<template>
  <div :style="`background-image: linear-gradient(to right, ${statusColor}, ${statusColor});
    background-size: 11px 100%; background-repeat: no-repeat; border-left: 0px;`" class="row px-0" v-bind:class="{'border rounded border-danger' : hasErrors}">
    <div class="col-12 alert alert-danger" role="alert" v-if="hasErrors">
      <div v-if="campaignItem.position_conflicts">
        <p>Er zijn geen posities voor deze keuzes:</p>
        <ul class="">
          <li v-for="error in campaignItem.position_conflicts" class="">{{error}}</li>
        </ul>
      </div>
    </div>
    <div v-if="isEditMode" class="col-12" style="border-bottom-width: 1px; border-bottom-style: dashed; border-bottom-color: rgb(206, 207, 208);">
      <div class="row">
        <div class="col">
          <label class="typo__label font-weight-bold">Bron</label>
          <multiselect @select="updateSourceSelection" v-model="selectedSource" :options="sources" :custom-label="name" placeholder="Kies een bron" label="source" track-by="source"></multiselect>
          <div class="center-buttons text-center">
            <button @click="copyCampaignItem('source')" class="no-button"><i class="far fa-plus-hexagon fa-lg"></i></button>
          </div>
          <label v-if="hasEstimateId" class="typo__label font-weight-bold">GA Code</label>
          <div v-if="hasEstimateId" class="input-group">
            <input v-model="campaignItem.ga_code" maxlength="30" style="height: 2.25rem; margin: 0px; min-width: 0px;" type="name" class="form-control d-block" id="title" placeholder="Vul een GA code in">
            <div class="input-group-append">
              <div @click="copyGACode" class="input-group-text" style="padding: 0.375rem 0.625rem; background-color: var(--blue-business) !important; color: white; z-index: 1; height: 2.25rem; width: 2.25rem;"><i class="right-addon fa fa-clone"></i></div>
            </div>
          </div>
          <label class="typo__label font-weight-bold mt-3">Ontzichtbare toeslag</label>
          <div class="input-group">
            <input v-model="campaignItem.invisible_surcharge" maxlength="30" style="height: 2.25rem; margin: 0px; min-width: 0px;" type="name" class="form-control d-block" id="title" placeholder="Bijv. 50 of 10%">
          </div>
        </div>
        <div class="col">
          <label class="typo__label font-weight-bold">Medium</label>
          <multiselect @select="updateMediumSelection" v-model="selectedMedium" :disabled="mediumDisabled" :options="mediumList" @open="setPopUp" :custom-label="name" placeholder="Kies een medium" label="medium" track-by="medium"></multiselect>
          <div class="center-buttons text-center">
            <button v-if="selectedMedium" @click="copyCampaignItem('medium')" class="no-button"><i class="far fa-plus-hexagon fa-lg"></i></button>
          </div>
          <label class="typo__label font-weight-bold">Korting</label>
          <div class="input-group">
            <input v-model="campaignItem.discount" maxlength="30" style="height: 2.25rem; margin: 0px; min-width: 0px;" type="name" class="form-control d-block" id="title" placeholder="Bijv. 50 of 10%">
          </div>
        </div>
        <div class="col">
          <label class="typo__label font-weight-bold">Channel</label>
          <multiselect v-model="selectedChannels" :options="channelList" :multiple="true" :disabled="channelDisabled" :close-on-select="false" :taggable="false" :clear-on-select="true" :preserve-search="true" placeholder="Kies de channels" label="name" track-by="id" >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} channels geselecteerd</span>
            </template>
          </multiselect>
          <div v-for="(channel, index) in selectedChannels" class="border border-primary rounded p-2 d-flex justify-content-between">
            <span>{{channel.name}}</span>
            <button @click="removeSelected('channel', index)" class="no-button text-danger"><i class="far fa-times-hexagon fa-lg"></i></button>
          </div>
        </div>
        <div class="col">
          <label class="typo__label font-weight-bold">Week</label>
          <multiselect v-model="selectedWeeks" :options="weeks" :multiple="true" :disabled="weekDisabled" :close-on-select="false" :taggable="false" :clear-on-select="true" :preserve-search="true" placeholder="Kies de weken" label="name" track-by="id" >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} weken geselecteerd</span>
            </template>
          </multiselect>
          <div v-for="(week, index) in selectedWeeks" class="border border-primary rounded p-2 d-flex justify-content-between">
            <span>{{week.name}}</span>
            <button @click="removeSelected('week', index)" class="no-button text-danger"><i class="far fa-times-hexagon fa-lg"></i></button>
          </div>
        </div>
        <div class="col">
            <label class="typo__label font-weight-bold">Status</label>
            <select v-model="campaignItem.status_id" class="form-control w-100" id="status">
              <option value="1">Optie</option>
              <option value="2">Bezet</option>
              <option value="3">Ingepland</option>
            </select>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-2">
          <div class="btn-group mb-2" role="group">
            <button @click="deleteCampaignItem" class="btn btn-sm btn-danger mr-1" onclick="return confirm('Weet je het zeker?')">Verwijderen</button>
            <button @click="saveCampaignItem" class="btn btn-sm btn-success">Opslaan</button>
          </div>
        </div>
      </div>
      <div class="p-4" style="border: 1px solid rgb(226, 198, 40); top: 40px; left: 40px; background-color: rgb(255, 255, 175); display:flex; justify-content: space-between" v-if="popUp">
        <div>  
          <i class="far fa-exclamation-circle"></i>
          Er zijn al channels geselecteerd voor {{ selectedMedium.name }}, als je een ander medium selecteert worden je geselecteerde channels verwijderd.
        </div>
        <div style="cursor: pointer;" @click="closePopUp">
          <i class="fal fa-times"></i>
        </div>
      </div>
    </div>
    <div v-if="!isEditMode" class="col-12" style="border-bottom-width: 1px; border-bottom-style: dashed; border-bottom-color: rgb(206, 207, 208);">
      <div class="row">
        <div class="col">
          <span class="font-weight-bold">Bron</span>
          <br>{{selectedSource.name}}
        </div>
        <div class="col">
          <span class="font-weight-bold">Medium</span>
          <br>{{selectedMedium.name}}
        </div>
        <div class="col">
          <span class="font-weight-bold">Channel</span>
          <span v-for="channel in selectedChannels">
            <br>{{channel.name}}
            <span class="m-0" v-if="campaignItem.combination_channels">
              <span class="text-secondary m-0" v-for="channel_combination in campaignItem.combination_channels">
                <br/><small>{{ channel_combination.name }}</small>
              </span>
            </span>
            <span class="m-0" v-else>
              <span class="text-secondary m-0" v-for="channel_combination in channel.channel_combinations">
                <br/><small>{{ channel_combination.name }}</small>
              </span>
            </span>
          </span>
        </div>
        <div class="col ">
          <span class="font-weight-bold">Week</span>
          <div class="m-0 p-0" v-for="week in selectedWeeks">
            <div class="row m-0 p-0">
              <label class="weekperiod" v-for="period in week.periods">{{period.name}}</label>
            </div>
            <div class="row m-0 p-0 mb-1">
              <span>{{week.name}}</span>
            </div>
          </div>
        </div>
        <div class="col">
          <span class="font-weight-bold">Status</span>
          <br>{{campaignItem.status.name}}
        </div>
        <div v-if="hasEstimateId" class="col">
          <span class="font-weight-bold">GA Code</span>
          <br>{{campaignItem.ga_code}}
        </div>
        <div v-if="campaignItem.discount" class="col">
          <span class="font-weight-bold">Korting</span>
          <br>{{campaignItem.discount}}
        </div>
        <div v-if="campaignItem.invisible_surcharge" class="col">
          <span class="font-weight-bold">Ontzichtbare toeslag</span>
          <br>{{campaignItem.invisible_surcharge}}
        </div>
        <div class="col text-right">
          <button v-if="deal_status != 4" @click="changeMode('edit')" class="btn btn-primary btn-sm mt-2">Bewerken</button>
        </div>
      </div>
      <div class="row" v-if="false">
        <div class="col-12">
          <pre>{{campaignItem}}</pre>
          <pre>{{selectedChannels}}</pre>
          <pre>{{selectedWeeks}}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import SalesPlanner from '../../packs/salesplanner.js'

  export default {
    name: "CampaignItem",
    props: ["campaignItem", "campaign", "sources", "campaign_index"],
    data(){
      return {
        selectedSource: null,
        selectedMedium: null,
        selectedChannels: [],
        selectedWeeks: [],
        mode: "edit",
        popUp: false,
        popUpSet: false
      }
    },
    components: {
      Multiselect
    },
    computed: {
      deal_status() {
        return window.store.getters.status_id
      },
      deal() {
        return window.store.getters.deal
      },
      hasEstimateId() {
        if(this.deal.estimate_number == "Concept" || this.deal.estimate_number == null || this.deal.estimate_number == "") {
          return false;
        } else {
          return true;
        } 
      },
      hasErrors(){
        return this.campaignItem.position_conflicts
      },
      statusColor() {
        return this.campaignItem.status.color
      },
      isEditMode(){
        if (
          (this.mode == 'edit') ||
          (this.campaignItem.position_conflicts) ||
          (this.campaignItem.weeks.length < 1) ||
          (this.campaignItem.channels.length < 1)
        ){
          return true
        } else {
          return false
        }
      },
      channelList() {
        if(this.selectedMedium == undefined) {
          return [];
        } else {
          try {
            return this.mediumList.filter(medium => medium.id == this.selectedMedium.id)[0].channels;
          } catch {
            this.selectedChannels = null
            this.selectedMedium = null
            return [];
          }
        }
      },
      mediumList() {
        if(this.selectedSource == undefined) {
          return [];
        } else {
          try {
            return this.sources.filter(source => source.id == this.selectedSource.id)[0].media;
          } catch {
            this.selectedMedium = null
            return [];
          }
        }
      },
      weeks() {
        return window.store.getters.upcoming_weeks
      },
      mediumDisabled() {
        return this.selectedSource == null;
      },
      channelDisabled() {
        return this.selectedMedium == null;
      },
      weekDisabled() {
        return this.selectedMedium == null;
      },
      indexOfSelfInList() {
        if(window.store.getters.deal.status_id != 4) {
          return window.store.getters.deal.campaigns_attributes[this.campaign_index].campaign_items_attributes.indexOf(this.campaignItem)
        } else {
          return window.store.getters.deal.campaigns_attributes.campaigns_attributes[this.campaign_index].campaign_items_attributes.indexOf(this.campaignItem)
        }
      },
    },
    methods: {
      setPopUp(){
        if(this.selectedChannels && !this.popUpSet){
          this.popUpSet = true
          this.popUp = true
          setTimeout(() => this.closePopUp(), 10000);
        }
      },
      closePopUp(){
        this.popUp = false
      },
      updateMediumSelection(selectedOption, id){
        this.selectedChannels = []
        window.store.dispatch("setCampaignItemMedium", {id: selectedOption.id, campaign_index: this.campaign_index, campaign_item_index: this.indexOfSelfInList})
      },
      updateSourceSelection(selectedOption, id){
        this.selectedMedium = null
        this.selectedChannels = null
      },
      name ({ name }) {
        return `${name}`
      },
      removeSelected(type, index) {
        if(type == "channel") {
          this.selectedChannels.splice(index, 1);
        } else if(type == "week") {
          this.selectedWeeks.splice(index,1)
        }
      },
      changeMode(mode) {
        this.mode = mode;
      },
      copyGACode() {
        var code = this.campaignItem.ga_code
        for (var campaign_item in this.campaign.campaign_items_attributes) {
          this.campaign.campaign_items_attributes[campaign_item].ga_code = code;
          window.store.dispatch("setCampaignItemGaCode", {ga_code: code, campaign_index: this.campaign_index, campaign_item_index: this.indexOfSelfInList})
          SalesPlanner.setGaCode(this.campaign, code)
        }
      },
      copyCampaignItem(type) {
        SalesPlanner.copyCampaignItem(type, this.campaignItem);
      },
      saveCampaignItem() {
        this.campaignItem.medium_id           = this.selectedMedium.id
        this.campaignItem.planned_channel_ids = this.selectedChannels.map((channel) => channel.id)
        this.campaignItem.planned_week_ids    = this.selectedWeeks.map((week) => week.id)
        SalesPlanner.saveCampaignItem(this.campaignItem, this.campaign);
        if(this.campaignItem.position_conflicts) {
        } else {
          this.changeMode('show')
        }
      },
      deleteCampaignItem() {
        window.store.dispatch("removeCampaignItem", this.campaignItem.id);
      }
    },
    created(){
      this.selectedChannels = this.campaignItem.channels
      this.selectedWeeks = this.campaignItem.weeks
      if (this.campaignItem.source != undefined){
        this.selectedSource = this.campaignItem.source
        if (this.campaignItem.medium != undefined){
          this.selectedMedium = this.campaignItem.medium
          if (this.campaignItem.channels != undefined){
            if (this.campaignItem.weeks != undefined){

              this.mode = "show"
            }
          }
        }
      }
    }
  };
</script>

<style scoped>

</style>
