<template>
  <div class="border border-primary rounded mt-3 p-4">
    <div class="row">
      <div class="col" v-if="false">{{product}}</div>
      <div class="col mb-3">
        <label class="typo__label font-weight-bold">Type</label>
        <multiselect v-model="product.product_type" :options="producttypes" :custom-label="name" placeholder="Kies een product type" label="name" track-by="id"></multiselect>
      </div>
      <div class="col">
        <label class="typo__label font-weight-bold">Tip</label>
        <multiselect v-model="product.dagjeweg_item" :options="dagjewegitems" :custom-label="name_with_id" placeholder="Kies een tip" label="name" track-by="dagjeweg_id"></multiselect>
      </div>
      <div class="col">
        <label class="typo__label" for="date">Startdatum</label>
        <datepicker placeholder="Kies een startdatum" input-class="form-control bg-white d-block form-color" v-model="product.starting_date"></datepicker>
      </div>
      <div class="col" v-if="product.product_type">
        <p class="d-flex m-0 justify-content-between">
          <span>{{product.product_type.name}}</span>
          <span>€{{productPrice}}</span>
        </p>
        <p v-if="product.charge_setup_costs" class="d-flex m-0 justify-content-between">
          <span>Opstartkosten</span>
          <span>€{{setupPrice}}</span>
        </p>
        <p v-if="product.discount" class="d-flex m-0 justify-content-between">
          <span>Korting</span>
          <span>- €{{discountPrice}}</span>
        </p>
        <p class="d-flex m-0 justify-content-between border-top pt-1 mt-1 border-primary">
          <span>Totaal:</span>
          <span>€{{totalPrice}}</span>
        </p>
        <p v-if="product.charge_monthly" style="font-size: 10px;"><em>Per maand</em></p>
        <p v-else style="font-size: 10px;"><em>Jaarlijks</em></p>
      </div>
    </div>
    <div class="row">
      <div class="col-2 mt-3">
        <div class="row ml-1 checkbox checkbox-primary">
          <input type="checkbox" :id="`setup_cost-${product.id}`" v-model="product.charge_setup_costs">
          <label :for="`setup_cost-${product.id}`">Opstartkosten</label>
        </div>
        <div class="row ml-1 checkbox checkbox-primary">
          <input type="checkbox" :id="`monthly-${product.id}`" v-model="product.charge_monthly">
          <label :for="`monthly-${product.id}`">Maandelijks gefactureerd</label>
        </div>
      </div>
      <div class="col-2">
        <label class="typo__label font-weight-bold">Korting</label>
        <div class="input-group">
          <input v-model="product.discount" maxlength="30" style="height: 2.25rem; margin: 0px; min-width: 0px;"type="name" class="form-control d-block" id="title" placeholder="Bijv. 50 of 10%">
        </div>
      </div>
      <div class="ml-auto mr-3 mt-2">
        <button @click="deleteProduct" class="btn btn-danger">Verwijder</button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Multiselect from 'vue-multiselect'
  import Datepicker from 'vuejs-datepicker';

  export default {
    name: "Product",
    props: ["product", "products", "index", "producttypes", "dagjewegitems"],
    components: {
      Multiselect,
      Datepicker
    },
    computed: {
      productPrice() {
        if(this.product.charge_monthly) {
          return this.round((this.product.product_type.price / this.product.product_type.duration), 2)
        } else {
          return this.round(this.product.product_type.price, 2)
        }
      },
      setupPrice() {
        if(this.product.charge_monthly) {
          return this.round((this.product.product_type.setup_cost / this.product.product_type.duration), 2)
        } else {
          return this.round(this.product.product_type.setup_cost, 2)
        }
      },
      discountPrice(){
        if(this.product.discount) {
          if(this.product.discount.includes("%")) {
            return this.round(parseFloat((this.productPrice/100)*this.product.discount.replace("%", "")), 2)
          } else {
            return this.product.discount
          }
        } else {
          return 0;
        }
      },
      totalPrice() {
        if(this.product.charge_setup_costs) {
          return this.round(this.round((this.setupPrice + this.productPrice), 2) - this.discountPrice)
        } else {
          return this.round(this.round(this.productPrice, 2) - this.discountPrice)
        }
      }
    },
    methods: {
      name_with_id ({ dagjeweg_id, name }) {
        return `[${dagjeweg_id}] ${name}`
      },
      name( {name}) {
        return `${name}`
      },
      round(value, decimals) {
        if(!value) {
          value = 0;
        }

        if(!decimals) {
          decimals = 0;
        }

        value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
        return value;
      },
      deleteProduct() {
        axios.delete(`/products/${this.product.id}`).then(response => {
          this.$noty.error("Product is verwijderd!")
        })
        this.products.splice(this.index, 1)
      }
    }
  };
</script>

<style scoped>

</style>
