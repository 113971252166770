<template>
  <tr>
    <th scope="row">{{dagjewegitem.dagjeweg_id}}</th>
    <td><a class="no_link" :href="`/dagjeweg_items/${dagjewegitem.id}`">{{ dagjewegitem.name }}</a></td>
    <td>{{dagjewegitem.type}}</td>
    <td>{{dagjewegitem.dagjewegtickets_id}}</td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/dagjeweg_items/${dagjewegitem.id}`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListDagjewegItem',
    props: ["dagjewegitem"],
  };
</script>

<style scoped>

</style>
