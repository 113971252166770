<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Naam</th>
              <th>Code</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListPromotionType :promotiontype="promotiontype" v-for="(promotiontype, $index) in promotiontypes" :key="$index"> </ListPromotionType>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListPromotionType from './ListPromotionType';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'PromotionTypes',
    components: {
      ListPromotionType,
    },
    created(){
      window.store.dispatch("resetVariables")
      SalesPlanner.getPromotionTypes();
    },
    computed: {
      promotiontypes() {
        var promotion_types = [];
        promotion_types = window.store.getters.promotion_types;
        promotion_types = promotion_types.sort();

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          promotion_types = promotion_types.filter(promotion_type =>
            (promotion_type.name.toLowerCase().includes(window.store.getters.filter)) ||
            (promotion_type.code.toLowerCase().includes(window.store.getters.filter)) ||
            (promotion_type.id.toString().includes(window.store.getters.filter)));
        }

        return promotion_types;
      },
    }
  };
</script>
<style scoped>

</style>
