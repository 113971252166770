<template>
  <div :style="`background-image: linear-gradient(to right, ${statusColor}, ${statusColor});
    background-size: 20px 100%; background-repeat: no-repeat; border-left: 0px;`" class="border border-primary rounded mt-3 p-4">
    <div class="row" style="border-bottom-width: 1px; border-bottom-style: dashed; border-bottom-color: rgb(206, 207, 208);">
      <div class="form-group col-4">
        <label class="font-weight-bold" for="title">Kenmerk campagne</label>
        <input v-model="campaign.title" type="name" class="form-control d-block" id="title" placeholder="Vul een kenmerk in">
      </div>
      <div class="form-group col-4">
        <label for="status" class="font-weight-bold">Status</label>
        <select v-model="campaign.status_id" class="form-control w-100" id="status">
          <option v-if="deal_status != 4" value="1">Optie</option>
          <option v-if="deal_status != 4" value="2">Bezet</option>
          <option v-if="deal_status != 4" value="3">Ingepland</option>
          <option v-if="deal_status == 4" value="4">Afgewezen</option>
        </select>
      </div>
      <div class="form-group col-2">
        <label class="font-weight-bold" for="title">Prijs</label>
        <input v-model="campaign.price" type="name" class="form-control d-inline-block" id="title" disabled>
      </div>
      <div class="form-group col-2 text-right my-auto">
        <a :href="`/deals/${deal_id}/stats?campaign=${campaign.id}`" class="btn btn-sm btn-info d-inline-block" v-if="deal_status == 5">Statistieken</a>
        <button class="btn btn-sm btn-danger d-inline-block" v-if="deal_status != 4" @click="removeCampaign" onclick="return confirm('Are you sure?')">Verwijderen</button>
      </div>
      <div class="col-4 text-danger" v-if="campaign.reminder_mail_count > 0">
        Verzonden reminders {{ campaign.reminder_mail_count }}
      </div>
    </div>
    <CampaignItem :campaign_index="indexOfSelfInList" :campaign="campaign" :campaignItem="campaignItem" :sources="sources" v-for="(campaignItem, $index) in campaignItems" :key="$index"> </CampaignItem>
  </div>
</template>

<script>
  import CampaignItem from './CampaignItem';

  export default {
    name: "Campaign",
    props: ["campaign", "sources"],
    components: {
      CampaignItem
    },
    computed: {
      deal_status() {
        return window.store.getters.status_id
      },
      deal_id() {
        return window.store.getters.deal.id
      },
      indexOfSelfInList() {
        return window.store.getters.deal.campaigns_attributes.indexOf(this.campaign)
      },
      campaignItems() {
        return this.campaign.campaign_items_attributes
      },
      statusColor() {
        return this.campaign.status_color
      }
    },
    methods: {
      newCampaignItem() {
        window.store.dispatch("newCampaignItem", this.indexOfSelfInList);
      },
      removeCampaign() {
        window.store.dispatch("removeCampaign", {index: this.indexOfSelfInList, id: this.campaign.id});
      }
    }
  }
</script>

<style scoped>

</style>
