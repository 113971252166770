<template>
  <tr>
    <td>{{ tasktype.id }}</td>
    <td>{{ tasktype.name }}</td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/task_types/${tasktype.id}/edit`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListTaskType',
    props: ["tasktype"]
  };
</script>

<style scoped>

</style>
