<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Label</th>
              <th>Week</th>
              <th>Gekoppeld</th>
              <th>Opgelost</th>
            </tr>
          </thead>
          <tbody>
            <ListImportError :importerror="importerror" v-for="(importerror, $index) in importerrors" :key="$index"> </ListImportError>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListImportError from './ListImportError';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'ImportErrors',
    components: {
      ListImportError,
    },
    created(){
      window.store.dispatch("resetVariables")
      SalesPlanner.getImportErrors();
    },
    computed: {
      importerrors() {
        var import_errors = [];
        import_errors = window.store.getters.import_errors;
        import_errors = import_errors.sort();

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          import_errors = import_errors.filter(import_error =>
            (import_error.text.toLowerCase().includes(window.store.getters.filter)) ||
            (import_error.id.toString().includes(window.store.getters.filter)));
        }

        return import_errors;
      },
    }
  };
</script>
<style scoped>

</style>
