<template>
	<div>
		<div id="chart">
     		<apexchart type=area height=350 :options="chartOptions" :series="series" />
    	</div>
	</div>
</template>

<script>
  export default {
    name: 'PageViews',
    props: ["total", "unique", "dates"],
	data () {
      return {
        series: [{
          name: 'Unieke views',
          data: this.unique
        }, {
          name: 'Totaal views',
          data: this.total
        }],
        chartOptions: {
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },


          xaxis: {
            type: 'string',
            categories: this.dates,
            labels: {
              show: false,
            }
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },

          }
        }

      }
  }
  };
</script>

<style scoped>

</style>
