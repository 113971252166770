<template>
  <tr>
    <td>{{ source.id }}</td>
    <td>{{ source.name }}</td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/sources/${source.id}`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListSource',
    props: ["source"]
  };
</script>

<style scoped>

</style>
