<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Naam</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListTaskType :tasktype="tasktype" v-for="(tasktype, $index) in task_types" :key="$index"> </ListTaskType>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListTaskType from './ListTaskType';
  import SalesPlanner from '../../packs/salesplanner.js'
  import axios from 'axios'

  export default {
    name: 'TaskTypes',
    components: {
      ListTaskType,
    },
    data () {
      return {
        task_types: [],
      }
    },
    created(){
      axios.get('/task_types.json').then(response => (this.task_types = response.data))
    },
    computed: {
      promotiontypes() {
        var filtered_task_types = [];
        filtered_task_types = this.task_types;
        filtered_task_types = filtered_task_types.sort();

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          filtered_task_types = filtered_task_types.filter(task_type =>
            (task_type.name.toLowerCase().includes(window.store.getters.filter)) ||
            (task_type.id.toString().includes(window.store.getters.filter)));
        }

        return filtered_task_types;
      },
    }
  };
</script>
<style scoped>

</style>
