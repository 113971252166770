<template>
  <div :class="`col-${columns} channel-positions m-0 p-0`">
    <PositionSpot :position="position" v-for="(position, $index) in week.positions" :key="`position-${$index}`" :number="$index" class="m-0"> </PositionSpot>
    <div class="position position-used position-bezet text-truncate" style="background-color: #97B5E8;" v-if="week.channel_combinations_count > 0">+ {{week.channel_combinations_count}}
      <span v-if="week.channel_combinations_count == 1">
        combi
      </span>
      <span v-else>
        combi's
      </span>
    </div>
  </div>
</template>

<script>
  import PositionSpot from './PositionSpot';

  export default {
    name: 'ChannelWeeks',
    props: ["week", "columns"],
    components: {
      PositionSpot
    }
  };
</script>

<style scoped>

</style>
