<template>
  <div class="link_modal">
    <span class="font-weight-bold">
      Link maken
    </span>
    <div>
      <div class="my-2">
        <div class="d-flex justify-content-around" style="margin-bottom: -1px;">
          <div @click="link_type = 'choose', link_location = '', link_text = ''" style="cursor: pointer;" :class="'border bg-white py-1 px-4 w-full rounded-top ' + (link_type === 'choose' ? 'border-bottom-0' : '')">
            kiezen
          </div>
          <div @click="link_type = 'create', link_location = '', link_text = ''" style="cursor: pointer;" :class="'border bg-white py-1 px-4 w-full rounded-top ' + (link_type === 'create' ? 'border-bottom-0' : '')">
            Maken
          </div>
        </div>
        <hr class="p-0 m-0" />
      </div>
      <div style="height: 175px; position:relative; overflow:hidden">
        <Transition name="fadeRight">
          <div v-if="link_type == 'create'">
            <div class="form-group">
              <label for="link_location">Locatie</label>
              <input type="text" class="form-control" name="link_location" v-model="link_location" placeholder="https://">
            </div>
            <div class="form-group">
              <label for="link_text">Tekst</label>
              <input type="text" class="form-control" name="link_text" v-model="link_text">
            </div>
          </div>
        </Transition>
        <Transition name="fadeLeft">
          <div v-if="link_type == 'choose'">
            <div class="form-group">
              <label for="link_location">Locatie</label>
              <select name="link_location" id="" class="form-control" v-model="link_location" v-for="(link, index) in link_options" :key="index">
                <option :value="link.url" selected>{{ link.text }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="link_text">Tekst</label>
              <input type="text" class="form-control" name="link_text" v-model="link_text">
            </div>
          </div>
        </Transition>
      </div>
      <button class="btn btn-secondary w-100" @click="add_link">
        Link toevoegen
      </button>
    </div>
  </div>
</template>
<script>

export default {
  name: "LinkModal",
  
  data() {
    return {
      link_location: '',
      link_text: '',
      link_type: 'choose',
      link_options: [
        {url: 'Deal#dashboard_link', text: 'Dashboard'}
      ]
    }
  },

  methods: {
    add_link(){
      var link = this.link_location + ">" + this.link_text + "} "

      this.$emit("link_created", link);
      this.link_location = ''
      this.link_text = ''
    }
  },
}
</script>