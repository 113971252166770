<template>
	<div>
		<button @click="toggle" class="no-button float-right" v-if="!show"><i class="far fa-plus-hexagon fa-lg"></i></button>
		<button @click="toggle" class="no-button float-right" v-if="show"><i class="far fa-minus-hexagon fa-lg"></i></button>
		<p>{{week.name}}</p>
		<table class="table">
		  <thead>
		    <tr>
		      <th scope="col">Promotie</th>
		      <th scope="col" v-for="promotion in week.promotions">{{promotion.name}}</th>
		    </tr>
		  </thead>
		  <tbody>
		    <tr>
		      <th scope="row">Totaal</th>
		      <td scope="col" v-for="promotion in week.promotions">{{promotion.total}}</td>
		    </tr>
		    <tr>
		      <th scope="row">Uniek</th>
		      <td scope="col" v-for="promotion in week.promotions">{{promotion.unique}}</td>
		    </tr>
		  </tbody>
		</table>
		<div class="locations" v-if="show">
		  <ul class="list-group">
		    <li class="list-group-item" v-for="location in week.locations">{{location.url}}<br/> Totaal: {{location.total}} Uniek: {{location.unique}}</li>
		  </ul>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'SearchResult',
    props: ["week"],
    data () {
        return {
          show: false,
        }
    },
    methods: { 
      toggle() {
        this.show = !this.show
      }
    }
  };
</script>

<style scoped>

</style>
