<template>
  <div class="card mb-5 shadow-sm">
    <div>
      <div class="card-header pb-0" :class="{'bg-dark': (campaign.is_in_future || campaign.is_current), 'bg-light': (!campaign.is_in_future && !campaign.is_current)}">
        <h5 :class="{'text-light': (campaign.is_in_future || campaign.is_current), 'text-dark': (!campaign.is_in_future && !campaign.is_current)}">{{campaign.title}} <span v-if="campaign.is_current" class="badge badge-success">LIVE</span>
        <span v-if="(campaign.too_late && (campaign.is_in_future || campaign.is_current))" class="badge badge-danger">INCOMPLEET</span>
        </h5>
      </div>
      <div class="list-group list-group-flush">
        <div class="list-group-item" style="border-bottom-width: 5px;">
          <table class="table table-striped">
            <tr>
              <th style="border-top: none;">Start campagne </th>
              <td style="border-top: none;">
                <span v-if="!campaign.is_in_future" class="">Campagne startte op {{campaign.show_starts_at}}.</span>
                <span v-if="campaign.is_in_future" class="">Campagne start op {{campaign.show_starts_at}}.</span>
              </td>
            </tr>
            <tr>
              <th>Einde campagne </th>
              <td v-if="campaign.is_in_future || campaign.is_current">Campagne eindigt op {{campaign.show_ends_at}}.</td>
              <td v-else>Campagne eindigde op {{campaign.show_ends_at}}.</td>
            </tr>
            <tr v-if="!campaign.is_current && campaign.is_in_future">
              <th>Deadline aanleveren</th>
              <td v-if="campaign.too_late">Het campagnemateriaal moest <strong>uiterlijk {{campaign.show_deadline}}</strong> worden aangeleverd.</td>
              <td v-if="!campaign.too_late">Het campagnemateriaal moet <strong>uiterlijk {{campaign.show_deadline}}</strong> worden aangeleverd.</td>
            </tr>
            <tr>
              <th>URL</th>
              <td v-if="((campaign.is_in_future || campaign.is_current) && campaign.status_id != 3) || user_signed_in">
                <div class="input-group input-group-sm mb-0">
                  <div class="input-group-prepend" v-if="false">
                    <span class="input-group-text" id="inputGroup-sizing-sm">https://</span>
                  </div>
                  <input v-on:keyup.enter="saveUrl" v-bind:disabled="loading" v-model="url" class="form-control" placeholder="https://www.example.com/">
                  <div class="input-group-append" v-if="url != campaign.url">
                    <button @click="saveUrl" class="btn btn-outline-secondary" type="button" v-bind:disabled="loading">opslaan</button>
                  </div>
                </div>
                <small class="form-text text-muted">Naar welke URL wordt de bezoeker gestuurd na het klikken op deze advertentie?</small>
              </td>
              <td v-if="((!campaign.is_in_future && !campaign.is_current) || campaign.status_id == 3) && !user_signed_in">
                <span v-if="url">{{url}}</span>
                <span v-if="!url">Geen URL opgeleverd.</span>
              </td>
            </tr>
            <tr>
              <th :style="{ color : countColor}">Call to Action <br> ({{ characterCount }} / {{ characterLimit }})</th>
              <td v-if="((campaign.is_in_future || campaign.is_current) && campaign.status_id != 3) || user_signed_in">
                <div class="input-group input-group-sm mb-0 position-relative">
                  <div class="input-group-prepend" v-if="false">
                    <span class="input-group-text" id="inputGroup-sizing-sm">https://</span>
                  </div>
                  <input v-on:keyup.enter="saveUrl" v-on:keyup="countCharacters" v-bind:disabled="loading" v-model="call_to_action" class="form-control" placeholder="Schrijf hier een korte wervende tekst..." :maxlength="characterLimit">
                  <div class="input-group-append" v-if="call_to_action != campaign.call_to_action">
                    <button @click="saveUrl" class="btn btn-outline-secondary" type="button" v-bind:disabled="loading">opslaan</button>
                  </div>
                </div>
                <div v-for="(warning, index) in warnings" :key="index">
                  <div class="py-1 px-3 alert alert-warning mt-2" v-if="warning">
                    {{ warning }}
                  </div>
                </div>
                <small class="form-text text-muted">De 'Call to Action' is een korte wervende tekst, die uitnodigt tot doorklikken. Bijvoorbeeld: <i>'Leer alles over brood en banket in het bakkerijmuseum!'</i></small>
              </td>
              
              <td v-if="((!campaign.is_in_future && !campaign.is_current) || campaign.status_id == 3) && !user_signed_in">
                <span v-if="call_to_action">{{call_to_action}}</span>
                <span v-if="!call_to_action">Geen Call to Action opgeleverd.</span>
              </td>
            </tr>
          </table>
        </div>
        <CampaignCardItem 
          class="list-group-item py-3" 
          @showMaterial="showMaterial" 
          :campaign="campaign" 
          :item="item" 
          :stats="stats" 
          :staturls="staturls"
          v-for="item in campaign.campaign_items_attributes" 
          :user_signed_in="user_signed_in"
          :key="item.id">  
        </CampaignCardItem>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import CampaignCardItem  from './CampaignCardItem.vue'
export default {
  name: 'CampaignCard',
  components: {CampaignCardItem},
  props: ['campaign', 'stats', 'user_signed_in', 'staturls'],
  data () {
    return {
      url: '',
      call_to_action: '',
      loading: false,
      characterCount: 0,
      countColor: "black",
      characterLimit: 55,
      warnings: [],
    }
  },
  created(){
    this.url = this.campaign.url
    this.call_to_action = this.campaign.call_to_action
    this.characterCount = this.call_to_action.length
  },
  methods: {
    countCharacters(event){
      this.characterCount = event.target.value.length
      if (this.characterCount == this.characterLimit){
        this.countColor = "red";
      }else if( (this.characterCount / this.characterLimit) * 100 >= 80){
        this.countColor = "orange";
      }else{
        this.countColor = "black";
      }
    },
    checkCapitalCharacters(){
      if (this.call_to_action){
        if(this.call_to_action.charAt(this.call_to_action.length-1) === "."){
          this.warnings.push("Uw CTA mag niet eindigen met een punt!");
        }; 
        if(this.warnings.length > 0){
          return false;
        }else{
          return true;
        }
      }else{
        return true;
      };
    },

    saveUrl(){
      this.warnings = []
      if (this.checkCapitalCharacters() === true){
        this.loading = true
        return axios.put(`/campaigns/${this.campaign.id}.json`, {campaign_material_campaign: {
            url: this.url,
        call_to_action: this.call_to_action
      }})
        .then(response => { 
            this.loading = false
            // this.url = this.campaign.url
            this.$emit('uploadComplete', response.data)
          })
      }
    },
    showMaterial(item){
      this.$emit('open', item)
    }
  },
}
</script>

<style lang="css" scoped>
</style>