<template>
  <div class="container-fluid">
    <div class="row">
      <div class="mx-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Naam</th>
              <th>Aantal keer verzonden</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListMailTemplate :mailTemplate="mailTemplate" v-for="(mailTemplate, $index) in mail_templates" :key="$index" @mailTemplateDeleted="refreshMailTemplates"> </ListMailTemplate>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import ListMailTemplate from "./ListMailTemplate.vue";
import SalesPlanner from '../../packs/salesplanner.js'

export default {
  components: {
    ListMailTemplate,
  },
  
  created(){
    SalesPlanner.getMailTemplates();
  },

  computed: {
    mail_templates() {
      var mail_templates = window.store.getters.mail_templates;

      return mail_templates;
    }
  },

  methods: {
    refreshMailTemplates() {
      SalesPlanner.getMailTemplates().then((response) => {
        this.mailTemplates = response.data;
      })
      .catch((error) => {
        console.error('Error refreshing mail templates', error);
      });
    }
  },
}
</script>