<template>
	<div>
		
	  <div class="row my-3">
      <div class="col-3 text-right" v-if="campaign_item.channels.length > 1"><h4>Channels</h4></div>
      <div class="col text-left"><h4>Weken</h4></div>
    </div>
    <div class="row mb-1" v-for="channel in campaign_item.channels" style="border-bottom: 1px solid #dedede;">
      <div class="col-3 text-right" v-if="campaign_item.channels.length > 1"><strong>{{channel.name}}</strong></div>
      <div class="col text-left">
	      <button @click="toggleVisibility(week, channel)" class="btn btn-outline-primary btn-sm float-left mb-1 mr-1" v-for="week in campaign_item.weeks" v-bind:class="{'text-muted' : !isActive(week, channel)}" v-bind:disabled="loading || positionIsIncluded(week, channel)">
	      	<i v-if="currentlyLoading(week, channel)" class="fa fa-spinner fa-spin text-muted"></i> 
	      	<i v-if="!currentlyLoading(week, channel) && positionIsIncluded(week, channel)" class="fa fa-check text-success"></i> 
	      	<i v-if="!currentlyLoading(week, channel) && !positionIsIncluded(week, channel)" class="fa fa-check text-muted disabled"></i>
	      	week {{week.week_number}}
	      </button>
      </div>
    </div>
    
    <span class="form-text text-muted" v-if="campaign_item.channels.length > 1 && campaign_item.weeks.length > 1">
      Op de geselecteerde weken en channels wordt deze uiting getoond op {{ campaign_item.source.name }}
    </span>
    <span class="form-text text-muted" v-if="campaign_item.channels.length == 1 && campaign_item.weeks.length > 1">
      Op de geselecteerde weken en channels wordt deze uiting getoond op {{ campaign_item.source.name }}
    </span>
    <span class="form-text text-muted" v-if="campaign_item.channels.length > 1 && campaign_item.weeks.length == 1">
      Op de geselecteerde channels wordt deze uiting getoond op {{ campaign_item.source.name }}
    </span>

    <pre v-if="false">{{disabledPositions}}</pre>
 	</div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'FileFieldWeekSelector',
  props: ['campaign_item', 'requirement', 'file'],
  data () {
    return {
    	loading: false,
    	loadingKey: '',
    	disabledPositions: []
    }
  },
  computed: {
  	textForNewFiles(){
  		if (this.disabledPositions.length == 1){
  			return 'de overgebleven positie'
  		} else {
  			return `de overige ${this.disabledPositions.length} posities`
  		}
  	}

  },
  methods: {
  	currentlyLoading(week, channel){
  		return (this.loadingKey == this.key(week, channel))
  	},
  	saveSettings(){
  		this.loading = true
  		return axios.put(`/assign-file-positions/${this.campaign_item.id}/${this.file.id}.json`, {disabled_positions: this.disabledPositions})
        .then(response => { 
          this.loading = false
          this.disabledPositions = []
		  		this.$emit('update', response.data)
          // this.$emit('uploadComplete', response.data) 
        })
  	},
  	key(week, channel){
  		return `week#${week.id}_channel#${channel.id}`
  	},
  	isActive(week, channel){
  		return !this.disabledPositions.includes(this.key(week, channel))
  	},
  	positionIsIncluded(week, channel){
  		return this.file.position_keys.includes(this.key(week, channel))
  	},
  	toggleVisibility(week, channel){
  		this.loading 		= true
  		this.loadingKey = this.key(week, channel)
  		return axios.put(`/assign-file-positions/${this.campaign_item.id}/${this.file.id}.json`, {week_id: week.id, channel_id: channel.id})
        .then(response => { 
          this.loading = false
          this.loadingKey = ''
		  		this.$emit('update', response.data)
        })
  	}
  }
}
</script>

<style lang="css" scoped>
</style>