<template>
  <tr>
    <td>{{ promotiontype.id }}</td>
    <td>{{ promotiontype.name }}</td>
    <td>{{ promotiontype.code }}</td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/promotion_types/${promotiontype.id}/edit`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListPromotionType',
    props: ["promotiontype"]
  };
</script>

<style scoped>

</style>
