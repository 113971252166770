<template>
  <div>
    <div class='LiveModalBackground' @click="closeModal()">
    </div>

    <div class="LiveModal">
      <div class="p-relative h-100">
        <div class="d-flex justify-content-between bg-white py-1">
          <h4 class="align-self-center">
            Mail:
          </h4> 
          
          <select name="mail_template_selector" id="" class="form-control ml-2" @change="set_template" v-model="selected_template_id">
            <option :value="template.id" v-for="(template, index) in mail_templates" :key="index" :selected="template.id == selected_template_id">{{ template.name }}</option>
          </select>
          <div @click="closeModal" style="cursor: pointer;" class="ml-5">
            <i class="fas fa-times m-auto"></i>
          </div>
        </div>
        <div class="h-100 d-flex">
          <!-- form -->
          <div class="w-50 px-2">
            <div class="form-group">
              <label class="font-weight-bold" for="receiver_email">Ontvanger</label>
              <input type="text" name="receiver_email" class="form-control" :class="errors.includes('receiver_email') ? 'border-danger': ''" v-model="receiver_email">
              <small class="text-danger" v-if="errors.includes('receiver_email')">Er moet een ontvanger zijn voor de mail.</small>
            </div>
            <div class="form-group">
              <label class="font-weight-bold" for="subject">Onderwerp</label>
              <input type="text" name="subject" class="form-control" :class="errors.includes('subject') ? 'border-danger': ''" v-model="subject">
              <small class="text-danger" v-if="errors.includes('subject')">Het onderwerp moet ingevuld zijn</small>
            </div>
            <div class="form-check mb-3">
              <input class="form-check-input" type="checkbox" name="has_header" id="has_header" v-model="has_header">
              <label class="form-check-label font-weight-bold" for="has_header">
                Heeft header
              </label>
            </div>

            <div class="form-group position-relative relative p-relative">
              <label class="font-weight-bold" for="content">Inhoud</label>
              <textarea name="content" ref="content" cols="30" rows="10" :class="errors.includes('content') ? 'form-control border border-danger': 'form-control'" v-model="content" @click="getCaretPosition" @input="getCaretPosition"></textarea>
              <small class="text-danger" v-if="errors.includes('content')">De inhoud moet ingevuld zijn</small>
              <LinkModal @link_created="add_link" v-if="displayLinkModal"></LinkModal>
              <VariableModal @variable_created="add_variable" v-if="displayVariableModal"></VariableModal>
              <div class="mt-2">
                <button class="btn btn-outline-primary" @click="add_character('[')">Variabele toevoegen</button>
                <button class="btn btn-outline-primary" @click="add_character('{')">Link toevoegen</button>
              </div>
            </div>
            <button class="btn btn-success" @click="send">
              Versturen
            </button>
          </div>
          <!-- example -->
          <LiveMailExample :content="content" :has_header="has_header" :subject="subject" :username="username" :deal_name="deal_name" :deal_id="deal.id" ></LiveMailExample>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import axios from 'axios';
import LinkModal from './LinkModal.vue';
import LiveMailExample from './LiveMailExample.vue';

export default {
  name: 'liveForm',

  props: {
    user: {
      type: Object,
    },
    mail_template: {
      type: Object,
    },
    mail_templates: {
      type: Array,
    },
    deal: {
      type: Object,
    },
    receiver_name: String,
    receiver_email: String,
  },

  components: {
    LinkModal,
    LiveMailExample
  },

  data() {
    return {
      name: this.mail_template ? this.mail_template.name : '',
      subject: this.mail_template ? this.mail_template.subject : '',
      content: this.mail_template ? this.mail_template.content : '',
      has_header: this.mail_template ? this.mail_template.has_header : false,
      deal_name: this.deal &&  this.deal.authorized_first_name ? this.deal.authorized_first_name : "",
      caretPosition: 0,
      link_location: '',
      link_text: '',
      link_type: 'choose',
      errors: [],
      link_options: [
        {url: 'Deal#dashboard_link', text: 'Dashboard'}
      ],
      selected_template_id: this.mail_template ? this.mail_template.id : 0,
    }
  },
  
  created() {
    document.documentElement.style.overflow = 'hidden'
  },

  methods: {
    set_template(){
      let mail_template = this.mail_templates.find(template => template.id === this.selected_template_id)

      this.name = mail_template.name;
      this.content = mail_template.content;
      this.subject = mail_template.subject;
      this.has_header = mail_template.has_header;

    },
    send(){
      this.errors = []
      if(!this.name || !this.subject || !this.content){
        if (!this.name){
          this.errors.push("name")
        }
        if (!this.subject){
          this.errors.push("subject")
        }
        if (!this.content){
          this.errors.push("content")
        }
      }else{
        const payload = {
          name: this.name,
          subject: this.subject,
          content: this.content,
          mail_template_id: this.mail_template.id,
          sent_by_id: this.user.id,
          sent_to: this.receiver_email,
          receiver_name: this.receiver_name,
          model_id: this.deal.id,
          has_header: this.has_header
        }

        axios.post(`/emails.json`, payload)
      } 
      // modal sluiten
      this.closeModal()
    },

    closeModal(){
      document.documentElement.style.overflow = 'auto'
      this.$emit('closeModal')
    },

    getCaretPosition(event) {
      this.caretPosition = event.target.selectionStart;
    },

    add_link(){
      var link = this.link_location + ">" + this.link_text + "}"
      
      this.content = this.content.slice(0, this.caretPosition) + link + this.content.slice(this.caretPosition);
      this.caretPosition = this.content.length;
      this.link_location = ''
      this.link_text = ''
    },

    add_character(character){
      if (this.caretPosition){
        this.content = this.content.slice(0, this.caretPosition) + character + this.content.slice(this.caretPosition);
        this.caretPosition = this.caretPosition + 1
        this.$refs.content.setSelectionRange(this.caretPosition, this.caretPosition);
      }else{
        this.content += character
        this.caretPosition = this.content.length
        this.$refs.content.focus();
      }
    },
    
    moveCaret(position){
      this.$refs.content.focus();
      this.$nextTick(() => {
        if (this.$refs.content.setSelectionRange) {
          this.caretPosition = position
          this.$refs.content.setSelectionRange(position, position);
        } else {
          const range = this.$refs.content.createTextRange();
          range.move('character', position);
          range.select();
        }
      });
    },
    
    add_link(event){
      var link = event
      
      this.content = this.content.slice(0, this.caretPosition) + link + this.content.slice(this.caretPosition);
      this.link_location = ''
      this.link_text = ''

      this.moveCaret(this.caretPosition + link.length)
    },
    
    add_variable(event){
      var variable = event
      
      this.content = this.content.slice(0, this.caretPosition) + variable + this.content.slice(this.caretPosition);
      this.variable_method = ''
      this.variable_text = ''
      
      this.moveCaret(this.caretPosition + variable.length)
    }
  },

  computed: {
    username(){
      if (this.user.first_name && this.user.last_name){
        return this.user.first_name + ' ' + this.user.last_name
      }else if (this.user.first_name){
        return this.user.first_name
      }
    },

    linkModalData(){
      let substr = this.content[0..this.caretPosition]
      console.log(substr)
    },

    displayLinkModal(){
      if (this.caretPosition > 0 && this.content[this.caretPosition - 1] === "{"){
        return true
      }
    },  

    displayVariableModal(){
      if (this.caretPosition > 0 && this.content[this.caretPosition - 1] === "["){
        return true
      }
    },  
  }
}

</script>