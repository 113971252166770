<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Kenmerk</th>
              <th>Offertenummer</th>
              <th>Bedrijf</th>
              <th>Accountmanager</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListPromotion :promotion="promotion" v-for="(promotion, $index) in filtered_promotions" :key="$index"></ListPromotion>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListPromotion from './ListPromotion';
  import axios from 'axios'

  export default {
    name: 'Promotions',
    components: {
      ListPromotion,
    },
    data () {
      return {
        promotions: []
      }
    },
    created(){
      window.store.dispatch("resetVariables")
      window.store.dispatch("setOrder", "id-desc")
      axios.get('/promotions.json').then(response => (this.promotions = response.data))
    },
    methods: {
      reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
          ret.push(input[i]);
        }
        return ret;
      },
    },
    computed: {
      filtered_promotions() {
        var filtered_promotions = [];
        if(window.store.getters.status != null) {
          filtered_promotions = this.promotions.filter(promotion => promotion.status.id == window.store.getters.status);
        } else {
          filtered_promotions = this.promotions;
        }

        if(window.store.getters.order == "id-desc") {
          filtered_promotions = this.reverseArr(filtered_promotions)
        } else if(window.store.getters.order == "id-asc") {
          filtered_promotions = filtered_promotions.sort();
        }

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          filtered_promotions = filtered_promotions.filter(promotion =>
            (promotion.id.toString().includes(window.store.getters.filter)) ||
            (promotion.user.name.toLowerCase().includes(window.store.getters.filter)));
        }

        return filtered_promotions;
      },
    }
  };
</script>
<style scoped>

</style>
