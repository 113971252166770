<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Naam</th>
              <th>Kleur</th>
              <th>Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListStatus :status="status" v-for="(status, $index) in statuses" :key="$index"> </ListStatus>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListStatus from './ListStatus';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'Statuses',
    components: {
      ListStatus,
    },
    created(){
      window.store.dispatch("resetVariables")
      SalesPlanner.getStatuses();
    },
    computed: {
      statuses() {
        var statuses = [];
        statuses = window.store.getters.statuses;
        statuses = statuses.sort();

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          statuses = statuses.filter(status =>
            (status.name.toLowerCase().includes(window.store.getters.filter)) ||
            (status.linked_to.toLowerCase().includes(window.store.getters.filter)) ||
            (status.id.toString().includes(window.store.getters.filter)));
        }

        return statuses;
      },
    }
  };
</script>
<style scoped>

</style>
