<template>
  <div class="variable_modal">
    <span class="font-weight-bold">
      Variabele toevoegen
    </span>
    <div style="height: 175px; position:relative; overflow:hidden">
      <div class="form-group">
        <label for="variable_method">Variabele</label>
        <select name="variable_method" id="" class="form-control" v-model="variable_method">
          <option :value="variable.method"  v-for="(variable, index) in variable_options" :key="index" selected>{{ variable.text }}</option>
        </select>
      </div>
    </div>
    <button class="btn btn-secondary w-100" @click="add_variable">
      Variabele toevoegen
    </button>
  </div>
</template>
<script>

export default {
  name: "variableModal",
  
  data() {
    return {
      variable_method: '',
      variable_text: '',
      variable_options: [
        {method: 'Deal#next_future_campaign_for_mail', text: 'Eerst volgende campagne'},
        {method: 'Deal#next_future_campaign_deadline_for_mail', text: 'Deadline voor eerst volgende campagne'},
        {method: 'Deal#customer_name', text: 'Naam van klant'},
      ]
    }
  },

  methods: {
    add_variable(){
      var variable = this.variable_method + "] "

      this.$emit("variable_created", variable);
      this.variable_method = ''
      this.variable_text = ''
    }
  },
}
</script>