<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <a class="navbar-brand" href="/">
    <img :src="logo" class="d-inline-block"/>
    <span class="the-name">Salesplanner</span>
  </a>
  <div v-if="user" ref="button" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
    
      <li v-for="(item, key) in items" :key="key" v-if="hasAccess(item.admin)" class="nav-item active" :class="{ dropdown: item.dropdown }">
        <a v-if="item.url" :href="item.url" class="nav-link">{{ item.title }}</a>
        <div v-if="item.dropdown" @click="open(key)" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ item.title }}
        </div>
        <div v-closable="{exclude: ['button'], handler: 'close'}" v-if="item.dropdown" class="dropdown-menu" :class="{ show: open_dropdown == key }" aria-labelledby="navbarDropdownstats">
          <a v-for="(dropdown_item, key) in item.dropdown" @click="open_dropdown = null" :key="key" class="dropdown-item" :href="dropdown_item.url">{{ dropdown_item.title }}</a>
        </div>
      </li>

    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item active dropdown">
        <div @click="open('user')" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="px-1">
            <i class="far fa-user"></i>
          </span>
          {{ user.name }}
        </div>
        <div :class="{ show: open_dropdown == 'user' }" class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" :href="`/profile/${user.id}`">Profiel bewerken</a>
          <a v-if="user.admin" class="dropdown-item"  href="/users">Gebruikers</a>
          <a class="dropdown-item"  href="/logout">Uitloggen</a>
        </div>
      </li>
    </ul>
  </div>
</nav>

</template>

<script>
  export default {
    name: "Navbar",
    props: ["user", "logo", "current_week"],
    methods: {
      hasAccess(admin) {
        if(admin) {
          if(this.user.admin){
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
      close() {
        this.open_dropdown = null
      },
      open(key) {
        if(this.open_dropdown == key) {
          this.open_dropdown = null
        } else {
          this.open_dropdown = key
        }
      }
    },
    data() {
      return {
        open_dropdown: null,
        items: [
          {title: "Home", url: "/"},
          {title: "Deals", dropdown: [
            {title: "Overzicht", url: "/deals"},
            {title: "Weekoverzicht", url: "/weeks/" + this.current_week + "/deals"},
          ]},
          {title: "Promoties", url: "/promotions"},
          {title: "Bedrijven", url: "/companies"},
          {title: "Redactie", url: "/tasks"},
          {title: "Tools", dropdown: [
            {title: "Genereer link", url: "/generate_link"},
            {title: "Nieuwsbrief", url: "/news_letter"},
          ]},
          {title: "Instellingen", dropdown: [
            {title: "Prijzen 💶", url: "/prices"},
            {title: "Bronnen", url: "/sources"},
            {title: "Mediums", url: "/media"},
            {title: "Bannerformaten", url: "/banner_sizes"},
            {title: "Channels", url: "/channels"},
            {title: "Periodes", url: "/periods"},
            {title: "Statussen", url: "/statuses"},
            {title: "Promotie types", url: "/promotion_types"},
            {title: "Product types", url: "/product_types"},
            {title: "Taak types", url: "/task_types"}
          ], admin: true},
          {title: "Statistieken", dropdown: [
            {title: "Tip Statistiek", url: "/tips/stats?weeks=10&tip_id=10002"},
            {title: "Tips", url: "/dagjeweg_items"},
            {title: "Facturatiestatus Doelgroepcampagnes", url: "/doelgroep-campagne-facturatie"}
          ]},
          {title: "Feeds", dropdown: [
            {title: "Advertenties", url: "/feed/12ee5296-a477-42bc-8eaf-6e80245126d2.xml?current=true"},
          ]},
          {title: "Imports", dropdown: [
            {title: "Imports", url: "/imports"},
            {title: "Import Errors", url: "/import_errors"},
            {title: "Moneybird Webhooks", url: "/moneybird_webhooks"}
          ], admin: true},
          {title: "Dashboard", url: "/campaign/dashboard"},
          {title: "Mails", dropdown: [
            {title: "Templates", url: "/mail_templates"},
            {title: "Verstuurde emails", url: "/emails"}
          ]},
        ]
      }
    }
  }
</script>