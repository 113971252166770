<template>
  <div class="row weeks bg-white mb-3">

    <div class="col-2">
    </div>
    <div class="col-1 text-right m-auto">
      <button @click="selectWeek('previous')"><i class='fas fa-backward'></i></button>
    </div>

    <div :day="day" v-for="day in days" :key="`day-${day.id}`" :class="`weeknumber font-weight-bold col-1`">
        {{ day.name }}<br>
        <span class="week-period font-weight-normal font-italic">{{day.date}}</span><br>
        <span class="week-period font-weight-normal font-italic">{{day.week}}</span>
    </div>

    <div class="col-1 m-auto">
      <button @click="selectWeek('next')"><i class='fas fa-forward'></i></button>
    </div>
    <div class="col-1"></div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Days',
    props: ["days", "currentweek"],
    methods: {
      selectWeek(direction) {
        var week = this.currentweek
        if(direction == "previous") {
          week = this.currentweek-1
        } else if (direction == "next") {
          week = this.currentweek+1
        }
        axios.get(`/days.json?week=${week}`).then(response => {
          this.$emit('clicked', response.data);
        })
      }
    }
  };

</script>

<style scoped>

</style>
