<template>
  <div class="w-50 px-2 pb-5" style="overflow-y: auto; height: 80%">
    <div style="max-width: 720px; margin: 0 auto" v-if="has_header">
      <div style="background-color: #121C3D; color: white; padding: 30px ;"> 
        <div style="font-size: 30px; display:table; text-align:center; margin: auto;" >
          <img src="https://assets.dagjeweg.group/nl/png/nl-beeldmerk-tekst.png" alt="Logo" height="100" width="100"/>
          <span style="display:table-cell; vertical-align:middle;">Adverteren</span>
        </div>
      </div>
      <div style="padding:30px">
        <div class="py-3" v-html="formattedContent">
        </div>
        <div class="py-3"> 
          Met vriendelijke groet,<br><br>
          {{ username }}<br>  
          <span style="font-weight:bold;">
            DagjeWeg.NL
          </span>
          <p>
            <img src="https://assets.dagjeweg.group/nl/png/nl-beeldmerk-tekst.png" alt="DagjeWeg.NL Logo" height="75" width="75"/>
          </p>
        </div>
      </div>
    </div>    
    <div v-else >
      <div class="py-3" v-html="formattedContent">
      </div>
      <div class="py-3"> 
        Met vriendelijke groet,<br><br>
        {{ username }}<br>  
        <span style="font-weight:bold;">
          DagjeWeg.NL
        </span>
        <p>
          <img src="https://assets.dagjeweg.group/nl/png/nl-beeldmerk-tekst.png" alt="DagjeWeg.NL Logo" height="75" width="75"/>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: [
    'username',
    'subject',
    'content',
    'has_header',
    'deal_name',
    'deal_id'
  ],
  computed: {
    formattedContent() {
      if (!this.content) return '';

      let formatted = this.content.replace(/\n/g, '<br>');

      let array = [['{','}'],['[',']']]

      array.forEach(character => {
        formatted = this.processLinks(formatted, character[0], character[1])
      })

      return formatted;
    },
  },

  methods: {
    processLinks(content, startSymbol, endSymbol) {
      let startIndex = content.indexOf(startSymbol);
      while (startIndex !== -1) {
        let endIndex = content.indexOf(endSymbol, startIndex + 1);
        if (endIndex === -1) break;

        const linkContent = content.substring(startIndex, endIndex + 1);
        const link = `<strong style="font-weight: bold; color:blue;">${linkContent}</strong>`;

        content = content.substring(0, startIndex) + link + content.substring(endIndex + 1);

        startIndex = content.indexOf(startSymbol, startIndex + link.length);
      }

      return content;
    },
  },
}
</script>