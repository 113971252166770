<template>
  <div>
    <div class="container-fluid filters d-flex justify-content-between" >
      <h1>
        Verstuurde email
      </h1>
      <span>
        <a href="/emails" class="btn btn-secondary btn-sm">Terug</a>
      </span>
    </div>
    
    <hr class="hr-top">
    
    <div class="container-fluid my-2">
      <div class="row">
        <div class="mx-auto col-12 col-lg-10">
          <div class="border w-100 p-3 rounded row my-3">
            <div class="col-1">
              <div class="p-1 m-1">
                Ontvanger
              </div>
              <div class="p-1 m-1">
                Onderwerp
              </div>
            </div>
            <div class="col-10">
              <div class=" w-100 p-1 rounded m-1">
                {{ email.sent_to }}
              </div>
              <div class=" w-100 p-1 rounded m-1">
                {{ email.subject ? email.subject : mail_template.subject }}
              </div>
            </div>
            <div class="col-1 text-right d-flex flex-column justify-content-around" @click="show_email == true ? show_email = false : show_email = true">
              <span class="btn btn-outline-primary btn-lg w-auto">
                <i class="far fa-chevron-down"></i>
              </span>
            </div>
            <div class="w-100 py-3 px-2 rounded mx-1 my-3 border-top" :class=" show_email ? '' : ' d-none' ">
              <div style="max-width: 720px; margin: 0 auto" v-if="header">
                <div style="background-color: #121C3D; color: white; padding: 30px ;"> 
                  <div style="font-size: 30px; display:table; text-align:center; margin: auto;" >
                    <img src="https://assets.dagjeweg.group/nl/png/nl-beeldmerk-tekst.png" alt="Logo" height="150" width="150"/>
                    <span style="display:table-cell; vertical-align:middle;">Adverteren</span>
                  </div>
                </div>
              </div>
              <div style="padding:30px" :style="header ? 'max-width: 720px; margin: 0 auto' : '' ">
                <p v-html="formatted_content">
                </p>
                <p>
                  Met vriendelijke groet,
                </p>
                <p>
                  {{ user }}<br />
                  <span style="font-weight:bold;">
                    DagjeWeg.NL
                  </span>
                </p>
                <img src='https://assets.dagjeweg.group/nl/png/nl-beeldmerk-tekst.png' alt="Logo DagjeWeg.NL" height=75, width=75>
              </div>
            </div>
          </div>


          <div class="border w-100 p-3 rounded row">
            <h4>
              Activiteiten voor deze mail
            </h4>
            <table class="table">
              <thead>
                <tr>
                  <th>Actie</th>
                  <th>Uitgevoerd op</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(mandrill_event, index) in mandrill_events" :key="index">
                  <td>
                    {{ mandrill_event[0] }}
                  </td>
                  <td>
                    {{  mandrill_event[1]}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: [
    'email',
    'formatted_content',
    'mandrill_events',
    'mail_template',
    'user',
    'header'
  ],

  data() {
    return {
      show_email: false
    }
  },

}

</script>