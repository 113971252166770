<template>
	<div>
		<ul class="list-group">
      <li class="list-group-item" v-for="date in clicks.dates">
        <p>{{date.name}}</p>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Promotie</th>
              <th scope="col" v-for="promotion in date.promotions">{{promotion.name}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Totaal</th>
              <td scope="col" v-for="promotion in date.promotions">{{promotion.total}}</td>
            </tr>
            <tr>
              <th scope="row">Uniek</th>
              <td scope="col" v-for="promotion in date.promotions">{{promotion.unique}}</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ul>
	</div>
</template>

<script>
  export default {
    name: 'OutgoingClicks',
    props: ["clicks"],
  };
</script>

<style scoped>

</style>
