<template>
  <div>
    <div class="row my-3">
      <!-- form -->
      <div class="col">
        <div class="form-group">
          <label class="font-weight-bold" for="name">Naam</label>
          <input type="text" name="name" class="form-control" :class="errors.includes('name') ? 'border-danger': ''" v-model="name">
          <small class="text-danger" v-if="errors.includes('name')">De naam moet ingevuld zijn</small>
        </div>
        <div class="form-group">
          <label class="font-weight-bold" for="subject">Onderwerp</label>
          <input type="text" name="subject" class="form-control" :class="errors.includes('subject') ? 'border-danger': ''" v-model="subject">
          <small class="text-danger" v-if="errors.includes('subject')">Het onderwerp moet ingevuld zijn</small>
        </div>
        <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" name="has_header" id="has_header" v-model="has_header">
          <label class="form-check-label font-weight-bold" for="has_header">
            Heeft header
          </label>
        </div>
        
        <div class="form-group position-relative relative p-relative">
          <label class="font-weight-bold" for="content">Inhoud</label>
          <textarea name="content" ref="content" cols="30" rows="10" :class="errors.includes('content') ? 'form-control border border-danger': 'form-control'" v-model="content" @click="getCaretPosition" @input="getCaretPosition"></textarea>
          <small class="text-danger" v-if="errors.includes('content')">De inhoud moet ingevuld zijn</small>
          <LinkModal @link_created="add_link" v-if="displayLinkModal"></LinkModal>
          <VariableModal @variable_created="add_variable" v-if="displayVariableModal"></VariableModal>
          <div class="mt-2">
            <button class="btn btn-outline-primary" @click="add_character('[')">Variabele toevoegen</button>
            <button class="btn btn-outline-primary" @click="add_character('{')">Link toevoegen</button>
          </div>
        </div>
      </div>
      <!-- example -->
      <LiveMailExample :content="content" :has_header="has_header" :subject="subject" :username="user" ></LiveMailExample>
    </div>
    <div class="d-flex"> 
      <button @click="save()" class="btn btn-success my-2 mr-2">
        Opslaan
      </button>
      <a :href="'/mail_templates/' + mail_template.id + '/sent_mails'" class="btn btn-info my-2 mr-2" v-if="mail_template.id">
        Alle verzonden mails
      </a>
      <a href="/mail_templates" class="btn btn-secondary my-2 ">
        Terug
      </a>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import LinkModal from './LinkModal.vue';
import LiveMailExample from './LiveMailExample.vue';

export default {
  name: 'liveForm',

  props: {
    user: String,
    mail_template: {
      type: Object, // Ensure that the type matches the data structure of mail_template
    }
  },

  components: {
    LinkModal,
    LiveMailExample
  },

  data() {
    return {
      name: this.mail_template ? this.mail_template.name : '',
      subject: this.mail_template ? this.mail_template.subject : '',
      content: this.mail_template && this.mail_template.content ? this.mail_template.content : 'Beste [Deal#customer_name], ',
      has_header: this.mail_template ? this.mail_template.has_header : false,
      caretPosition: 0,
      errors: [],
    }
  },

  methods: {
    save(){
      this.errors = []
      if(!this.name || !this.subject || !this.content){
        if (!this.name){
          this.errors.push("name")
        }
        if (!this.subject){
          this.errors.push("subject")
        }
        if (!this.content){
          this.errors.push("content")
        }
      }else{
        const payload = {
          name: this.name,
          subject: this.subject,
          content: this.content,
          has_header: this.has_header
        }

        if (this.mail_template.id){
          axios.patch(`/mail_templates/${this.mail_template.id}.json`, payload).then(response => {
            console.log(response)
          })
        }else{
          axios.post('/mail_templates.json', payload).then(response => {
            window.location.href = response.headers.location
          });
        }
      } 
    },

    add_character(character){
      if (this.caretPosition){
        this.content = this.content.slice(0, this.caretPosition) + character + this.content.slice(this.caretPosition);
        this.caretPosition = this.caretPosition + 1
        this.$refs.content.setSelectionRange(this.caretPosition, this.caretPosition);
      }else{
        this.content += character
        this.caretPosition = this.content.length
        this.$refs.content.focus();
      }
    },
    
    getCaretPosition(event) {
      this.caretPosition = event.target.selectionStart;
    },

    moveCaret(position){
      this.$refs.content.focus();
      this.$nextTick(() => {
        if (this.$refs.content.setSelectionRange) {
          this.caretPosition = position
          this.$refs.content.setSelectionRange(position, position);
        } else {
          const range = this.$refs.content.createTextRange();
          range.move('character', position);
          range.select();
        }
      });
    },
    
    add_link(event){
      var link = event
      
      this.content = this.content.slice(0, this.caretPosition) + link + this.content.slice(this.caretPosition);
      this.link_location = ''
      this.link_text = ''

      this.moveCaret(this.caretPosition + link.length)
      
    },
    
    add_variable(event){
      var variable = event
      
      this.content = this.content.slice(0, this.caretPosition) + variable + this.content.slice(this.caretPosition);
      this.variable_method = ''
      this.variable_text = ''
      
      this.moveCaret(this.caretPosition + variable.length)
    }
  },

  computed: {
    displayLinkModal(){
      if (this.caretPosition > 0 && this.content[this.caretPosition - 1] === "{"){
        return true
      }
    },  
    displayVariableModal(){
      if (this.caretPosition > 0 && this.content[this.caretPosition - 1] === "["){
        return true
      }
    },  

    formattedContent() {
      if (this.content){
        let content = this.content.replace(/\n/g, '<br>');

        let startIndex = content.indexOf('{');
        let result = '';
        
        while (startIndex !== -1) {
          const endIndex = content.indexOf('}', startIndex + 1);
          if (endIndex !== -1) {
            const linkContent = content.substring(startIndex + 1, endIndex);
            const [url, text] = linkContent.split('>');
            const link = `<a style="color:blue;" href="${url.trim()}">${text.trim()}</a>`;
            
            result += content.substring(0, startIndex);
            result += link;
            
            content = content.substring(endIndex + 1);
            startIndex = content.indexOf('{');
          } else {
            break;
          }
        }
        result += content;
        return result;
      }
    }
  }
}

</script>
<style scoped>
  .min-height{
    min-height: 500px;
  }
</style>