<template>
	<select v-model="deal.status_id" class="form-control d-inline w-25 mr-3" v-bind:disabled="deal_is_rejected">
        <option v-for="status in statuses"  :value="status.id">{{status.name}}</option>
    </select>
</template>

<script>

  export default {
    name: "DealStatus",
    props: ["statuses", "deal"],
    data () {
    	return {
    		initialStatusId: null
    	}
    },
    created(){
		  this.initialStatusId = this.deal.status_id
    },
    computed: {
      deal_is_rejected() {
      	return this.statuses.filter(status => status.name == "Afgewezen")[0].id == this.initialStatusId
      },
    },
  };
</script>

<style scoped>

</style>
