<template>
  <div class="row">
    <div class="offset-lg-1 col-6">
      <div v-if="selected_medium" class="label d-inline align-middle">
        <p>{{selected_source.name}}
          <button @click="removeSelectedSource">
            <i class="fal fa-times-circle" style="color: red;"></i>
          </button>
          <i class="fas fa-chevron-right" style="color: rgb(38, 153, 251);"></i>
        </p>
        <p>{{selected_medium.name}}
          <button @click="removeSelectedMedium">
            <i class="fal fa-times-circle" style="color: red;"></i>
          </button>
          <i class="fas fa-chevron-right" style="color: rgb(38, 153, 251);"></i>
        </p>
      </div>

      <div v-else-if="selected_source" class="label d-inline align-middle">
        <p>{{selected_source.name}}
          <button @click="removeSelectedSource">
            <i class="fal fa-times-circle" style="color: red;"></i>
          </button>
          <i class="fas fa-chevron-right" style="color: rgb(38, 153, 251);"></i>
        </p>
      </div>

      <div v-else class="label d-inline align-middle">
        <p>Bron</p>
      </div>
      <div class="channel-filter-options mr-1" v-if="selected_medium">
      </div>
      <div class="channel-filter-options mr-1" v-else-if="selected_source">
        <div v-for="(medium, $index) in media.media" :key="`medium-${$index}`" v-if="medium.source == selected_source.id" class="buttons">
          <button @click="selectMedium(medium)" class="btn btn-outline-primary filter">{{ medium.name }}</button>
        </div>
      </div>
      <div class="channel-filter-options mr-1" v-else>
        <div v-for="(source, $index) in sources.sources" :key="`source-${$index}`" class="buttons">
          <button @click="selectSource(source)" class="btn btn-outline-primary filter">{{ source.name }}</button>
        </div>
      </div>
    </div>
    <div class="col-4" style="height:3rem;">
      <multiselect :custom-label="customLabel" @close="updateChannelSelection" v-model="selectedChannels" :options="selectChannels" :multiple="true" :close-on-select="false" :taggable="false" :clear-on-select="true" :preserve-search="true" placeholder="Kies de channels" label="name" track-by="id" style="margin-top: 5px;">
        <template slot="selection" slot-scope="{ values, search, isOpen }">
          <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} channels geselecteerd</span>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
  import SalesPlanner from '../../packs/salesplanner.js'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'FilterChannel',
    props: ["sources", "media", "channels"],
    created() {
      this.listChannels = this.channels.channels
      if(window.store.getters.selected_channels.length) {
        this.selectedChannels = window.store.getters.selected_channels
      }
    },
    computed: {
      selected_channels() {
        return window.store.getters.selected_channels
      },
      selected_medium() {
        return window.store.getters.medium
      },
      selected_source() {
        return window.store.getters.source
      },
      selectChannels() {
        return this.listChannels
      }
    },
    components: {
          Multiselect
    },
    data () {
      return {
        value: null,
        selectedChannels: [],
        listChannels: [],
      }
    },
    methods: {
      customLabel ({ medium, name }) {
        if(this.selected_medium){
          return `${name}`
        }else if(this.selected_source) {
          return `${medium.name} > ${name}`
        } else {
          return `${medium.source.name}>${medium.name} > ${name}`
        }
      },
      onSelect() {
        window.store.dispatch("setPeriod", null)
        window.store.dispatch("setSelectedWeek", this.value)
        window.store.dispatch("resetGetters")
        SalesPlanner.getChannelsAndWeeks("week")
      },
      getOptions() {
        var array = []
        this.channels.channels.map((channel) => {
          array.push({id: channel.id, name: channel.name});
        });
        return array
      },
      updateChannelSelection() {
        window.store.dispatch("setChannelIds", this.selectedChannels.map((channel)=>channel.id))
        SalesPlanner.getChannelsAndWeeks("filter", this.selectedChannels.map((channel)=>channel.id))
      },
      removeSelectedSource() {
        this.listChannels = this.channels.channels
        this.selectedChannels = [];
        window.store.dispatch("setChannelIds", [])
        window.store.dispatch("resetSelectedChannels")
        window.store.dispatch("setMedium", null)
        window.store.dispatch("setSource", null)
        window.store.dispatch("resetGetters")
        SalesPlanner.getChannelsAndWeeks("removesource")
      },
      selectSource(source) {
        this.listChannels = this.listChannels.filter(channel => channel.medium.source.name == source.name);
        this.selectedChannels = [];
        window.store.dispatch("setChannelIds", [])
        window.store.dispatch("setSource", source)
        window.store.dispatch("resetGetters")
        SalesPlanner.getChannelsAndWeeks("source")
      },
      removeSelectedMedium() {
        this.listChannels = this.channels.channels.filter(channel => channel.medium.source.name == this.selected_source.name);
        this.selectedChannels = [];
        window.store.dispatch("setChannelIds", [])
        window.store.dispatch("resetSelectedChannels")
        window.store.dispatch("setMedium", null)
        window.store.dispatch("resetGetters")
        SalesPlanner.getChannelsAndWeeks("removemedium")
      },
      selectMedium(medium) {
        this.listChannels = this.listChannels.filter(channel => channel.medium.name == medium.name);
        this.selectedChannels = [];
        window.store.dispatch("setChannelIds", [])
        window.store.dispatch("setMedium", medium)
        window.store.dispatch("resetGetters")
        SalesPlanner.getChannelsAndWeeks("medium")
      },
    }
  };
</script>

<style scoped>

</style>
