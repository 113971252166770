<template>
	<div>
    <Keypress :key-code="27" event="keyup" @pressed="closeUploader" />
    <div class="container pt-2">
      <div class="row" v-if="tourTaken" id="deal">
        <div class="col">
          <div class="card mb-3 shadow-sm">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-5">
                  <div class="thumbnail float-left">
                    <div class="float-left">
                      <h4>{{ deal.title }}</h4>
                      <h5 class="mb-0 small text-uppercase text-muted" v-if="deal.advertiser">{{ deal.advertiser.name }}</h5>
                    </div>
                  </div>
                </div>
              </div>          
            </div>
          </div>
        </div>  
      </div>

      <div class="row fadeIn animated mt-5 pt-5" v-if="!tourTaken">
        <div class="col-12 col-md-10 col-lg-8 mx-auto">
          <div class="card mb-3 shadow-sm">
            <div class="card-body">
              <center><h1>Welkom</h1></center>
              <p class="lead text-center">Welkom bij het aanleversysteem van {{ sources }}. Op deze pagina kun je het benodigde materiaal aanleveren. </p>
              <hr>
              <h4>Zo werkt het het</h4>
              <p>Het systeem controleert vanzelf of het materiaal aan de afgesproken specificaties voldoet. Als dat zo is, controleert een medewerker van {{ sources}} het aangeleverde materiaal.</p>
              <p>Als de deadline voor het aanleveren nadert en er ontbreken nog bestanden, dan zul je van dit systeem automatisch een herinnering via je e-mail krijgen.</p>
              <h4>We helpen graag</h4>
              <p>Bij vragen kun je altijd contact opnemen met de mensen van {{ sources}}. Jouw contactpersoon is {{deal.user.name}}. Je kunt ook een chat starten via de knop rechtsonder in je scherm. We geven hier vaak heel snel een reactie op.</p>
              <center><button @click="startTour" class="btn btn-success">Start een korte rondleiding </button></center>
            </div>
          </div>
        </div>  
      </div>


      <div class="row fadeIn animated" v-if="tourTaken">
        <div class="col" id="actions" >
          <h3>Actie ondernemen</h3>
          <div class="card mb-3 shadow-sm">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col">
                  <span class="badge badge-success p-2 mr-3" v-if="deal.status_code == 100"><i class="fal fa-flag-checkered fa-2x"></i></span>
                  <h4 class="h6" style="display: inline;">{{deal.status_title}}</h4>
                </div>
              </div>
              <div class="row"><div class="col">
                {{ deal.material_status}}
              </div></div>
            </div>
          </div>
        </div>


        <div class="col" id="details">
          <h3>Details campagne</h3>
          <div class="card mb-3 shadow-sm">
            <div class="card-header bg-dark text-light">
              <i class="fas fa-user-tie mr-2"></i> Je contactpersoon
            </div>
            <div class="card-body ">
              <span v-if="deal.user">Je contactpersoon is <b>{{ deal.user.name }}</b>.<br>Neem bij vragen gerust contact op!</span>
              <div class="">
                <i class="fal fa-phone mr-3"></i><span>+31 (0)318 640 289</span><br>
              </div>
              <div class="">
                <i class="fal fa-at mr-3"></i><span>sales@dagjeweg.nl</span>
              </div>
              <img :src="deal.user.image" v-if="deal.user.image" height="100" style="position: absolute; bottom: 0px; right: 10px;">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pt-2 fadeIn animated" id="campaigns" v-if="tourTaken">
  		<div class="row">
  			<div class="col">
  				<h2 @click="closeUploader">
  					<span v-if="deal.campaigns_attributes.length > 1">Campagnes</span>
  					<span v-if="deal.campaigns_attributes.length == 1">Campagne</span>
  					<span v-if="selectedCampaignItem" class="text-muted" style="cursor: pointer;">
  						/ {{selectedCampaign.title }} / {{ selectedCampaignItem.medium.name }} / {{ selectedRequirement.name }}
  					</span>
  				</h2>
  				
          <CampaignCard :user_signed_in="user_signed_in" @uploadComplete="uploadComplete" @open="showCampaignItem" :campaign="campaign" :stats="stats" :staturls="staturls" v-for="campaign in futureCampaigns" v-if="selectedCampaignItem === null" v-bind:key="campaign.id"></CampaignCard>
  			</div>
  		</div>
    </div>

    <div class="container pt-2 fadeIn animated" v-if="selectedCampaignItem === null && currentCampaigns.length && tourTaken">
      <div class="row">
        <div class="col">
          <h3 @click="closeUploader">
            <span v-if="currentCampaigns.length > 1">Actieve campagnes</span>
            <span v-if="currentCampaigns.length == 1">Actieve campagne</span>
          </h3>
          <CampaignCard :user_signed_in="user_signed_in" @open="showCampaignItem" :campaign="campaign" :stats="stats" :staturls="staturls" v-for="campaign in currentCampaigns" v-bind:key="campaign.id"></CampaignCard>
        </div>
      </div>
    </div>

    <div class="container pt-2 fadeIn animated" v-if="selectedCampaignItem === null && pastCampaigns.length && tourTaken">
      <div class="row">
        <div class="col">
          <h3 @click="closeUploader">
            <span v-if="pastCampaigns.length > 1">Campagnes uit het verleden</span>
            <span v-if="pastCampaigns.length == 1">Campagne uit het verleden</span>
          </h3>
          <CampaignCard :user_signed_in="user_signed_in" @open="showCampaignItem" :campaign="campaign" :stats="stats" :staturls="staturls" v-for="campaign in pastCampaigns" v-bind:key="campaign.id"></CampaignCard>
        </div>
      </div>
    </div>

    <div class="container-fluid fadeIn animated" v-if="selectedCampaignItem && tourTaken">
      <div class="row">

        <div class="col-8 col-lg-9 mr-0 pr-0" style="position: relative; z-index: 800;" v-bind:class="{'mx-auto': !selectedCampaignItem.specs}">
          <div class="card mb-5 shadow" >
            <MaterialUploader @uploadComplete="uploadComplete" @close="closeUploader" :user_signed_in="user_signed_in" :campaign="selectedCampaign" :requirement="selectedRequirement" :sources="sources" :campaign_item="selectedCampaignItem"></MaterialUploader>          
          </div>
        </div>
        <div class="col-4 col-lg-3 ml-0 pl-0" style="position: relative; left: -30px; z-index: 0;" v-if="selectedCampaignItem.specs">
          <div class="sticky-top pt-5">
            <div class="card mt-5 shadow-sm" style="transform: rotate(3deg);">
              <div class="card-body pl-5 handwriting" style="font-size: 12pt;">
                <h4>Tips</h4>
                <div v-html="selectedCampaignItem.specs"></div>
                <a class="btn btn-primary" :href="selectedCampaignItem.spec_sheet" target="_blank" v-if="selectedCampaignItem.spec_sheet">Download de aanleverspecificaties <i class="fal fa-file-pdf"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-tour name="myTour" :options="tourOptions" :steps="steps"></v-tour>
	</div>
</template>

<script>

import Keypress from 'vue-keypress'
import CampaignCard from '../deals/CampaignCard.vue'
import MaterialUploader	from '../deals/MaterialUploader.vue'

export default {
	props: ['initial_deal', 'user_signed_in', 'sources', 'tour_taken', 'stats', 'staturls'],
  name: 'CampaignList.vue',
  components: {MaterialUploader, CampaignCard, Keypress},
  data () {
    return {
    	deal: null,
      tourTaken: false,
    	selectedCampaign: null,
    	selectedCampaignItemId: null,
      selectedRequirementId: null,
      tourOptions: {
        highlight: true,
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: 'Stoppen',
          buttonPrevious: 'Vorige',
          buttonNext: 'Volgende',
          buttonStop: 'Klaar!'
        }
      },
      steps: [
          {
            target: '#deal',
            header: {
              title: 'Deze pagina is van jou!'
            },
            content: `Zoals je ziet, gaat deze pagina helemaal over het <b>aanleveren van jouw reclame-uitingen</b>. Je kunt op elk moment naar deze pagina terugkomen om materiaal aan te leveren of te kijken wat de status van de afgesproken campagne is.`
          },
          {
            target: '#actions',  // We're using document.querySelector() under the hood
            header: {
              title: 'Actuele status',
            },
            content: `Op deze plek kun je steeds zien wat er op dit moment aan de hand is en wat de volgende stap is die genomen moet worden. Als al het materiaal is aangleverd, kun je zien of het inmiddels is goedgekeurd en ingepland.`
          },
          {
            target: '#details',
            content: 'Als je ergens tegenaan loopt, kun je altijd contact opnemen met je contactpersoon.'
          },
          {
            target: '#campaigns',
            header: {
              title: 'Campagnes'
            },
            content: 'Hier draait het allemaal om. Op deze plek staan jouw campagnes. Je ziet de afgesproken channels en weken.',
            params: {
              placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            }
          },
          {
            target: '.material-field',
            header: {
              title: 'Aanleveren'
            },
            content: 'Klik op deze knop om het beeldmateriaal aan te leveren. Je kunt op elk gewenst moment terugkomen om het materiaal te bekijken. Per uiting kun je verschillende bestanden uploaden voor de verschillende weken of channels.',
            params: {
              placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            }
          }
        ]
    }
  },
  created(){
  	this.deal = this.initial_deal
    this.tourTaken = this.tour_taken
  },
  mounted: function () {
    // this.$tours['myTour'].start()
  },
  computed: {
    futureCampaigns(){
      return this.deal.campaigns_attributes.filter(campaign => campaign.is_in_future);
    },
    currentCampaigns(){
      return this.deal.campaigns_attributes.filter(campaign => campaign.is_current);
    },
    pastCampaigns(){
      return this.deal.campaigns_attributes.filter(campaign => !campaign.is_in_future && !campaign.is_current);
    },
  	selectedCampaignItem(){

  		if (this.selectedCampaignItemId){
  			for (var i = this.deal.campaigns_attributes.length - 1; i >= 0; i--) {
  				let campaign = this.deal.campaigns_attributes[i]
  				for (var j = campaign.campaign_items_attributes.length - 1; j >= 0; j--) {
	  				if (campaign.campaign_items_attributes[j].id == this.selectedCampaignItemId){
	  					this.selectedCampaign = this.deal.campaigns_attributes[i]
	  					return campaign.campaign_items_attributes[j]
	  				}
	  			}
  			}
  			return null
  		} else {
  			return null
  		}
  	},
    selectedRequirement(){

      if (this.selectedRequirementId){
        for (var i = this.selectedCampaignItem.requirements.length - 1; i >= 0; i--) {
          let requirement = this.selectedCampaignItem.requirements[i]
          if (requirement.id == this.selectedRequirementId) {
            return this.selectedCampaignItem.requirements[i]
          }
        }
        return null
      } else {
        return null
      }
    }
  },
  methods: {
    startTour(){
      this.tourTaken = true
      this.$tours['myTour'].start()
    },
  	uploadComplete(response){
  		console.log("upload completed!")
  		this.deal = response
  	},
  	closeUploader(){
			this.selectedCampaignItemId = null
      this.selectedRequirementId  = null
  	},
  	showCampaignItem(item){
  		console.log(item)
  		this.selectedCampaignItemId = item.item.id
      this.selectedRequirementId    = item.requirement.id
  	}
  }
}
</script>

<style lang="css" scoped>
</style>