<template>
  <tr>
    <th scope="row">{{ deal.id }}</th>
    <td><a class="no_link" :href="`/deals/${deal.id}/edit`">{{ deal.title }}</a></td>
    <td><a class="no_link" :href="`/deals/${deal.id}/edit`">{{ deal.estimate_number }}</a></td>
    <td>{{ deal.date }}</a></td>
    <td><a class="no_link" :href="`/companies/${deal.customer.id}/`">{{ deal.customer.name }}</a></td>
    <td><a class="no_link" :href="`/companies/${deal.advertiser.id}/`">{{ deal.advertiser.name }}</a><i @click="searchForCompany(deal.advertiser.name)" class="ml-2 fas fa-search"></i></td>
    <td>{{ deal.price }}</td>
    <td><button :style="`color: #212529; background-color: ${deal.status.color}; border-color: ${deal.status.color}; left: 1px;`" :class="`btn btn-sm btn-block`">{{ deal.status.name }}</button></td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/deals/${deal.id}/edit`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListDeal',
    props: ["deal"],
    methods: {
      searchForCompany(name) {
        window.store.dispatch("setFilter", name.toLowerCase())
      },
    }
  };
</script>

<style scoped>

</style>
