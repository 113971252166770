<template>
  <div class="container-fluid">
    <div class="row">
      <div class="mx-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>DagjeWeg.NL ID</th>
              <th>Naam</th>
              <th>Type</th>
              <th>DagjeWeg.NL Tickets ID</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListDagjewegItem :dagjewegitem="dagjewegitem" v-for="(dagjewegitem, $index) in dagjewegitems" :key="$index"> </ListDagjewegItem>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListDagjewegItem from './ListDagjewegItem';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'DagjewegItems',
    components: {
      ListDagjewegItem,
    },
    created(){
      window.store.dispatch("resetVariables")
      window.store.dispatch("setOrder", "id-desc")
      SalesPlanner.getDagjewegItems();
    },
    methods: {
      reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
          ret.push(input[i]);
        }
        return ret;
      },
    },
    computed: {
      dagjewegitems() {
        var dagjeweg_items = window.store.getters.dagjeweg_items;


        if(window.store.getters.order == "id-desc") {
          dagjeweg_items = this.reverseArr(dagjeweg_items)
        } else if(window.store.getters.order == "id-asc") {
          dagjeweg_items = dagjeweg_items.sort();
        }

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          dagjeweg_items = dagjeweg_items.filter(dagjeweg_item =>
            (dagjeweg_item.name.toLowerCase().includes(window.store.getters.filter)) ||
            (dagjeweg_item.dagjeweg_id.toString().includes(window.store.getters.filter)) ||
            (dagjeweg_item.type.toLowerCase().includes(window.store.getters.filter)));
        }

        return dagjeweg_items;
      },
    }
  };
</script>
<style scoped>

</style>
