import { render, staticRenderFns } from "./OutgoingClicks.vue?vue&type=template&id=10803b66&scoped=true&"
import script from "./OutgoingClicks.vue?vue&type=script&lang=js&"
export * from "./OutgoingClicks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10803b66",
  null
  
)

export default component.exports