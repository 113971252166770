<template>
  <!-- Index -->
  <div class="row bg-white fixed-bottom deal_footer">
    <div class="col-8 offset-1 mt-1">
      <strong>Legenda</strong>
      <table class="legend">
        <tbody>
          <tr>
            <td>
              <div class="colorbox" style="background: rgb(251, 254, 211);"></div>
            </td>
            <td>
              Optie
            </td>
            <td>
              <div class="colorbox" style="background: rgb(254, 215, 171);"></div>
            </td>
            <td>
              Bezet
            </td>
            <td>
              <div class="colorbox" style="background: rgb(146, 210, 161);"></div>
            </td>
            <td>
              Ingepland
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-1">
      <p class="h6 mt-2" v-if="calculatedTotalPrice">Prijssysteem:</p>
      <hr class="m-0 mt-2 mb-2"/>
      <p class="h6 mt-2" v-if="totalDiscount">Spreadsheet:</p>
      <p class="h6" v-if="totalInvisibleSurcharge">Toeslag:</p>
      <p class="h6" v-if="totalDiscount">Korting:</p>
      <hr class="m-0" v-if="totalDiscount" />
      <p class="h5 mt-0" v-bind:class="{ 'mt-2': !totalDiscount }">Totaal:</p>
    </div>
    <div class="col-1">
      <p class="h6 mt-2 text-right" v-if="calculatedTotalPrice">€{{calculatedTotalPrice}},00</p>
      <hr class="m-0 mt-2 mb-2" />
      <p class="h6 mt-2 text-right" v-if="totalDiscount">€{{totalPrice}},00</p>
      <p class="h6 text-right" v-if="totalInvisibleSurcharge">€{{totalInvisibleSurcharge}},00</p>
      <p class="h6 text-right" v-if="totalDiscount">- €{{totalDiscount}},00</p>
      <hr class="m-0" v-if="totalDiscount || totalInvisibleSurcharge" />
      <p class="h5 text-right mt-0" v-bind:class="{ 'mt-2': !totalDiscount || !totalInvisibleSurcharge }">€{{calculatedPrice}},00</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DealFooter',
    props: ["totalPrice", "totalDiscount", "calculatedTotalPrice", "totalInvisibleSurcharge"],
    computed: {
      calculatedPrice() {
        return this.totalPrice - this.totalDiscount + this.totalInvisibleSurcharge
      }
    }
  };
</script>

<style scoped>

</style>
