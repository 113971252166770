<template>
  <tr>
    <td>{{ mailTemplate.id }}</td>
    <td>{{ mailTemplate.name }}</td>
    <td>{{ mailTemplate.email_count }}</td>
    <td class="text-right">
      <a :href="'/mail_templates/' + mailTemplate.id + '/edit'" class="btn btn-outline-primary">Bekijken</a>
      <button @click="removeMailTemplate" class="btn btn-outline-danger" v-if="!mailTemplate.email_count">Verwijderen</button>
    </td>
  </tr>
</template>

<script>
  import axios from "axios";	

  export default {
    name: "ListMailTemplate",
    props: ["mailTemplate"],

    methods: {
      removeMailTemplate() {
        if( confirm('Wil je deze template verwijderen?')){
          if (this.mailTemplate.id != null){
            axios.delete(`/mail_templates/${this.mailTemplate.id}.json`).then(response => {
                this.$emit('mailTemplateDeleted');
            })
          }
        }
      },
    }
  }
</script>