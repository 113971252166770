<template>
  <tr>
    <td>{{ importerror.text }}</td>
    <td>{{ importerror.week.week_number }}/{{ importerror.week.year }}</td>
    <td>
      <a v-if="!importerror.assigned" href="/import_errors/" class="btn btn-outline-primary btn-sm btn-block m-0">koppelen</a>
      <span v-else class="btn btn-outline-success btn-sm btn-block m-0 text-uppercase">gekoppeld</span>
    </td>
    <td>
      <a v-if="!importerror.solved" href="/import_errors/" class="btn btn-outline-primary btn-sm btn-block m-0">oplossen</a>
      <span v-else class="btn btn-outline-success btn-sm btn-block m-0 text-uppercase">opgelost</span>
    </td>

  </tr>
</template>

<script>
  export default {
    name: 'ListImportError',
    props: ["importerror"]
  };
</script>

<style scoped>

</style>
