<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Naam</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListProductType :producttype="producttype" v-for="(producttype, $index) in product_types" :key="$index"> </ListProductType>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListProductType from './ListProductType';
  import SalesPlanner from '../../packs/salesplanner.js'
  import axios from 'axios'

  export default {
    name: 'PromotionTypes',
    components: {
      ListProductType,
    },
    data () {
      return {
        product_types: [],
      }
    },
    created(){
      axios.get('/product_types.json').then(response => (this.product_types = response.data))
    },
    computed: {
      promotiontypes() {
        var filtered_product_types = [];
        filtered_product_types = this.product_types;
        filtered_product_types = filtered_product_types.sort();

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          filtered_product_types = filtered_product_types.filter(product_type =>
            (product_type.name.toLowerCase().includes(window.store.getters.filter)) ||
            (product_type.id.toString().includes(window.store.getters.filter)));
        }

        return filtered_product_types;
      },
    }
  };
</script>
<style scoped>

</style>
