<template>
  <div class="row" style="margin-top: 0.375rem;">
    <div class="offset-lg-1 col-10 col-lg-8">
      <div v-if="selected_period" class="label d-inline align-middle">
        <p>{{selected_period_year}}
          <button @click="removeSelectedPeriodYear">
            <i class="fal fa-times-circle" style="color: red;"></i>
          </button>
          <i class="fas fa-chevron-right" style="color: rgb(38, 153, 251);"></i>
        </p>
      </div>

      <div v-else-if="selected_period_year" class="label d-inline align-middle">
        <p>{{selected_period_year}}
          <button @click="removeSelectedPeriodYear">
            <i class="fal fa-times-circle" style="color: red;"></i>
          </button>
          <i class="fas fa-chevron-right" style="color: rgb(38, 153, 251);"></i>
        </p>
      </div>

      <div v-else class="label d-inline align-middle">
        <p>Periode</p>
      </div>
      <div class="mr-1 period-filter-options" v-if="selected_period_year">
        <div v-for="(period, $index) in periods" :key="`period-${$index}`" class="buttons" v-if="period.includes(selected_period_year)">
          <button @click="selectPeriod(period)" :class="`btn btn-outline-primary filter ${active_period(period)}`">{{ period }}</button>
        </div>
      </div>
      <div class="mr-1 period-filter-options" v-else>
        <div v-for="(period_year, $index) in period_years" :key="`period_year-${$index}`" class="buttons" v-if="period_year != '2016'">
          <button @click="selectPeriodYear(period_year)" class="btn btn-outline-primary filter">{{ period_year }}</button>
        </div>
      </div>
    </div>
    <div class="col-2" style="height:3rem;">
      <multiselect v-model="value" :options="weeks.weeks" ref="week" placeholder="Weken" :select-label="'Selecteer'" label="name" track-by="name" @input="onSelect" style="margin-top: 5px;"></multiselect>
    </div>
  </div>
</template>

<script>
  import SalesPlanner from '../../packs/salesplanner.js'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'FilterPeriod',
    props: ["period_years", "periods", "weeks"],
    components: {
      Multiselect
    },
    data () {
      return {
        value: null,
      }
    },
    computed: {
      selected_period_year() {
        return window.store.getters.period_year
      },
      selected_period() {
        return window.store.getters.period
      }
    },
    methods: {
      selectPeriod(period) {
        if(period == window.store.getters.period) {
          window.store.dispatch("setPeriod", null)
          window.store.dispatch("resetGetters")
          SalesPlanner.getChannelsAndWeeks("removeperiod")
        } else {
          window.store.dispatch("setPeriod", period)
          window.store.dispatch("resetGetters")
          SalesPlanner.getChannelsAndWeeks("period")
        }
      },
      onSelect() {
        window.store.dispatch("setPeriod", null)
        window.store.dispatch("setSelectedWeek", this.value)
        window.store.dispatch("resetGetters")
        SalesPlanner.getChannelsAndWeeks("week")
      },
      selectPeriodYear(period_year) {
        window.store.dispatch("setPeriodYear", period_year)
      },
      removeSelectedPeriod(period_year) {
        window.store.dispatch("setPeriod", null)
        window.store.dispatch("resetGetters")
        SalesPlanner.getChannelsAndWeeks("removeperiod")
      },
      removeSelectedPeriodYear() {
        window.store.dispatch("setPeriodYear", null)
        window.store.dispatch("setPeriod", null)
        window.store.dispatch("resetGetters")
        SalesPlanner.getChannelsAndWeeks("removeperiod")
      },
      active_period(period) {
        if(period == window.store.getters.period) {
          return "active"
        } else {
          return null
        }
      }
    }
  };
</script>

<style scoped>

</style>
