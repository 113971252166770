<template>
  <tr>
    <td>{{ status.id }}</td>
    <td>{{ status.name }}</td>
    <td><div class="colorbox" :style="`background: ${status.color};`"></div></td>
    <td>{{ status.linked_to }}</td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/statuses/${status.id}/edit`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListStatus',
    props: ["status"]
  };
</script>

<style scoped>

</style>
