import axios from 'axios'

export default {
  getMailTemplates() {
    var url = `/mail_templates.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setMailTemplates", response.data)
      })
  },
  getEmails() {
    var url = `/emails.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setEmails", response.data)
      })
  },

  getDeals() {
    var url = `/deals.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setDeals", response.data)
      })
  },

  getCompanies() {
    var url = `/companies.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setCompanies", response.data)
      })
  },

  getDagjewegItems() {
    var url = `/dagjeweg_items.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setDagjewegItems", response.data)
      })
  },

  getUsers() {
    var url = `/users.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setUsers", response.data)
      })
  },

  getWeeks() {
    var url = `/weeks.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setWeeks", response.data)
          window.store.dispatch("setUpcomingWeeks", response.data.upcoming_weeks)
      })
  },

  getChannels() {
    var url = `/channels.json?display=list`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setChannelsList", response.data)
      })
  },

  getPeriods() {
    var url = `/periods.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setPeriods", response.data)
      })
  },

  getMedia() {
    var url = `/media.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setMedia", response.data)
      })
  },

  getSources() {
    var url = `/sources.json?display=list`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setSources", response.data)
      })
  },

  getStatuses() {
    var url = `/statuses.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setStatuses", response.data)
      })
  },

  getPromotionTypes() {
    var url = `/promotion_types.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setPromotionTypes", response.data)
      })
  },

  getImportErrors() {
    var url = `/import_errors.json`
    return axios.get(url)
      .then(response => {
          window.store.dispatch("setImportErrors", response.data)
      })
  },

  copyCampaignItem(type, campaignItem) {
    var url = `/campaign_items/${campaignItem.id}/copy.json?type=${type}`
    return axios.get(url)
      .then(response => {
        window.store.dispatch("setDeal", response.data)
      })
  },

  createCampaign() {
    var url = `/deals/${window.store.getters.deal.id}/campaigns/new.json`
    return axios.get(url)
      .then(response => {
         window.store.dispatch("setDeal", response.data)
      })
  },

  saveCampaignItem(campaignItem, campaign) {
    var url = `/campaign_items/${campaignItem.id}.json`
    return axios.put(url, {campaign_item: campaignItem, campaign: campaign})
      .then(response => {
        window.store.dispatch("setDeal", response.data)
      })
  },

  setGaCode(campaign, code) {
    var url = `/campaigns/${campaign.id}.json`
    return axios.put(url, {code: code})
      .then(response => {
        window.store.dispatch("setDeal", response.data)
      })
  },

  getContacts(selectedcompany) {
    console.log(selectedcompany)
  },

  saveDealWithoutReload() {
    var theDeal = {...window.store.getters.deal}
    theDeal.advertiser_id = window.store.getters.deal.advertiser.id
    theDeal.customer_id   = window.store.getters.deal.customer.id
    if (window.store.getters.deal.user) {
      theDeal.user_id     = window.store.getters.deal.user.id
    }
    if (window.store.getters.deal.contact) {
      theDeal.contact_id     = window.store.getters.deal.contact.id
      theDeal.contact_email  = window.store.getters.deal.contact.name
    }
    return axios.put(`/deals/${window.store.getters.deal.id}.json`, {deal: theDeal})
      .then(response => {
        window.store.dispatch("setStatusId", response.data.status_id)
        window.store.dispatch("setDeal", response.data)
        console.log("done saving")
      })
  },

  exportDeal() {
    return axios.post(`/deals/${window.store.getters.deal.id}/propagate.json`)
    .then(response => {
      this.isExporting = false
      window.location.replace(`/deals/${response.data.id}/edit`)
    })
  },

  saveDeal() {
    var theDeal = {...window.store.getters.deal}
    theDeal.advertiser_id = window.store.getters.deal.advertiser.id
    theDeal.customer_id   = window.store.getters.deal.customer.id
    if (window.store.getters.deal.user) {
      theDeal.user_id     = window.store.getters.deal.user.id
    }
    if (window.store.getters.deal.contact) {
      theDeal.contact_id     = window.store.getters.deal.contact.id
      theDeal.contact_email  = window.store.getters.deal.contact.name
    }
    if(window.store.getters.deal.id == null) {
      return axios.post("/deals.json", {deal: theDeal})
        .then(response => {
          window.store.dispatch("setStatusId", response.data.status_id)
          window.store.dispatch("setDeal", response.data)
          window.location.replace(`/deals/${response.data.id}/edit`)
        })
    } else {
      return axios.put(`/deals/${window.store.getters.deal.id}.json`, {deal: theDeal})
        .then(response => {
          window.store.dispatch("setStatusId", response.data.status_id)
          window.store.dispatch("setDeal", response.data)
          window.location.replace(`/deals/${response.data.id}/edit`)
        })
    }
  },


  getChannelsAndWeeks(direction, state=null){
      if(window.store.getters.channel_ids.length) {
        window.store.dispatch("resetGetters")
      } else {
        if(direction == "filter") {
          window.store.dispatch("resetGetters")
        }
      }

      if(window.store.getters.source) {
        var source = window.store.getters.source.id
      } else {
        var source = null
      }

      if(window.store.getters.medium) {
        var medium = window.store.getters.medium.id
      } else {
        var medium = null
      }

      if(window.store.getters.selected_week) {
        var week = window.store.getters.selected_week.id
      } else if(window.store.getters.weeks.length) {
        window.store.dispatch("setSelectedWeek", window.store.getters.weeks[0])
        var week = window.store.getters.weeks[0].id
      } else {
        var week = ''
      }

      if(window.store.getters.selected_channels.length >= 1) {
        var channels = window.store.getters.selected_channels.map(function(channel) {
  	       return channel.id;
        });
      } else {
        var channels = null
      }

      if (window.store.getters.initial_state && window.store.getters.initial_state.page != undefined) {
        var start_page = window.store.getters.initial_state.page
      } else {
        var start_page = ''
      }
      if (window.store.getters.period) {
        var url = `/channels.json?direction=${direction}&channel_ids=${window.store.getters.channel_ids}&page=${window.store.getters.page}&period=${window.store.getters.period}&source=${source}&medium=${medium}&channels=${channels}&start_page=${start_page}`
      } else if(window.store.getters.selected_week) {
        var url = `/channels.json?direction=${direction}&channel_ids=${window.store.getters.channel_ids}&page=${window.store.getters.page}&current_week=${week}&source=${source}&medium=${medium}&channels=${channels}&start_page=${start_page}`
      } else {
        var url = `/channels.json?direction=${direction}&channel_ids=${window.store.getters.channel_ids}&page=${window.store.getters.page}&source=${source}&medium=${medium}&channels=${channels}&start_page=${start_page}`
      }
      return axios.get(url)
        .then(response => {
            if (window.store.getters.initial_state && window.store.getters.initial_state.page){
              window.store.dispatch("setInitialState", {})
            }
            window.store.dispatch("setSelectedWeek", response.data.weeks[0])
            var week = response.data.weeks[0].id

            history.replaceState({}, "Salesplanner", `/state/${week}+${window.store.getters.page}+${source}+${medium}+${window.store.getters.channel_ids}`);

            window.store.dispatch("setChannels", response.data.channels)
            window.store.dispatch("setWeeks", response.data.weeks)
            window.store.dispatch("setIdentifier", response.data.identifier)
            window.store.dispatch("setPage", response.data.next_page)
            window.store.dispatch("setAllWeeks", response.data.all_weeks)
            return response.data.next_page
        })
    }
}
