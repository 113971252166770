<template>
	<div>
    <div>
      <button @click="toggleOpened" v-if="!opened" class="btn btn-outline-primary">Uitgebreide statistieken uitklappen</button>
      <button @click="toggleOpened" v-if="opened" class="btn btn-outline-primary">Uitgebreide statistieken inklappen</button>
    </div>
		<table v-if="opened" class="table mt-4">
      <thead>
        <tr>
          <th scope="col">Pagina</th>
          <th scope="col">Weergaves</th>
          <th scope="col">Gebruikers</th>
          <th scope="col">Klikken</th>
          <th scope="col">CTR</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(stat, index) in staturls" :key="index">
          <th scope="row">{{ stat["url"] }}</th>
          <td>{{ stat.event_count }}</td>
          <td>{{ stat.user_count }}</td>
          <td>{{ stat.click_count }}</td>
          <td>{{ parseFloat(stat.ctr) }}%</td>
        </tr>
      </tbody>
    </table>
	</div>
</template>

<script>
  export default {
    name: 'StatTable',
    props: ["staturls"],
    data () {
      return {
        opened: false,
      }
    },
    methods: {
      toggleOpened() {
        this.opened = !this.opened
      }
    }
  };
</script>

<style scoped>

</style>