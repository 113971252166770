<template>
  <div v-bind:class="{ fold: !showFolded() }">
    <div :class="`row channel-${channel.id}`" :id="`channel-${channel.id}`">
      <div class="col-3">
        <div class="card position-card">
          <div v-if="showFolded()" class="card-body row">
            <div class="col">
              <h5 class="card-title">{{ channel.name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ channel.medium }}</h6>
              <p class="card-text">{{ channel.source }}</p>
            </div>
            <div class="col" v-if="channel.counter < 1">
              <button @click="toggleExpanded(false)" class="no-button float-right"><i class="far fa-minus-hexagon fa-lg"></i></button>
            </div>
          </div>
          <div v-else class="card-body row">
            <div class="col">
               <h6 class="card-title mb-2">{{ channel.name }}</h6>
               <p class="card-subtitle">
                 <span class="text-muted"><small>{{ channel.medium }}</small></span>
                 <span class="card-text"><small>{{ channel.source }}</small></span>
               </p>
            </div>
            <div class="col" v-if="channel.counter < 1">
               <button @click="toggleExpanded(true)" class="no-button float-right"><i class="far fa-plus-hexagon fa-lg"></i></button>
            </div>
          </div>
      </div>
    </div>
      <ChannelWeeks :columns="columns" :week="week" v-for="week in channel.weeks" :key="`channel_week-${channel.id}-${week.id}`"> </ChannelWeeks>
    </div>
    <br>
  </div>
</template>

<script>
  import ChannelWeeks from './ChannelWeeks';

  export default {
    name: 'Channel',
    components: {
      ChannelWeeks
    },
    data() {
      return {
        toggled: false,
      }
    },
    methods: {
      showFolded() {
        if(this.toggled) {
          return true
        } else if(this.channel.counter > 0) {
          return true
        } else {
          return false
        }
      },
      toggleExpanded(state) {
        this.toggled = !this.toggled
      },
    },
    props: ["channel", "columns"]
  };
</script>

<style scoped>

</style>
