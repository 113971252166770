<template>
  <div class="container-fluid">
    <div class="row">
      <div class="mx-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Naam</th>
              <th>Ingevuld</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListCompany :company="company" v-for="(company, $index) in companies" :key="$index"> </ListCompany>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListCompany from './ListCompany';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'Companies',
    components: {
      ListCompany,
    },
    created(){
      window.store.dispatch("resetVariables")
      window.store.dispatch("setOrder", "id-desc")
      SalesPlanner.getCompanies();
    },
    methods: {
      reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
          ret.push(input[i]);
        }
        return ret;
      },
    },
    computed: {
      companies() {
        var companies = window.store.getters.companies;


        if(window.store.getters.order == "id-desc") {
          companies = this.reverseArr(companies)
        } else if(window.store.getters.order == "id-asc") {
          companies = companies.sort();
        }

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          companies = companies.filter(company =>
            (company.name.toLowerCase().includes(window.store.getters.filter)) ||
            (company.id.toString().includes(window.store.getters.filter)));
        }

        return companies;
      },
    }
  };
</script>
<style scoped>

</style>
