<template>
	<div class="chart">
		<apexchart type=bar height=350 :options="chartOptions" :series="series" />
	</div>
</template>

<script>
  export default {
    name: 'StatChart',
    props: ["stats", "color", "name"],
    data () {
      return {
        series: [{
          name: this.name,
          data: this.stats.map(stat => stat["data"])
        }, 
       ],
        chartOptions: {
          chart: {
            stacked: false,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '100%',
            },
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center', 
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: [this.color],
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: 'Aantal ' + this.name 
            }
          },
          fill: {
            opacity: 1

          },
          xaxis: {
            type: 'date',
            categories: this.stats.map(stat => stat["stat_date"]),
          },
          tooltip: {
            enabled: true,
            fixed: {
              enabled: true
            },
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        }
      }
    }
  };
</script>

<style scoped>

</style>