<template>
  <div class="container-fluid filters">
    <div id="channel-filter" class="row">
      <div class="col-12 col-xl-1">
        <h1>Import errors</h1>
      </div>
      <div class="offset-lg-1 offset-xl-0 col-12 col-lg-10">
          <input @input="onInput" type="text" class="form-control" placeholder="Filter op nummer, naam of type" ref="filter" aria-describedby="basic-addon1">
            <span class="input-group-text input-group-append" id="basic-addon1"><i class="far fa-search"></i></span>
          </input>
        <a @click="resetFilters()" href="javascript:void(0)"  class="btn btn-danger btn-sm">Reset</a>
      </div>
    </div>
  </div>
</template>

<script>
  import SalesPlanner from '../../packs/salesplanner.js'

  export default {
    name: 'ImportErrorFilter',
    methods: {
      resetFilters() {
        window.store.dispatch("resetmediumFilters", null)
        this.$refs.filter.value = null
      },
      onInput(event) {
        window.store.dispatch("setFilter", event.target.value.toLowerCase())
      },
    },
  };
</script>
<style scoped>

</style>
