<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Naam</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListSource :source="source" v-for="(source, $index) in sources" :key="$index"> </ListSource>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListSource from './ListSource';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'Sources',
    components: {
      ListSource,
    },
    created(){
      window.store.dispatch("resetVariables")
      SalesPlanner.getSources();
    },
    computed: {
      sources() {
        var sources = [];
        sources = window.store.getters.sources;
        sources = sources.sort();

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          sources = sources.filter(source =>
            (source.name.toLowerCase().includes(window.store.getters.filter)) ||
            (source.id.toString().includes(window.store.getters.filter)));
        }

        return sources;
      },
    }
  };
</script>
<style scoped>

</style>
