<template>
	<div class="container-fluid filters bg-light" v-shortkey="['ctrl', 's']" @shortkey="saveTask()">
      <div id="new-deal-filter" class="row sticky-top bg-white border-style border-bottom border-primary">
        <div class="col-12 col-xl-6">
          <h1 v-if="!id">Nieuwe taak</h1>
          <h1 v-else>Bewerk taak {{task.id}} - {{task.name}}</h1>
        </div>
        <div class="col-12 col-xl-6 filters text-right">
				  <multiselect v-model="task.status" :options="statuses" :custom-label="name" placeholder="Kies een status" label="customer" track-by="customer"></multiselect>
          <a v-if="task.id" class="btn btn-secondary" href="/tasks">Terug</a>
          <a v-if="!task.id" class="btn btn-sm btn-secondary" href="/deals">Annuleren</a>
          <a @click="removeTask" v-if="task.id" class="btn btn-danger text-white">Verwijderen</a>
          <button @click="saveTask" class="btn btn-success ">Opslaan</button>
        </div>
      </div>
		<div class="row bg-white">
			<div class="col-md-4 col-sm-12 mt-2">
			  <label class="typo__label" for="title">Naam</label>
			  <input v-model="task.name" type="name" class="form-control d-block form-color" id="title" placeholder="Vul een naam in">
			</div>
			<div class="col-md-4 col-sm-12 mt-2">
			  <label class="typo__label" for="title">Beschrijving</label>
			  <textarea v-model="task.description" type="name" rows="1" class="form-control d-block form-color" id="title" placeholder="Vul een beschrijving in"></textarea>
			</div>
			<div class="col-md-4 col-sm-12 mt-2">
			  <label class="typo__label" for="date">Datum</label>
			  <datepicker placeholder="Kies een datum" format="dd-MM-yyyy" input-class="form-control bg-white d-block form-color" v-model="date" name="test"></datepicker>
			</div>
			<div class="col-md-4 col-sm-12 mt-2">
			  <label for="title">Type</label>
			  <multiselect style="width: auto;" v-model="task.task_type" :options="task_types" :custom-label="name" placeholder="Kies een type taak" label="name" track-by="id"></multiselect>
			</div>
			<div class="col-md-4 col-sm-12 mt-2">
			  <label for="title">Gebruiker</label>
			  <multiselect style="width: auto;" v-model="task.user" :options="users" :custom-label="name" placeholder="Kies een gebruiker" label="name" track-by="id"></multiselect>
			</div>
		</div>
	</div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import Datepicker from 'vuejs-datepicker';
  import axios from 'axios'

  export default {
    name: "Task",
    components: {
      Multiselect,
      Datepicker
    },
    props: ["id", "auto"],
    created() {
      if(this.id) {
        axios.get(`/tasks/${this.id}.json`).then(response => {
          this.task = response.data
          this.date = this.task.day.date
        })
      } else {
        this.task = {"id": null, "name": null, "description": null, "status": {"id": 13, "name": "Niet toegewezen"}, "user": null, "day": null}
        if(this.auto) {
          axios.get(`/task_types/${this.auto.task_type}.json`).then(response => (this.task.task_type = response.data))
          axios.get(`/days/${this.auto.day}.json`).then(response => {
            this.task.day = response.data
            this.date = this.task.day.date
          })
        }
      }

      axios.get('/users.json?hubspot=true').then(response => (this.users = response.data))
      axios.get('/statuses.json?type=task').then(response => (this.statuses = response.data))
      axios.get('/task_types.json').then(response => (this.task_types = response.data))
    },
    data () {
      return {
        users: [],
        statuses: [],
        date: null,
        task_types: [],
        task: {"id": null, "name": null, "description": null, "status": {"id": 12, "name": "Niet toegewezen"}, "user": null, "day": null}
      }
    },
    methods: {
      name ({ name }) {
        return `${name}`
      },
      removeTask() {
        if(confirm("Weet je zeker dat je deze taak wilt verwijderen?")){
          axios.delete(`/tasks/${this.task.id}`).then(response => {
            location.replace(`/tasks`)
          })
        }
      },
      getStatus() {
        if(this.task.id) {
          return this.status.id
        } else {
          if(this.task.user) {
            return 14
          } else {
            return 12
          }
        }
      },
      getUserId() {
        if(this.task.user) {
          return this.task.user.id
        } else {
          return null;
        }
      },
      saveTask() {
        if(this.task.task_type && this.task.name && this.date) {
          axios.get(`/days.json?date=${this.date}`).then(response => {
            this.task.day = response.data
            if(this.id) {
            axios.put(`/tasks/${this.task.id}.json?`, {
              name: this.task.name,
              description: this.task.description,
              status_id: this.task.status.id,
              user_id: this.task.user.id,
              day_id: this.task.day.id,
              task_type_id: this.task.task_type.id
            }).then(response => {
              setTimeout(() =>  window.location.href = '/tasks', 500);
            })
            this.$noty.success("Taak is opgeslagen!")
          } else {
            // Creates a new task and then redirects to it.
            axios.post(`/tasks.json?`, {
              name: this.task.name,
              description: this.task.description,
              status_id: this.getStatus(),
              user_id: this.getUserId(),
              day_id: this.task.day.id,
              task_type_id: this.task.task_type.id
            }).then(response => {
              setTimeout(() =>  window.location.href = '/tasks', 500);
            })
          }
          })
        } else {
          this.$noty.error("Nog niet alle velden zijn ingevuld!")
        }
      },
    }
  };
</script>

<style scoped>

</style>
