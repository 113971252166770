<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-10 mx-auto">
        <div class="planner">
          <Weeks :weeks="weeks" :columns="columns"></Weeks>
          <Channel :columns="columns" :channel="channel" v-for="(channel, $index) in channels" :key="$index"></Channel>
          <infinite-loading @infinite="infiniteHandler" :identifier="infiniteIdentifier"></infinite-loading>
          <PlannerIndex></PlannerIndex>
        </div>
      </div>
    </div>
    <div class="row">
    </div>
  </div>
</template>

<script>
  import PlannerIndex from './PlannerIndex';
  import Channel from './Channel';
  import SalesPlanner from '../../packs/salesplanner.js'
  import Weeks from './Weeks';
  import axios from 'axios'
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'Planner',
    props: ["columns", "state"],
    created() {
      window.store.dispatch("resetVariables");
      window.addEventListener('keydown', (e) => {
        if (e.key == '1') {
          this.$confetti.start({
            canvasId: "confetti-canvas"
          });
        }
      });
    },
    computed: {
      weeks() {
        return window.store.getters.weeks
      },
      channels() {
        return window.store.getters.channels
      },
      infiniteIdentifier() {
        return window.store.getters.identifier
      }
    },
    components: {
      Channel,
      Weeks,
      InfiniteLoading,
      PlannerIndex
    },
    methods: {
      infiniteHandler($state) {
        if(window.store.getters.page == false) {
          $state.complete();
        } else {
         SalesPlanner.getChannelsAndWeeks("update", this.state)
         .then((data) => {
            if (data) {
              $state.loaded()
            } else {
              $state.complete();
            }
        });
        }
      }
    },
  };
</script>

<style scoped>

</style>
