import { render, staticRenderFns } from "./TaskTypeFilter.vue?vue&type=template&id=487c4e99&scoped=true&"
import script from "./TaskTypeFilter.vue?vue&type=script&lang=js&"
export * from "./TaskTypeFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487c4e99",
  null
  
)

export default component.exports