<template>
  <tr>
    <th scope="row">{{ promotion.id }}</th>
    <td>{{ promotion.name }}</td>
    <td>{{ promotion.estimate_number }}</td>
    <td>{{ promotion.company.name }}</td>
    <td>{{ promotion.user.name }}</td>
    <td><button :style="`color: #212529; background-color: ${promotion.status.color}; border-color: ${promotion.status.color}; left: 1px;`" :class="`btn btn-sm btn-block`">{{ promotion.status.name }}</button></td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/promotions/${promotion.id}/edit`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListPromotion',
    props: ["promotion"],
    methods: {
      searchForCompany(name) {
        window.store.dispatch("setFilter", name.toLowerCase())
      },
    }
  };
</script>

<style scoped>

</style>
