<template>
  <div class="row">
    <div class="mx-auto col-12 col-lg-10">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Status</th>
            <th>Template</th>
            <th>Onderwerp</th>
            <th>Verstuurd aan</th>
            <th>Verstuurd door</th>
            <th>Verstuurd op</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(email, $index) in the_paginated_emails"  :key="$index">
            <td class="align-middle">
              <span v-if="email.mail_status">
                <i class="fas" :class="email.mail_status.icon + ' text-' + email.mail_status.color"></i>
                {{ email.mail_status.text }}
              </span>
            </td>
            <td class="align-middle">
                #{{email.mail_template.id}} | {{ email.mail_template.name }}
            </td>
            <td class="align-middle">
              <div>
                {{ email.mail_template.subject }}
              </div>
              <span>
                <a class="text-secondary" :href="'/deals/' + email.deal.id + '/edit'">{{ email.deal.title }}|{{ email.customer.name }}</a>
              </span>
            </td>
            <td class="align-middle">
              {{ email.sent_to }}
            </td>
            <td class="align-middle">
              {{email.user ? email.user.first_name : "Systeem"}}
            </td>
            <td class="align-middle">
              {{email.created_at}}
            </td>
            <td class="align-middle">
              <a class="btn btn-outline-primary btn-sm" :href="'/emails/' + email.id">Bekijk</a>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination :items="emails" @paginated="paginated_emails"></Pagination>
    </div>
  </div>    
</template>
<script>
import SalesPlanner from '../../packs/salesplanner.js'
import Pagination from '../base/Pagination'

export default {

  components: {
    Pagination
  },

  data() {
    return {
      current_page: 0,
      per_page : 2,
      pages: 0,
      filtered_emails_length: 0,
      end_seperator: true,
      start_seperator: false,
      the_paginated_emails: []
    }
  },

  created(){
    SalesPlanner.getEmails();
  },

  computed: {
    all_emails() {
      var all_emails = window.store.getters.emails;
      return all_emails;
    },

    emails() {
      var filtered_emails = this.all_emails

      if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
        filtered_emails = filtered_emails.filter(email =>
          (email.mail_template.id.toString().includes(window.store.getters.filter))
        );
      }
      
      return filtered_emails;
    },
  },

  methods: {
    paginated_emails(response){
      this.the_paginated_emails = response
    },
  },
  
} 

</script>
