<template>
  <div class="container-fluid filters">
    <div id="deal-filter" class="align-middle">
      <div class="row">
        <h1 class="col-1">Promoties</h1>
        <div class="col-3 input-group">
          <input @input="onInput" type="text" class=" form-control" placeholder="Filter op nummer" ref="filter" aria-describedby="filter">
          <div class="input-group-append">
            <span class="input-group-text input-group-append bg-primary text-white" id="basic-addon1"><i class="far fa-search"></i></span>
          </div>
        </div>
        <div class="col-2 input-group">
          <select @change="selectOrder($event)" ref="order" class="form-control " id="orderSelect" aria-describedby="order">
            <option value="date-desc">Meest recente eerst</option>
            <option value="date-asc">Minst recente eerst</option>
          </select>
        </div>
        <div class="col-2 input-group">
          <select @change="selectStatus($event)" ref="status" class="form-control " id="statusSelect" ria-describedby="status">
            <option value="null">Alle statussen</option>
            <option v-for="status in statuses" :value="status.id">{{status.name}}</option>
          </select>
        </div>
        <div class="col-1">
            <a @click="resetFilters()" href="javascript:void(0)"  class="btn btn-danger btn my-auto">Reset</a>
        </div>
        <div class="col">
            <a class="btn btn-primary float-right my-auto" href="/promotions/new">Nieuwe promotie</a>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
  export default {
    name: 'PromotionFilter',
    computed: {
      filter() {
        return window.store.getters.filter
      }
    },
    data () {
      return {
        statuses: []
      }
    },
    created(){
      axios.get('/statuses.json?type=promotion').then(response => (this.statuses = response.data))
    },
    methods: {
      resetFilters() {
        window.store.dispatch("resetDealFilters", null)
        this.$refs.status.value = null
        this.$refs.order.value = "date-desc"
        this.$refs.filter.value = null
      },
      onInput(event) {
        window.store.dispatch("setFilter", event.target.value.toLowerCase())
      },
      selectStatus() {
        if(event.target.value == "null") {
          window.store.dispatch("setStatus", null)
        } else {
          window.store.dispatch("setStatus", event.target.value)
        }
      },
      selectOrder() {
        window.store.dispatch("setOrder", event.target.value)
      }
    },
  };
</script>
