<template>
    <div @click="clickTask" :id="`task`"
      class="task text-truncate m-0"
      :style="`height: 60px; background-color: ${task.status_color}`">
      <a v-if="!tasktype && task.user" :href="`/tasks/${task.id}`" style="display: grid; grid-template-columns: 100%; grid-auto-rows: 40px 20px;">
        <div class="name">{{ task.name }}</div>
        <div class="user" :style="`background-color: ${task.user.color};`">{{ task.user.name }}</div>
      </a>
      <a v-if="!tasktype && !task.user" :href="`/tasks/${task.id}`" style="display: grid; grid-template-columns: 1fr; grid-auto-rows: 40px 20px;">
        <div class="name">{{ task.name }}</div>
        <div class="user"></div>
      </a>
      <a v-if="tasktype" :href="`/tasks/new?day=${day}&task_type=${tasktype}`">{{ task.name }}</a>
    </div>
</template>

<script>
  export default {
    name: 'TaskSpot',
    props: ["task", "day", "tasktype"],
    methods: {
      clickTask() {
        if(this.task.id) {
          window.location.href = '/tasks/' + this.task.id;
        } else {
          window.location.href = "/tasks/new?day=" + this.day + "&task_type=" + this.tasktype;
        }
      }
    },
  };
</script>

<style scoped>

</style>
