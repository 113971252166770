<template>
  <div class="row weeks bg-white mb-3">

    <div class="col-2">
    </div>
    <div class="col-1 text-right m-auto">
      <button @click="selectWeek('fast-backward')"><i class='fas fa-fast-backward'></i></button>
      <button @click="selectWeek('backward')"><i class='fas fa-backward'></i></button>
    </div>

    <div :week="week" v-for="week in weeks" :key="`week-${week.id}`" :class="`weeknumber font-weight-bold col-${columns}`">
        {{ week.name }}<br>
        <span class="week-period font-weight-normal font-italic">{{week.start}} - {{week.end}}</span><br>
        <span class="week-period font-weight-normal font-italic">{{week.period}}</span>
    </div>

    <div class="col-1 m-auto">
      <button @click="selectWeek('forward')"><i class='fas fa-forward'></i></button>
      <button @click="selectWeek('fast-forward')"><i class='fas fa-fast-forward'></i></button>
    </div>
  </div>
</template>

<script>
  import SalesPlanner from '../../packs/salesplanner.js'

  export default {
    name: 'Weeks',
    props: ["columns"],
    methods: {
      selectWeek(direction) {
        window.store.dispatch("setPeriod", null)
        window.store.dispatch("setSelectedWeek", null)
        window.store.dispatch("resetGetters")
        SalesPlanner.getChannelsAndWeeks(direction)
      }
    },
    computed: {
      weeks() {
        return window.store.getters.weeks
      }
    }
  };

</script>

<style scoped>

</style>
