<template>
  <div :class="`col-1 channel-positions m-0 p-0`">
    <TaskSpot :task="task" v-for="(task, $index) in day.tasks" :key="`task-${$index}`" class="m-0"> </TaskSpot>
    <TaskSpot :day="day.id" :tasktype="tasktype.id" :task="empty_task" class="m-0"> </TaskSpot>
  </div>
</template>

<script>
  import TaskSpot from './TaskSpot';

  export default {
    name: 'TaskTypeDays',
    props: ["day", "tasktype"],
    data () {
      return {
        empty_task: {"name": "+", "status_color": "#F0F0F0", "id": null},
      }
    },
    components: {
      TaskSpot
    },
  };
</script>

<style scoped>

</style>
