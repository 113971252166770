<template>
  <div v-shortkey="['ctrl', 's']" @shortkey="savePromotion()">
    <div class="hourglass" v-if="is_loading"></div>
    <div class="container-fluid filters bg-light" v-bind:class="{ blur: is_loading }">
      <div id="new-deal-filter" class="row sticky-top bg-white border-style border-bottom border-primary" style="padding-bottom: 0.375rem;">
        <div class="col-12 col-xl-6">
          <h1 v-if="!promotion.id">Nieuwe promotie</h1>
          <h1 :title="promotion.id" v-else>Bewerk promotie {{promotion.name}} [{{promotion.estimate_number}}]</h1>
        </div>
        <div class="col-12 col-xl-6 filters text-right">
          <select v-model="promotion.status" class="form-control d-inline w-25 mr-3" v-bind:disabled="promotion_is_rejected">
              <option v-for="status in statuses"  :value="status">{{status.name}}</option>
          </select>
          <a v-if="promotion.id" class="btn btn-secondary" href="/promotions">Terug</a>
          <a v-if="!promotion.id" @click="removePromotion" class="btn btn-sm btn-secondary text-white">Annuleren</a>
          <button v-if="promotion.id && !promotion_is_rejected && !promotion_is_sold" @click="removePromotion" class="btn btn-danger text-white">Verwijderen</button>
          <a v-if="promotion_has_moneybird" target="_blank" :href="`https://moneybird.com/146676057926272374/estimates/${promotion.moneybird_estimate_id}`" class="btn btn-primary"><i class="fab fa-twitter"></i></a>
          <a v-if="promotion_has_hubspot" target="_blank" :href="`https://app.hubspot.com/contacts/6299272/deal/${promotion.hubspot_deal_id}`" class="btn btn-primary"><i class="fab fa-hubspot"></i></a>
          <button v-if="show_export_button" @click="exportPromotion" class="btn btn-info">Export</button>
          <div class="btn-group">
            <button v-closable="{exclude: ['dropdown'], handler: 'close'}" @click="open('links')" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Links
            </button>
            <div class="dropdown-menu" :class="{ show: open_dropdown == 'links' }">
              <a :href="`/moneybird_webhooks?promotion_id=${promotion.id}`" class="dropdown-item">Webhooks</a>
              <a v-if="promotion.id && !promotion_is_rejected && promotion_is_exported" :href="`/promotions/${promotion.id}/rebase`" class="dropdown-item">Update export</a>
              <a v-if="promotion_has_moneybird" target="_blank" :href="`https://moneybird.com/146676057926272374/estimates/${promotion.moneybird_estimate_id}`" class="dropdown-item">Moneybird</a>
              <a v-if="promotion_has_hubspot" target="_blank" :href="`https://app.hubspot.com/contacts/6299272/deal/${promotion.hubspot_deal_id}`" class="dropdown-item">Hubspot</a>
            </div>
          </div>
          <button v-if="!promotion_is_rejected" @click="savePromotion" class="btn btn-success ">Opslaan</button>
        </div>
      </div>

      <div class="row bg-white">
        <div class="col mt-2">
          <label class="typo__label">Klant</label>
          <multiselect v-model="promotion.company" :options="companies" :custom-label="name" placeholder="Kies een klant" label="customer" track-by="customer"></multiselect>
          <a href="/companies/new" v-if="!promotion.company"><small> + Nieuwe klant toevoegen</small></a>
        </div>
        <div class="col mt-2">
          <label class="typo__label" for="title">Kenmerk</label>
          <input v-model="promotion.name" type="name" class="form-control d-block form-color" id="title" placeholder="Vul een kenmerk in">
        </div>
        <div class="col mt-2">
          <label for="title">Accountmanager</label>
          <multiselect v-model="promotion.user" :options="users" :custom-label="name" placeholder="Kies een accountmanager" label="name" track-by="id"></multiselect>
        </div>
      </div>
    </div>
    <div class="container-fluid filters">
      <div class="row">
        <div class="offset-xl-1 col-12 col-xl-10" v-bind:class="{ blur: is_loading }">
          <Product class="mb-3" :product="product" :products="products" :index="$index" :dagjewegitems="dagjeweg_items" :producttypes="product_types" v-for="(product, $index) in products" :key="$index"> </Product>
          <button v-if="!promotion_is_rejected && promotion.id" class="btn btn-primary btn-lg mb-5" @click="newProduct">Nieuw product</button>
        </div>
      </div>
    </div>
     <PromotionFooter :totalPrice="total_price" class="mt-2"></PromotionFooter>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import Product from './Product';
  import PromotionFooter from './PromotionFooter';
  import axios from 'axios'

  export default {
    name: "Promotion",
    components: {
      Multiselect,
      Product,
      PromotionFooter
    },
    props: ["id"],
    computed: {
      show_export_button() {
        if(
          this.promotion.hubspot_deal_id || 
          this.promotion.moneybird_estimate_id || 
          this.promotion.hubspot_deal_id == 0 || 
          this.promotion.moneybird_estimate_id == "0" ||
          this.products.map(x => [x.starting_date !=null, x.product_type !=null, x.dagjeweg_item]).flat().includes(false)
        ) {
          return false
        } else if(this.isExporting) {
          return false
        } else {
          return true
        }
      },
      is_loading(){
        return this.isExporting
      },
      promotion_is_rejected() {
        if(this.statuses.length) {
          return this.statuses.filter(status => status.name == "Afgewezen")[0].id == this.initialStatusId
        } else {
          return false
        }
      },
      total_price() {
        var price = {"monthly": 0, "single": 0};
        this.products.forEach((product, index) => {
          if(product.product_type) { 
            if(product.charge_monthly) {
              if(product.discount) {
                if(product.discount.includes("%")) {
                  var calculated_price = this.round(product.product_type.price/product.product_type.duration)
                  var discount = calculated_price/100*parseInt(product.discount.match(/\d+/)[0])
                  calculated_price = calculated_price - discount
                } else {
                  var calculated_price = this.round((product.product_type.price/product.product_type.duration) - product.discount)
                }
              } else {
                var calculated_price = this.round(product.product_type.price/product.product_type.duration)
              }
              if(product.charge_setup_costs) {
                price = {monthly: price.monthly+calculated_price+this.round((product.product_type.setup_cost/product.product_type.duration)), single: price.single}
              } else {
                price = {monthly: price.monthly+calculated_price, single: price.single}
              }
            } else {
              if(product.discount) {
                if(product.discount.includes("%")) {
                  var calculated_price = this.round(product.product_type.price)
                  var discount = calculated_price/100*parseInt(product.discount.match(/\d+/)[0])
                  calculated_price = calculated_price - discount
                } else {
                  var calculated_price = this.round((product.product_type.price) - product.discount)
                }
              } else {
                var calculated_price = this.round(product.product_type.price)
              }
              if(product.charge_setup_costs) {
                price = {monthly: price.monthly, single: price.single+calculated_price+product.product_type.setup_cost}
              } else {
                price = {monthly: price.monthly, single: price.single+calculated_price}
              }
            }
          }
        });
        return price;
      },
      promotion_is_exported() {
        if(this.promotion.hubspot_deal_id && this.promotion.moneybird_estimate_id) {
          return true
        } else {
          return false
        }
      },
      promotion_has_moneybird() {
        if(this.promotion.moneybird_estimate_id) {
          if (this.promotion.moneybird_estimate_id.toString().slice(-2) != '00') {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      promotion_has_hubspot() {
        if(this.promotion.hubspot_deal_id) {
          return true
        } else {
          return false
        }
      },
      promotion_is_sold() {
        if(this.statuses.length) {
          return this.statuses.filter(status => status.name == "Verkocht")[0].id == this.initialStatusId
        } else {
          return false
        }
      },
    },
    created() {
      if(this.id) {
        axios.get(`/promotions/${this.id}.json`).then(response => (this.promotion = response.data))
        axios.get(`/products.json?promotion_id=${this.id}`).then(response => {
          this.products = response.data
            if(this.products.length < 1) {
              this.newProduct()
            }
          })
      } else {
        this.promotion = {"id": null, "company": null, "user": null, "status": {"id": 9, "name": "Optie", "color": "#FBFED3"}}
      }

      axios.get('/companies.json').then(response => (this.companies = response.data))
      axios.get('/users.json?hubspot=true').then(response => (this.users = response.data))
      axios.get('/statuses.json?type=promotion').then(response => (this.statuses = response.data))
      axios.get('/product_types.json').then(response => (this.product_types = response.data))
      axios.get('/dagjeweg_items.json').then(response => (this.dagjeweg_items = response.data))
    },
    data () {
      return {
        companies: [],
        users: [],
        products: [],
        dagjeweg_items: [],
        product_types: [],
        statuses: [],
        isExporting: false,
        promotion: {"id": null, "company": null, "user": null, "status": null},
        open_dropdown: null
      }
    },
    methods: {
      name ({ name }) {
        return `${name}`
      },
      close() {
        this.open_dropdown = null
      },
      open(key) {
        if(this.open_dropdown == key) {
          this.open_dropdown = null
        } else {
          this.open_dropdown = key
        }
      },
      round(value, decimals) {
        if(!value) {
          value = 0;
        }

        if(!decimals) {
          decimals = 0;
        }

        value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
        return value;
      },
      exportPromotion() {
        if(this.promotion.id) {
          let promotion_id = this.promotion.id
          this.isExporting = true
          console.log("saving")
          let saving = this.savePromotion()
          saving.then(function(result) {
            axios.post(`/promotions/${promotion_id}/propagate.json`)
            .then(response => {
              window.location.replace(`/promotions/${response.data.id}`)
            })
          })
        }
      },
      removePromotion() {
        if(confirm("Weet je zeker dat je deze taak wilt verwijderen?")){
          axios.delete(`/promotions/${this.promotion.id}`).then(response => {
            location.replace(`/promotions`)
          })
        }
      },
      savePromotion() {
        if(this.id) {
          this.products.forEach((product, index) => {
            if(product.product_type && product.starting_date) {
              if(product.dagjeweg_item === null) {
                return axios.put(`/products/${product.id}.json`, {
                  product_type_id: product.product_type.id,
                  charge_setup_costs: product.charge_setup_costs,
                  charge_monthly: product.charge_monthly,
                  discount: product.discount,
                  starting_date: product.starting_date
                }).then(response => {
                  product = response.data
                  console.log("saving done")
                })
              } else {
                return axios.put(`/products/${product.id}.json`, {
                  product_type_id: product.product_type.id,
                  charge_setup_costs: product.charge_setup_costs,
                  charge_monthly: product.charge_monthly,
                  discount: product.discount,
                  starting_date: product.starting_date,
                  dagjeweg_item_id: product.dagjeweg_item.id
                }).then(response => {
                  product = response.data
                  console.log("saving done")
                })
              }
            } else {
              this.$noty.error("Een van de producten kan niet worden opgeslagen. Deze is nog niet voldoende ingevuld!")
            }
          })
          if(this.promotion.name && this.promotion.user && this.promotion.company && this.promotion.name) {
            return axios.put(`/promotions/${this.promotion.id}.json?status_id=${this.promotion.status.id}&company_id=${this.promotion.company.id}&user_id=${this.promotion.user.id}&name=${this.promotion.name}`).then(response => {
              this.promotion = response.data})
            this.$noty.success("Promotie is opgeslagen!")
            } else {
              this.$noty.error("Nog niet alle velden zijn ingevuld!")
          }
        } else {
          if(this.promotion.name && this.promotion.user && this.promotion.company && this.promotion.name) {
            // Creates a new promotion and then redirects to it. 
            axios.post(`/promotions.json?name=${this.promotion.name}&status_id=${this.promotion.status.id}&company_id=${this.promotion.company.id}&user_id=${this.promotion.user.id}`).then(response => {
              location.replace(`/promotions/${response.data.id}`)
            })
          } else {
            this.$noty.error("Nog niet alle velden zijn ingevuld!")
          }
        }
      },
      newProduct() {
        axios.post(`/products.json?promotion_id=${this.id}&charge_setup_costs=false`).then(response => this.products.push(response.data))
        this.$noty.success("Product is aangemaakt!")
      }
    }
  };
</script>

<style scoped>

</style>
