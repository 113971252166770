<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Naam</th>
              <th>Weken</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListPeriod :period="period" v-for="(period, $index) in periods" :key="$index"> </ListPeriod>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListPeriod from './ListPeriod';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'Periods',
    components: {
      ListPeriod,
    },
    created(){
      window.store.dispatch("resetVariables")
      SalesPlanner.getPeriods();
    },
    methods: {
      reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
          ret.push(input[i]);
        }
        return ret;
      },
    },
    computed: {
      periods() {
        var periods = [];
        periods = window.store.getters.periods;
        periods = this.reverseArr(periods)

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          periods = periods.filter(period =>
            (period.name.toLowerCase().includes(window.store.getters.filter)) ||
            (period.weeks.toLowerCase().includes(window.store.getters.filter)) ||
            (period.id.toString().includes(window.store.getters.filter)));
        }

        return periods;
      },
    }
  };
</script>
<style scoped>

</style>
