<template>
  <!-- Index -->
  <div class="row bg-white fixed-bottom deal_footer">
    <div class="col-8 offset-1">
      <strong>Legenda</strong>
      <table  class="legend">
        <tbody>
          <tr>
            <td>
              <div class="colorbox" style="background: rgb(251, 254, 211);"></div>
            </td>
            <td>
              Optie
            </td>
            <td>
              <div class="colorbox" style="background: rgb(254, 215, 171);"></div>
            </td>
            <td>
              Bezet
            </td>
            <td>
              <div class="colorbox" style="background: rgb(146, 210, 161);"></div>
            </td>
            <td>
              Ingepland
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-2">
      <a class="btn btn-primary float-right mt-1" href="/deals/new">Nieuwe deal</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PlannerIndex',
  };
</script>

<style scoped>

</style>
