<template>
  <tr>
    <td>{{ period.id }}</td>
    <td><a class="no_link" :href="`/periods/${period.id}`">{{ period.name }}</a></td>
    <td>{{ period.weeks }}</td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/periods/${period.id}`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListPeriod',
    props: ["period"]
  };
</script>

<style scoped>

</style>
