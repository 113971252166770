<template>
	<div>
		<ul class="list-group">
      <li class="list-group-item" v-for="week in weeks.weeks">
        <SearchResult :week="week"></SearchResult>
      </li>
    </ul>
	</div>
</template>

<script>
  import SearchResult from './SearchResult';
  export default {
    name: 'SearchResults',
    props: ["weeks"],
    components: {
      SearchResult,
    },
  };
</script>

<style scoped>

</style>
