<template>
  <div class="container-fluid filters">
    <div id="deal-filter" class="align-middle">
      <div class="row">
        <h1 class="col-2">Taak types</h1>
        <div class="col-3 input-group">
          <input @input="onInput" type="text" class=" form-control" placeholder="Filter op nummer of naam" ref="filter" aria-describedby="filter">
          <div class="input-group-append">
            <span class="input-group-text input-group-append bg-primary text-white" id="basic-addon1"><i class="far fa-search"></i></span>
          </div>
        </div>
        <div class="col-1">
            <a @click="resetFilters()" href="javascript:void(0)"  class="btn btn-danger btn my-auto">Reset</a>
        </div>
        <div class="col">
            <a class="btn btn-primary float-right my-auto" href="/task_types/new">Nieuw taak type</a>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
  import SalesPlanner from '../../packs/salesplanner.js'

  export default {
    name: 'TaskTypeFilter',
    methods: {
      resetFilters() {
        window.store.dispatch("resetmediumFilters", null)
        this.$refs.filter.value = null
      },
      onInput(event) {
        window.store.dispatch("setFilter", event.target.value.toLowerCase())
      },
    },
  };
</script>
<style scoped>

</style>
