<template>
  <!-- Index -->
  <div class="row bg-white fixed-bottom deal_footer">
    <div class="col-8 offset-1">
      <strong>Legenda</strong>
      <table class="legend">
        <tbody>
          <tr>
            <td v-for="status in statuses">
              <div class="colorbox mr-1" :style="`background-color: ${status.color};`"></div>
              {{status.name}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TaskFooter',
    props: ["statuses"]
  };
</script>

<style scoped>

</style>
