<template>
  <div>
    <div class="hourglass" v-if="is_loading"></div>
    <div v-bind:class="{ blur: is_loading }">
      <div class="container-fluid filters">
        <div id="new-deal-filter" class="row sticky-top bg-white border-style border-bottom border-primary" style="padding-bottom: 0.375rem;">
          <div class="col-12 col-xl-6">
            <h1 v-if="!deal.id">Nieuwe deal</h1>
            <h1 :title="deal.user.name" v-else>Bewerk deal {{deal.id}} - {{deal.title}} [{{deal.estimate_number}}] <span class="badge badge-secondary" v-if="deal.is_paused">Gepauzeerd</span></h1>
          </div>
          <div class="col-12 col-xl-6 text-right">
            <DealStatus :deal="deal" :statuses="statuses.statuses"></DealStatus>
            <a v-if="deal.id" class="btn btn-secondary" href="/deals">Terug</a>
            <a v-if="!deal.id" @click="removeDeal" class="btn btn-sm btn-secondary" href="/deals">Annuleren</a>
            <a v-if="deal.id && !deal_is_rejected && !deal_is_sold" @click="removeDeal" class="btn btn-danger text-white">Verwijderen</a>
            <a v-if="deal_has_moneybird" target="_blank" :href="`https://moneybird.com/146676057926272374/estimates/${deal.moneybird_estimate_id}`" class="btn btn-primary"><i class="fab fa-twitter"></i></a>
            <a v-if="deal_has_hubspot" target="_blank" :href="`https://app.hubspot.com/contacts/6299272/deal/${deal.hubspot_id}`" class="btn btn-primary"><i class="fab fa-hubspot"></i></a>
            <button v-if="show_export_button" @click="exportDeal" class="btn btn-info">Export</button>
            <div class="btn-group">
              <button v-closable="{exclude: ['dropdown'], handler: 'close'}" @click="open('mails')" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Mails
              </button>
              <div class="dropdown-menu" :class="{ show: open_dropdown == 'mails' }">
                <li class="dropdown-item" style="cursor: pointer;" v-for="(template, index) in mail_templates" :key="index" @click="selectedMail = template">
                  {{ template.name }}
                </li>
                <div class="dropdown-divider"></div>
                <a v-if="deal_has_hubspot" target="_blank" :href="`/deals/${deal.id}/sent_mails`" class="dropdown-item align-bottom">Verstuurde mails <span class="badge badge-primary">{{ deal.email_count }}</span></a>
              </div>
            </div>
            <div class="btn-group">
              <button v-closable="{exclude: ['dropdown'], handler: 'close'}" @click="open('links')" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Links
              </button>
              <div class="dropdown-menu" :class="{ show: open_dropdown == 'links' }">
                <a v-if="!deal_is_rejected && deal_is_sold" :href="`/deals/${deal.id}/dashboard/${deal.uuid}`" class="dropdown-item" target="_blank">Dashboard</a>
                <a v-if="deal_is_sold" href="stats" class="dropdown-item">Stats</a>
                <a :href="`/moneybird_webhooks?deal_id=${deal.id}`" class="dropdown-item">Webhooks</a>
                <button v-if="deal.id && !deal_is_rejected && deal_export_complete" @click="updateDeal" class="dropdown-item">Update export</button>
                <div class="dropdown-divider"></div>
                <a v-if="deal_has_moneybird" target="_blank" :href="`https://moneybird.com/146676057926272374/estimates/${deal.moneybird_estimate_id}`" class="dropdown-item">Moneybird</a>
                <a v-if="deal_has_hubspot" target="_blank" :href="`https://app.hubspot.com/contacts/6299272/deal/${deal.hubspot_id}`" class="dropdown-item">Hubspot</a>
              </div>
            </div>
            <button v-if="!deal_is_rejected" @click="saveDeal" class="btn btn-success ">Opslaan</button>
          </div>
        </div>
        <ModalLiveForm v-if="selectedMail" :deal="deal" :mail_template="selectedMail" @closeModal="selectedMail = null" :user="current_user" :receiver_email="deal.authorized_email" :receiver_name="deal.authorized_first_name" :mail_templates="mail_templates"></ModalLiveForm>
        <div class="row bg-white">
          <div class="col mt-2">
            <label class="typo__label">Klant</label>
            <multiselect v-model="deal.customer" :options="companies" :custom-label="name" placeholder="Kies een klant" label="customer" track-by="customer"></multiselect>
            <a href="/companies/new" v-if="!deal.advertiser"><small> + Nieuwe klant toevoegen</small></a>
          </div>
          <div class="col mt-2">
            <label class="typo__label">Adverteerder</label>
            <multiselect v-model="deal.advertiser" :options="companies" :custom-label="name" placeholder="Kies een adverteerder" label="advertiser" track-by="advertiser"></multiselect>
            <a href="/companies/new" v-if="!deal.advertiser"><small> + Nieuwe adverteerder toevoegen</small></a>
          </div>
          <div class="col mt-2 position-relative" >
            <div :class="adding_authorized_person ? 'position-absolute border p-2 bg-white shadow w-100' : ''" :style="adding_authorized_person ? 'z-index:1500;' : ''">
              <label class="typo__label">Email klant</label>
              <input v-model="deal.authorized_email" type="email" class="form-control d-block form-color" :custom-label="name" placeholder="voorbeeld@julianatoren.nl" label="authorized_email" track-by="authorized_email" @focus="adding_authorized_person = true" @input="setAuthorizedFirstName">
              <div class="mt-4" v-if="adding_authorized_person">
                <label class="typo__label">Aanhef</label>
                <input v-model="deal.authorized_first_name" type="email" class="form-control d-block form-color" :custom-label="name" placeholder="voorbeeld@julianatoren.nl" label="authorized_first_name" track-by="authorized_first_name" @blur="adding_authorized_person = false" >
              </div>
            </div>
          </div>
          <div class="col mt-2">
            <label class="typo__label" for="title">Kenmerk</label>
            <input v-model="deal.title" type="name" class="form-control d-block form-color" id="title" placeholder="Vul een kenmerk in">
          </div>
          <div class="col mt-2">
            <label for="title">Accountmanager</label>
            <multiselect v-model="deal.user" :options="users" :custom-label="name" placeholder="Kies een accountmanager" label="name" track-by="id"></multiselect>
          </div>
          <div v-on:click="getContacts()" v-if="initialCustomer && false" class="col mt-2">
            <label for="title">Contactpersoon</label>
            <multiselect v-model="deal.contact" :options="contacts" :custom-label="name" placeholder="Kies een contactpersoon" label="name" track-by="id"></multiselect>
          </div>
          <div class="col mt-2">
            <label class="typo__label" for="title">Korting</label>
            <input v-model="deal.discount" type="name" class="form-control d-block form-color" id="title" placeholder="Bijv. 50 of 10%">
          </div>
        </div>
      </div>
      <div class="container-fluid filters">
        <div class="row">
          <div class="offset-xl-1 col-12 col-xl-10" style="margin-bottom: 200px;">
            <Campaign class="mb-3" :campaign="campaign" :sources="sources" v-for="(campaign, $index) in deal.campaigns_attributes" :key="$index"> </Campaign>
            <button v-if="deal_status != 4 && deal.id" class="btn btn-primary btn-lg" @click="newCampaign">Nieuwe campagne</button>
          </div>
        </div>
      </div>
       <DealFooter :totalDiscount="this.deal.total_discount" :totalInvisibleSurcharge="this.deal.total_invisible_surcharge" :totalPrice="this.deal.total_price" :calculatedTotalPrice="this.deal.calculated_total_price" class="mt-2"></DealFooter>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import Campaign from './Campaign';
  import DealFooter from './DealFooter';
  import DealStatus from './DealStatus';
  import ModalLiveForm from '../mails/ModalLiveForm';
  import SalesPlanner from '../../packs/salesplanner.js'
  import axios from 'axios'

  export default {
    name: "Deal",
    props: ["companies", "statuses", "sources", "dealinfo", "selectedcompany", "users", "mail_templates", "current_user"],
    components: {
      Multiselect,
      Campaign,
      DealFooter,
      DealStatus,
      ModalLiveForm,
    },
    computed: {
      authorized_first_name_from_email() {
        return this.deal.authorized_email.split('@')[0];
      },
      deal_is_rejected() {
        return this.statuses.statuses.filter(status => status.name == "Afgewezen")[0].id == this.initialStatusId
      },
      show_export_button() {
        if(
          this.deal.hubspot_id || 
          this.deal.moneybird_estimate_id || 
          this.deal.hubspot_id == 0 || 
          this.deal.moneybird_estimate_id == "0" || 
          window.store.getters.deal.campaigns_attributes.length == 0 ||
          window.store.getters.deal.campaigns_attributes.map(x => x.title != null).includes(false) ||
          window.store.getters.deal.campaigns_attributes.map(x => x.campaign_items_attributes.map(y => [y.channels.length > 0, y.weeks.length > 0])).flat(100).includes(false)
        ) {
          return false
        } else if(this.isExporting) {
          return false
        } else {
          return true
        }
      },
      is_loading(){
        return this.isExporting
      },
      deal_export_complete() {
        if(this.deal.moneybird_estimate_id && this.deal.hubspot_id) {
          return true
        } else {
          return false
        }
      },
      deal_has_moneybird() {
        if(this.deal.moneybird_estimate_id || !this.deal.moneybird_estimate_id == 0) {
          return true
        } else {
          return false
        }
      },
      deal_has_hubspot() {
        if(this.deal.hubspot_id || !this.deal.hubspot_id == 0) {
          return true
        } else {
          return false
        }
      },
      deal_is_sold() {
        return this.statuses.statuses.filter(status => status.name == "Verkocht")[0].id == this.initialStatusId
      },
      deal_status() {
        return window.store.getters.deal.status
      },
      campaigns() {
        return this.deal.campaigns_attributes
      },
      deal() {
        return window.store.getters.deal
      }
    },
    created() {
      this.initialStatusId = this.dealinfo.status_id
      this.initialCustomer = this.dealinfo.customer
      if(!this.dealinfo.hubspot_id == 0) {
        this.initialMoneybird = this.dealinfo.moneybird_estimate_id
      } else {
        this.initialMoneybird = null
      }
      console.log("is exported: " + this.deal_has_moneybird + " | moneybird: " + this.deal.moneybird_estimate_id + " | hubspot: " + this.deal.hubspot_id)
      SalesPlanner.getWeeks();
      window.store.dispatch("setDeal", this.dealinfo);
      window.store.dispatch("setStatusId", this.dealinfo.status_id);
      if(this.selectedcompany) {
        this.deal.advertiser = this.selectedcompany
        this.deal.customer = this.selectedcompany
      }
      if(this.dealinfo.id) {
        if(this.deal.status_id != 4) {
          if(this.dealinfo.campaigns_attributes.length < 1) {
            this.newCampaign();
          }
        }
      }
    },
    data () {
      return {
        advertiser: {},
        customer: {},
        initialStatusId: null,
        initialCustomer: null,
        initialMoneybird: null,
        contacts: [],
        isExporting: false,
        open_dropdown: null,
        adding_authorized_person: false,
        selectedMail: null,
      }
    },
    methods: {
      setAuthorizedFirstName() {
        if (this.deal.authorized_email.includes('@')) {
          this.deal.authorized_first_name =  this.authorized_first_name_from_email[0].toUpperCase() + this.authorized_first_name_from_email.slice(1);
        }
      },
      sendReminderMail() {
      const url = 'send_reminder_mail';
      const data = {id: this.deal.id};

      axios
        .post(url, data)
        .then(response => {
          window.location = window.location.href
        })
        .catch(error => {
          console.error(error);
        });
      },
      name ({ name }) {
        return `${name}`
      },
      close() {
        this.open_dropdown = null
      },
      open(key) {
        if(this.open_dropdown == key) {
          this.open_dropdown = null
        } else {
          this.open_dropdown = key
        }
      },
      getContacts() {

      },
      exportDeal() {
        if(this.deal.id) {
          this.isExporting = true

          console.log("saving")
          let saving = SalesPlanner.saveDealWithoutReload()
          saving.then(function(result) {
            console.log("exporting")
            SalesPlanner.exportDeal()
          })
        }
      },
      updateDeal() {
        if(this.deal.id) {
          this.isExporting = true
          axios.post(`/deals/${this.deal.id}/rebase.json`)
          .then(response => {
            this.isExporting = false
          })
        }
      },
      removeDeal() {
        if(confirm("Weet je zeker dat je deze deal wilt verwijderen?")){
          if(this.deal.id) {
            window.store.dispatch("removeDeal", this.deal.id);
            setTimeout(() =>  window.location.href = '/deals', 500);
           
          }
        }
      },
      saveDeal() {
        SalesPlanner.saveDeal();
      },
      newCampaign() {
        SalesPlanner.createCampaign();
      }
    }
  };
</script>

<style lang="css" scoped>

</style>
