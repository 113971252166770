<template>
  <div>
    <div class="row">
      <div class="col-4">
        <h6 v-if="item && item.source">{{item.source.name}} - {{item.medium.name}}</h6>
        <p v-if="item.medium.description && (campaign.is_in_future || campaign.too_late)" v-html="item.medium.description"></p>
        <a v-if="item.spec_sheet && (campaign.is_in_future || campaign.too_late)" :href="item.spec_sheet" target="_blank" class="btn btn-outline-dark btn-sm btn-block mb-2"><i class="fal fa-file-pdf"></i> Bekijk de aanleverspecificaties</a>
        <div class="row">
          <div class="col" v-if="channelsToShow.length">
            <ul class="list-group">
              <li class="list-group-item py-1 text-left font-weight-bold">
                <i class="fal fa-map-signs"></i> 
                <span>Channels</span>
              </li>
              <li class="list-group-item py-1" v-show="(index < maxToShow) || showAllChannels || item.channels.length == (maxToShow + 1)" v-for="(channel, index) in channelsToShow">
                <a v-if="channel.url" :href="channel.url" target="_blank">
                  <span>{{channel.name}}</span>
                </a>
                <span v-else>{{channel.name}}</span>
              </li>
              <li style="cursor: pointer;" @click="showAllChannels = true" class="list-group-item py-1 font-weight-bold" v-if="!showAllChannels && item.channels.length > (maxToShow + 1)">Nog {{item.channels.length - maxToShow}} channels</li>
            </ul>
          </div>  
          <div class="col">
            <ul class="list-group">
              <li class="list-group-item py-1 text-left font-weight-bold">
                <i class="fal fa-calendar-alt"></i> 
                <span>Weken</span>

              </li>
              <li class="list-group-item py-1" v-show="(index < maxToShow) || showAllWeeks || item.weeks.length == (maxToShow + 1)" v-for="(week, index) in item.weeks">{{week.name}}</li>
              <li style="cursor: pointer;" @click="showAllWeeks = true" class="list-group-item py-1 font-weight-bold" v-if="!showAllWeeks && item.weeks.length > (maxToShow + 1)">Nog {{item.weeks.length - maxToShow}} weken</li>

            </ul>
          </div>
        </div>
      </div> 
      <div class="col-8">
        <div class="d-flex justify-content-between">
          <h6>Beeldmateriaal</h6>
          <button 
            v-if="campaign.has_uploaded_files && user_signed_in"
            :disabled="syncing"
            @click="syncFiles"
            class="btn btn-outline-primary btn-sm mb-1"
          >
            <i class="fa fa-spinner fa-spin" v-if="syncing"></i> <span v-if="syncing">Even geduld...</span> <span v-if="!syncing">Synchroniseer bestanden naar Google Drive</span>
          </button>
        </div>
        <small>
          Beeldmateriaal kunt u zowel in standaard als in HD-kwaliteit uploaden. Met HD-kwaliteit benut u de hogere kwaliteit van HD-schermen <b>(onze voorkeur gaat uit naar HD)</b>.
        </small>
        <table v-if="campaign.is_in_future || campaign.too_late || user_signed_in" class="table">
          <thead>
            <tr>
              <th width="">Soort materiaal</th>
              <th>Afmeting SD</th>
              <th>Afmeting HD</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <CampaignTableItem @open="showMaterial" :user_signed_in="user_signed_in" :campaign="campaign" :requirement="requirement" :item="item" v-for="requirement in item.requirements" v-bind:key="`item-${item.id}-${requirement.id}`"></CampaignTableItem>  
          </tbody>
        </table>
        <table v-else class="table">
          <thead>
            <tr>
              <th v-for="requirement in item.requirements" v-bind:key="`item-requirement-${item.id}-${requirement.id}`">{{requirement.name}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="requirement in item.requirements" v-bind:key="`item-image-${item.id}`">
                <img :src="file.small_image" class="img-thumbnail d-inline mr-1" style="height: 80px;" v-if="file.uploaded" v-for="file in requirement.files">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="!campaign.is_in_future && statsForCampaignItem.length && item.medium.name.toLowerCase() != 'redactioneel'" class="row mt-3">
      <div class="col-6">
        <h6>Statistieken</h6>
        <p>
          De {{item.medium.name}} heeft {{statsViews}} views gekregen en {{statsUniqueViews}} gebruikers bereikt. Er is in totaal {{statsClicks}} keer doorgeklikt. Dat maakt dat de doorklik ratio (CTR) {{statsCTR}} is. Hieronder staan de gegevens in grafieken weergegeven.
        </p>
      </div>
      <div class="offset-6">
      </div>
      <div class="col-4">
        <StatChart :stats="viewStats" color="#0ABE0A" name="Weergaves"></StatChart>
      </div>
      <div class="col-4">
        <StatChart :stats="userStats" color="#078F8F" name="Gebruikers"></StatChart>
      </div>
      <div class="col-4">
        <StatChart :stats="clickStats" color="#EE720C" name="Klikken"></StatChart>
      </div>
      <div class="col-12">
        <StatTable :staturls="staturlForCampaignItem"></StatTable>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CampaignTableItem  from './CampaignTableItem.vue'
import StatChart  from '../stats/StatChart.vue'
import StatTable  from '../stats/StatTable.vue'
export default {

  name: 'CampaignCardItem',
  components: {CampaignTableItem, StatChart, StatTable},
  props: ['campaign', 'item', 'stats', "staturls", 'user_signed_in'],
  data () {
    return {
      maxToShow: 4,
      showAllChannels: false,
      showAllWeeks: false,
      syncing: false
    }
  },
  methods: {
    syncFiles() {
      this.syncing = true
      axios.get(`/campaign_items/${this.item.id}/sync_to_drive.json`)
      .then((response) => {
        console.log(response)
        if (response.data.url && response.data.url.length) {
          window.open(response.data.url,'_blank')
        }
        this.syncing = false
      }).catch((error) => {
        console.error(error);
        alert('Er is iets misgegaan...')
      }).finally(() => {
        // TODO
      });
    },
    showMaterial(item){
      this.$emit('showMaterial', item)
    }
  },
  computed: {
    statsForCampaignItem() {
      return this.stats.filter(stat => stat.ga_code == this.item.ga_code)
    },
    staturlForCampaignItem() { 
      return this.staturls.filter(stat => stat.ga_code == this.item.ga_code)[0].stats
    },
    viewStats() {
      return this.statsForCampaignItem.map((item) => {
        return {
          stat_date: item.stat_date,
          data: item.ga_views
        };     
      })
    },
    userStats() {
      return this.statsForCampaignItem.map((item) => {
        return {
          stat_date: item.stat_date,
          data: item.ga_unique_views
        };     
      })
    },
    clickStats() {
      return this.statsForCampaignItem.map((item) => {
        return {
          stat_date: item.stat_date,
          data: item.ga_clicks
        };     
      })
    },
    sortedStats() {
      return this.statsForCampaignItem.sort((a, b) => a.stat_date - b.stat_date )
    },
    channelsToShow(){
      return this.item.channels.filter(channel => !channel.hide_channel_name)
    },
    statsViews() {
      let total = 0;
      this.statsForCampaignItem.forEach(function(item){
        total = total + item.ga_views
      });
      return total.toLocaleString('nl-NL')
    },
    statsUniqueViews () {
      let total = 0;
      this.statsForCampaignItem.forEach(function(item){
        total = total + item.ga_unique_views
      });
      return total.toLocaleString('nl-NL')
    },
    statsClicks () {
      let total = 0;
      this.statsForCampaignItem.forEach(function(item){
        total = total + item.ga_clicks
      });
      return total.toLocaleString('nl-NL')
    },
    statsUniqueClicks () {
      let total = 0;
      this.statsForCampaignItem.forEach(function(item){
        total = total + item.ga_unique_clicks
      });
      return total.toLocaleString('nl-NL')
    },
    statsCTR () {
      let total = 0;
      this.statsForCampaignItem.forEach(function(item){
        total = total + item.ga_ctr
      });
      return (total/this.statsForCampaignItem.length/100).toLocaleString('nl-NL',{'maximumFractionDigits':2}) + "%"
    },
    statsUniqueCTR () {
      let total = 0;
      this.statsForCampaignItem.forEach(function(item){
        total = total + item.ga_unique_ctr
      });
      return (total/this.statsForCampaignItem.length/100).toLocaleString('nl-NL',{'maximumFractionDigits':2}) + "%"
    }
  }
}
</script>

<style lang="css" scoped>
</style>