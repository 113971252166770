<template>
  <div class="container-fluid filters mt-2">
    <FilterChannel :media="media" :sources="sources" :channels="channels"> </FilterChannel>
    <hr class="mb-1 mt-1">
    <FilterPeriod :period_years="period_years" :periods="periods" :weeks="weeks"> </FilterPeriod>
    <hr class="mb-4 mt-1">
  </div>
</template>

<script>
  import FilterChannel from './FilterChannel';
  import FilterPeriod from './FilterPeriod';

  export default {
    name: 'Filters',
    components: {
      FilterChannel,
      FilterPeriod
    },
    created(){
      window.store.dispatch('setInitialState', this.state)
    },
    props: ["period_years", "periods", "sources", "media", "channels", "state", "weeks"]
  };
</script>

<style scoped>

</style>
