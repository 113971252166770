<template>
    <div v-tooltip.top-center="msg" @click="clickEmpty" v-if="!position.campaign_item" class="position position-free">
      <a :href="`/deals/new`">{{number+1}}</a>
    </div>
    <div v-else
      @click="clickDeal"
      class="position position-used position-bezet text-truncate"
      :style="`background-color: ${position.campaign_item.status_color}`">
      <a :href="`/deals/${position.campaign_item.deal.id}/edit`">{{ position.campaign_item.deal.advertiser }}</a>
    </div>
</template>

<script>
import VTooltip from 'v-tooltip'

  export default {
    name: 'PositionSpot',
    props: ["position", "number"],
    data () {
      return {
        msg: "Prijs: €" + this.position.price + ",00" + "<br/> " + "Berekend: €" + this.position.calculated_price + ",00",
      }
    },
    methods: {
      clickDeal() {
        if(this.position.campaign_item) {
          window.location.href = '/deals/' + this.position.campaign_item.deal.id + '/edit';
        }
      },
      clickEmpty() {
        window.location.href = '/deals/new';
      }
    },
  };
</script>

<style scoped>

</style>
