<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Naam</th>
              <th>Bron</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListMedium :medium="medium" v-for="(medium, $index) in media" :key="$index"> </ListMedium>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListMedium from './ListMedium';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'Media',
    components: {
      ListMedium,
    },
    created(){
      window.store.dispatch("resetVariables")
      window.store.dispatch("setOrder", "date-desc")
      SalesPlanner.getMedia();
    },
    computed: {
      media() {
        var media = [];
        media = window.store.getters.media;
        media = media.sort();

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          media = media.filter(media =>
            (media.name.toLowerCase().includes(window.store.getters.filter)) ||
            (media.source.toLowerCase().includes(window.store.getters.filter)) ||
            (media.id.toString().includes(window.store.getters.filter)));
        }

        return media;
      },
    }
  };
</script>
<style scoped>

</style>
