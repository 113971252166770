<template>
  <tr>
    <th scope="row">{{ medium.id }}</th>
    <td>{{ medium.name }}</td>
    <td>{{ medium.source }}</td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/media/${medium.id}`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListMedia',
    props: ["medium"]
  };
</script>

<style scoped>

</style>
