<template>
	<div class="container">
    <div class="row">
  		<div class="form-group col mt-5">
        <label for="tip_id">Tip id</label>
        <input @change="calculateSnippet" v-model="tip_id" type="email" class="form-control" id="tip_id" aria-describedby="emailHelp" placeholder="DagjeWeg.NL Tip id">
      </div>
    </div>
    <div class="row">
      <div class="form-group col">
      <label for="url">Url</label>
      <input @change="calculateSnippet" v-model="url" type="email" class="form-control" id="url" aria-describedby="emailHelp" placeholder="Bestemmings url">
    </div>
    </div>
    <div class="row">
      <div class="form-group col">
      <label for="text">Tekst</label>
      <input @change="calculateSnippet" v-model="text" type="email" class="form-control" id="text" aria-describedby="emailHelp" placeholder="Tekst in artikel">
    </div>
    </div>

    <div class="row">
      <div class="input-group col">
      <textarea v-model="snippet" id="snippet" type="email" class="form-control mr-0 form-control-lg bg-white" aria-describedby="emailHelp"  readonly />
      <div class="input-group-append">
        <button @click="copy" class="ml-0 btn btn-outline-primary" type="button" id="button-addon2">Kopiëer</button>
      </div>
      </div>
    </div>
	</div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'GenerateLink',
  	data () {
        return {
          url: '',
          tip_id: null,
          text: '',
          snippet: ''
        }
    },
    methods: {
      copy() {
        let testingCodeToCopy = document.querySelector('#snippet')
          testingCodeToCopy.setAttribute('type', 'text')
          testingCodeToCopy.select()

          try {
            var successful = document.execCommand('copy');
          } catch (err) {
            alert('Helaas, Hij is niet gekopieerd. Probeer het nog een keer.');
          }
          window.getSelection().removeAllRanges()
      },
      checkPromotion(dagjeweg) {
        if(dagjeweg.goud) {
          return "Goud"
        } else if(dagjeweg.vip) {
          return "Beste"
        } else if(dagjeweg.beter_uit) {
          return "Beter"
        }
      },
      calculateSnippet() {
        var dagjeweg_url = `/dagjeweg_api/${this.tip_id}/`
        axios.get(dagjeweg_url)
          .then(response => {
            const dagjeweg = response.data

            const promotion = this.checkPromotion(dagjeweg)

            this.snippet = `<a href="https://www.dagjeweg.nl/tools/regclick?url=${encodeURIComponent(this.url)}&medium=pagina" target="_blank" onclick="ga('send', 'event', 'Uitgaand', 'Tipdetail', '${this.tip_id} - ${promotion} - ${dagjeweg.name}');">${this.text}</a>`
        })

      }
    },
  };
</script>

<style scoped>

</style>
