<template>
	<tr>
    <td>
      {{ requirement.name }}<br>
      <img :src="file.small_image" class="img-thumbnail d-inline mr-2" style="height: 80px;" v-if="file.uploaded" v-for="file in requirement.files">
    </td>
    <td>
      {{ requirement.width / 2 }}&times;{{requirement.height / 2}}px
      <div v-if="requirement.material_status.has_rejected_material"><span class="badge badge-danger">AFGEKEURD</span></div>
    </td>
    <td>
      {{ requirement.width }}&times;{{requirement.height}}px
      <div v-if="requirement.material_status.has_rejected_material"><span class="badge badge-danger">AFGEKEURD</span></div>
    </td>
		<td class="text-right material-field" >
			<button @click="openForm" class="btn btn-sm btn-primary" v-if="!requirement.material_status.has_valid_material"><i class="fal fa-upload"></i> Aanleveren</button>
      <button @click="openForm" class="btn btn-sm btn-success" v-if="requirement.material_status.has_valid_material"><i class="far fa-eye"></i> Bekijken</button>
		</td>
    <td class="text-center px-1" width="50px">
      <span class="badge badge-pill badge-light" style="font-size: 14px; margin-top: 3px;">
        {{filesWithAttachement}}/{{requirement.files.length}}
        <span v-if="!requirement.material_status.has_valid_material && !requirement.material_status.has_validated_material"><i class="far fa-times text-danger"></i></span>
        <span v-if="requirement.material_status.has_valid_material && !requirement.material_status.has_validated_material"><i class="far fa-check text-success"></i></span>
        <span v-if="requirement.material_status.has_valid_material && requirement.material_status.has_validated_material"><i class="far fa-check-double text-success"></i></span>
        <span v-if="requirement.material_status.has_rejected_material"><i class="far fa-times text-danger"></i></span>

      </span>
    </td>
	</tr>

</template>

<script>
export default {

  name: 'CampaignTableItem',
  props: ['item', 'campaign', 'requirement', 'user_signed_in'],
  data () {
    return {
    	showWeeks: false,
    	showChannels: false,
    }
  },
  computed: {
    filesWithAttachement(){
      // this.selectedChannels.map((channel) => channel.id)
      // .filter(channel => !channel.hide_channel_name)
      return this.requirement.files.filter(file => file.passes_autocheck).length
    },
  },
  methods: {

  	openForm(){
  		this.$emit('open', this)
  	},
  	toggleWeeks(){
  		this.showWeeks = true
			setTimeout(function () {
        this.showWeeks = false
      }.bind(this), 1003000)
  	},
  	toggleChannels(){
  		this.showChannels = true
			setTimeout(function () {
        this.showChannels = false
      }.bind(this), 1003000)
  	}
  }
};
</script>

<style scoped>
</style>