import { render, staticRenderFns } from "./Statuses.vue?vue&type=template&id=fe56b650&scoped=true&"
import script from "./Statuses.vue?vue&type=script&lang=js&"
export * from "./Statuses.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe56b650",
  null
  
)

export default component.exports