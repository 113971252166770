<template>
  <div>
    <hr>
    <div class="w-full d-flex justify-content-between" v-if="items.length > per_page">
      <button :class="current_page > 0 ? 'btn btn-outline-primary' : 'btn btn-disabled'"  @click="previousPage">  
        Vorige
      </button>
      <span>
        <span v-for="(page, index) in shownPages" :key="index" :class="page == current_page + 1 ? 'btn btn-primary' : page == '...' ? '' : 'btn btn-outline-primary'" class="mx-1" @click="setPage(page)">
          {{ page }}
        </span>
      </span>
      <button @click="nextPage" :class="current_page + 1 != pages ? 'btn btn-outline-primary' : 'btn btn-disabled'">  
        Volgende
      </button>
    </div>
  </div>
</template>
<script>

export default {

  name: "Pagination",

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  
  data() {
    return {
      current_page: 0,
      per_page : 15,
      pages: 0,
      end_seperator: true,
      start_seperator: false,
    }
  },

  watch: {
    items: {
      immediate: true,
      handler(newItems) {
        this.emitPaginatedItems(newItems);
      },
    },
  },

  methods: {
    emitPaginatedItems() {
      const startIndex = this.current_page * this.per_page;
      const endIndex = startIndex + this.per_page;
      this.$emit('paginated', this.items.slice(startIndex, endIndex));
    },

    nextPage(){
      this.current_page + 1 != this.pages ? this.current_page = this.current_page + 1 : null

      const startIndex = this.current_page * this.per_page;
      const endIndex = startIndex + this.per_page;
      this.$emit('paginated', this.items.slice(startIndex, endIndex));
    },

    previousPage(){
      this.current_page > 0 ? this.current_page = this.current_page - 1 : null

      const startIndex = this.current_page * this.per_page;
      const endIndex = startIndex + this.per_page;
      this.$emit('paginated', this.items.slice(startIndex, endIndex));
    },
    
    setPage(page){
      page != '...' ? this.current_page = page - 1 : null
      const startIndex = this.current_page * this.per_page;
      const endIndex = startIndex + this.per_page;
      this.$emit('paginated', this.items.slice(startIndex, endIndex));
    }
  },
  
  computed: {
    shownPages(){
      this.pages = Math.ceil(this.items.length / this.per_page);

      if(this.pages > 7){
        const shownPages = []
        const highest_page = Math.ceil(this.items.length / this.per_page)

        // seperators
        if((highest_page - this.current_page) <= 4){
          this.end_seperator = false;
        }else{
          this.end_seperator = true;
        }        
        if(this.current_page <= 3){
          this.start_seperator = false;
        }else{
          this.start_seperator = true;
        }
        
        if(this.current_page > 0){
          shownPages.push(1);
        }
        if(this.start_seperator){
          shownPages.push("...");
        }
        if(this.current_page > 2){
          shownPages.push(this.current_page - 1);
        }
        if(this.current_page > 1){
          shownPages.push(this.current_page);
        }
        
        shownPages.push(this.current_page + 1);

        if(highest_page - this.current_page > 1 && !(this.current_page + 2 > highest_page)){
          shownPages.push(this.current_page + 2); 
        }
        if(highest_page - this.current_page > 2 && !(this.current_page + 3 > highest_page)){
          shownPages.push(this.current_page + 3); 
        }
        if(this.end_seperator){
          shownPages.push("...");
        }
        if(highest_page - this.current_page > 3){
          shownPages.push(highest_page); 
        }

        return shownPages;
      }else{
        return this.pages;
      }
    }
  }
}

</script>