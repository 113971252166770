<template>
  <div>
    <div class="card-header sticky-top bg-dark">
      <div class="d-flex justify-content-between">
        <h5 class="mt-2 text-light">Aanleveren beeldmateriaal</h5>
        <button @click="close" class="btn btn-sm btn-secondary"><i class="fal fa-chevron-left"></i> Terug naar overzichtspagina</button>
      </div>
    </div>
    <div class="card-body">
      <SingleFileField @toggleAlternatives="toggleShowAlternatives" @uploadComplete="completeUpload" :user_signed_in="user_signed_in" :key="`file-field-${file.id}`" :index="index" :file="file" :requirement="requirement" :campaign_item="campaign_item" :total_files="requirement.files.length" :sources="sources" :show_alternatives="show_alternatives" :campaign="campaign" v-for="(file, index) in requirement.files"></SingleFileField>

      <div class="row pt-3" v-if="campaign_item.material_status.has_valid_material">
        <div class="col-8 mx-auto text-center">
          <p class="lead">Fantastisch! Het beeldmateriaal lijkt allemaal in orde. De medewerkers van {{campaign_item.source.name}} kunnen met dit materiaal verder.</p>
          <button @click="close" class="btn btn-success">Ga terug naar de overzichtspagina</button>
        </div>
      </div>      

      <div class="row pt-3">
        <div class="col text-center" v-if="requirement.files[requirement.files.length-1].uploaded && requirement.files.length < maxNumberOfPossibleFiles">
          <p class="lead">Als je wilt, kun je voor de verschillende weken of channels verschillende uitingen aanleveren.</p>
          <button @click="createAlternative" class="btn btn-primary" v-bind:disabled="loading">Upload nog een alternatief <i v-if="loading" class='fal fa-spinner fa-spin'></i></button>
        </div>
      </div>
      <pre v-if="false">
        {{ campaign_item }}
      </pre>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import SingleFileField from './SingleFileField.vue'
export default {

  name: 'MaterialUploader',
  components: {SingleFileField},
  props: ['campaign_item', 'campaign', 'user_signed_in', 'requirement', 'sources'],
  data () {
    return {
      show_alternatives: false,
      loading: false
    }
  },
  methods: {
    createAlternative(){
      this.loading = true
      return axios.post(`/create-alternative-file/${this.campaign_item.id}/${this.requirement.id}.json`)
        .then(response => { 
          this.loading = false
          this.$emit('uploadComplete', response.data)
        })
    },
    toggleShowAlternatives(){
      this.show_alternatives = !this.show_alternatives
    },
    completeUpload(response){
      this.$emit('uploadComplete', response)
    },
  	close(){
  		this.$emit('close')
  	},
    
  },
  computed: {
    maxNumberOfPossibleFiles(){
      return this.campaign_item.channels.length * this.campaign_item.weeks.length
    }
  }
}
</script>

<style lang="css" scoped>

</style>