<template>
  <div class="container-fluid" >
    <div class="row">
      <div class="col-12 col-lg-10 mx-auto">
        <div class="planner">
          <Days @clicked="onDaysClick" :days="days" :currentweek="current_week"></Days>
          <TaskType :tasktype="tasktype" v-for="(tasktype, $index) in task_types" :key="$index"></TaskType>
          <TaskFooter :statuses="statuses"></TaskFooter>
        </div>
      </div>
      <div class="col-lg-2 col-6">
        <div class="notes-container mt-5">
          <Note :note="note" v-for="(note, $index) in notes" :key="$index"></Note>
          <div class="row">
            <div class="col-10">
              <input v-model="note" class="mt-3 form-control d-block form-color" type="text" name="content" placeholder="Nieuwe notitie">
            </div>
            <div class="col-2">
              <button @click="createNote" type="button" class="float-right mt-3 btn btn-primary btn-circle btn-lg"><i class="fa fa-plus"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
    </div>
  </div>
</template>

<script>
  import TaskFooter from './TaskFooter';
  import TaskType from './TaskType';
  import Days from './Days';
  import Note from './Note';
  import axios from 'axios';

  export default {
    name: 'TaskPlanner',
    created() {
      axios.get('/days.json?week=current').then(response => {
        this.days = response.data.days
        this.notes = response.data.notes
        this.current_week = response.data.current_week
      })
      axios.get('/statuses.json?type=task').then(response => (this.statuses = response.data))
      axios.get('/task_types.json?week=current').then(response => (this.task_types = response.data))
    },
    data () {
      return {
        days: [],
        current_week: null,
        task_types: [],
        notes: [],
        note: "",
        statuses: []
      }
    },
    methods: {
      onDaysClick(value) {
      axios.get(`/days.json?week=${value.current_week}`).then(response => {
        this.days = response.data.days
        this.notes = response.data.notes
        this.current_week = response.data.current_week
      })
      axios.get(`/task_types.json?week=${value.current_week}`).then(response => (this.task_types = response.data))
      },
      createNote() {
        if(this.note) {
          axios.put(`/days/${this.days[0].id}.json`, {
            note: this.note
          }).then(response => {
                  this.$noty.success("Notitie is opgeslagen!")
                  this.note = ""
                  axios.get(`/days.json?week=${this.current_week}`).then(response => {
                    this.notes = response.data.notes
                  })
                })
        } else {
          this.$noty.error("Een notitie mag niet leeg zijn.")
        }
      }
    },
    components: {
      TaskFooter,
      TaskType,
      Days,
      Note
    },
  };
</script>

<style scoped>

</style>
