<template>
  <div>
    <div :class="`row tasktype-${tasktype.id}`" :id="`tasktype-${tasktype.id}`">
      <div class="col-3">
        <div class="card position-card">
          <div class="card-body row">
            <div class="col">
              <h5 class="card-title">{{ tasktype.name }}</h5>
            </div>
          </div>
      </div>
    </div>
    <TaskTypeDays :day="day" :tasktype="tasktype" v-for="day in tasktype.days" :key="`tasktype_week-${tasktype.id}-${day.id}`"> </TaskTypeDays>
    <div class="col-1"></div>
    </div>
    <br>
  </div>
</template>

<script>
  import TaskTypeDays from './TaskTypeDays';

  export default {
    name: 'TaskType',
    components: {
      TaskTypeDays
    },
    props: ["tasktype"],
  };
</script>

<style scoped>

</style>
