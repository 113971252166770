<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>ID</th>
              <th>Naam</th>
              <th>Email</th>
              <th>Rol</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListUser :user="user" v-for="(user, $index) in users" :key="$index"> </ListUser>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListUser from './ListUser';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'Users',
    components: {
      ListUser,
    },
    created(){
      window.store.dispatch("resetVariables")
      window.store.dispatch("setOrder", "id-desc")
      SalesPlanner.getUsers();
    },
    methods: {
      reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
          ret.push(input[i]);
        }
        return ret;
      },
    },
    computed: {
      users() {
        var users = [];
        users = window.store.getters.users;
        if(window.store.getters.order == "id-desc") {
          users = users.sort();
        } else if(window.store.getters.order == "id-asc") {
          users = this.reverseArr(users);
        }

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          users = users.filter(user =>
            (user.name.toLowerCase().includes(window.store.getters.filter)) ||
            (user.email.toLowerCase().includes(window.store.getters.filter)) ||
            (user.id.toString().includes(window.store.getters.filter)));
        }

        return users;
      },
    }
  };
</script>
<style scoped>

</style>
