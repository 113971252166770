// seed data
import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

import axios from 'axios'

Vue.use(Vuex)

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default new Vuex.Store({

  state: { // data
    all_weeks: [],
    channels: [],
    companies: [],
    deal: {campaigns_attributes: [{campaign_items_attributes: []}]},
    deals: [],
    filter: null,
    identifier: "init",
    import_errors: [],
    media: [],
    upcoming_weeks: [],
    medium: null,
    order: null,
    page: 1,
    period_year: null,
    period: null,
    periods: [],
    promotion_types: [],
    selected_channels: [],
    selected_week: null,
    source: null,
    sources: [],
    status: null,
    statuses: [],
    user: null,
    users: [],
    weeks: [],
    channel_ids:[],
    status_id: null,
    dagjeweg_items: [],
    initial_state: {},
    mail_templates: [],
    emails: [],
  },

  getters: { // computed properties
    status(state) {
      return state.status
    },
    status_id(state) {
      return state.status_id
    },
    upcoming_weeks(state) {
      return state.upcoming_weeks
    },
    dagjeweg_items(state) {
      return state.dagjeweg_items
    },
    user(state) {
      return state.user
    },
    users(state) {
      return state.users
    },
    statuses(state) {
      return state.statuses
    },
    promotion_types(state) {
      return state.promotion_types
    },
    import_errors(state) {
      return state.import_errors
    },
    channel_ids(state) {
      return state.channel_ids
    },
    periods(state) {
      return state.periods
    },
    deal(state) {
      return state.deal
    },
    companies(state) {
      return state.companies
    },
    campaigns(state) {
      return state.deal.campaigns_attributes
    },
    filter(state) {
      return state.filter
    },
    order(state) {
      return state.order
    },
    channels(state) {
      return state.channels
    },
    deals(state) {
      return state.deals
    },
    selected_channels(state) {
      return state.selected_channels
    },
    period_year(state) {
      return state.period_year
    },
    period(state) {
      return state.period
    },
    source(state) {
      return state.source
    },
    medium(state) {
      return state.medium
    },
    media(state) {
      return state.media
    },
    sources(state) {
      return state.sources
    },
    weeks(state) {
      return state.weeks
    },
    all_weeks(state) {
      return state.all_weeks
    },
    selected_week(state) {
      return state.selected_week
    },
    page(state) {
      return state.page
    },
    identifier(state) {
      return state.identifier
    },
    initial_state(state){
      return state.initial_state
    },
    mail_templates(state) {
      return state.mail_templates
    },
    emails(state) {
      return state.emails
    }
  },

  actions: {
    setInitialState(context, state){
      context.commit("setInitialState", state)
      if (state.source){ context.commit("setSource", state.source) }
      if (state.medium){ context.commit("setMedium", state.medium) }
      if (state.week){ context.commit("setSelectedWeek", state.week) }
      if (state.channel_ids){ context.commit("setSelectedChannels", state)}
    },
    resetDealFilters(context, data) {
      context.commit("resetDealFilters", data)
    },
    resetMailFilters(context, data) {
      context.commit("resetMailFilters", data)
    },
    resetUserFilters(context, data) {
      context.commit("resetUserFilters", data)
    },
    resetVariables(context) {
      // context.commit("resetVariables")
    },
    setUser(context, data) {
      context.commit("setUser", data)
    },
    setUsers(context, data) {
      context.commit("setUsers", data)
    },
    resetMediumFilters(context, data) {
      context.commit("resetMediumFilters", data)
    },
    resetChannelFilters(context, data) {
      context.commit("resetChannelFilters", data)
    },
    resetCompanyFilters(context, data) {
      context.commit("resetCompanyFilters", data)
    },
    setStatus(context, data) {
      context.commit("setStatus", data)
    },
    setDeal(context, data) {
      context.commit("setDeal", data)
    },
    setStatuses(context, data) {
      context.commit("setStatuses", data)
    },
    setPromotionTypes(context, data) {
      context.commit("setPromotionTypes", data)
    },
    setImportErrors(context, data) {
      context.commit("setImportErrors", data)
    },
    setPeriods(context, data) {
      context.commit("setPeriods", data)
    },
    setSources(context, data) {
      context.commit("setSources", data)
    },
    setMedia(context, data) {
      context.commit("setMedia", data)
    },
    setCompanies(context, data) {
      context.commit("setCompanies", data)
    },
    setDagjewegItems(context, data) {
      context.commit("setDagjewegItems", data)
    },
    setChannelIds(context, data) {
      context.commit("setChannelIds", data)
    },
    setOrder(context, data) {
      context.commit("setOrder", data)
    },
    setFilter(context, data) {
      context.commit("setFilter", data)
    },
    setChannels(context, data) {
      context.commit("setChannels", data)
    },
    setChannelsList(context, data) {
      context.commit("setChannelsList", data)
    },
    setDeals(context, data) {
      context.commit("setDeals", data)
    },
    setMailTemplates(context, data) {
      context.commit("setMailTemplates", data)
    },
    setEmails(context, data) {
      context.commit("setEmails", data)
    },
    setCampaignItemMedium(context, data) {
      context.commit("setCampaignItemMedium", data)
    },
    setCampaignItemGaCode(context, data) {
      context.commit("setCampaignItemGaCode", data)
    },
    setWeeks(context, data) {
      context.commit("setWeeks", data)
    },
    setUpcomingWeeks(context, data) {
      context.commit("setUpcomingWeeks", data)
    },
    setSelectedWeek(context, data) {
      context.commit("setSelectedWeek", data)
    },
    setPeriodYear(context, data) {
      context.commit("setPeriodYear", data)
    },
    setPeriod(context, data) {
      context.commit("setPeriod", data)
    },
    setMedium(context, data) {
      context.commit("setMedium", data)
    },
    setSelectedChannel(context, data) {
      context.commit("setSelectedChannel", data)
    },
    addSelectedChannel(context, data) {
      context.commit("addSelectedChannel", data)
    },
    removeSelectedChannel(context, data) {
      context.commit("removeSelectedChannel", data)
    },
    resetSelectedChannels(context, data) {
      context.commit("resetSelectedChannels", data)
    },
    setSource(context, data) {
      context.commit("setSource", data)
    },
    setWeeks(context, data) {
      context.commit("setWeeks", data)
    },
    setPage(context, data){
      context.commit("setPage", data)
    },
    setAllWeeks(context, data){
      context.commit("setAllWeeks", data)
    },
    setIdentifier(context, data) {
      context.commit("setIdentifier", data)
    },
    resetGetters(context){
      context.commit("setPage", 1)
      context.commit("resetChannels")
    },
    newCampaign(context) {
      context.commit("newCampaign")
    },
    newCampaignItem(context, index) {
      context.commit("newCampaignItem", index)
    },
    removeCampaignItem(context, id) {
      if (id != null){
        axios.delete(`/campaign_items/${id}.json`)
        .then(response => {
          window.store.dispatch("setDeal", response.data)
        })
      }
    },
    setStatusId(context, data) {
      context.commit("setStatusId", data)
    },
    removeCampaign(context, options) {
      if (options.id != null){
        axios.delete(`/campaigns/${options.id}.json`)
        .then(response => {
        })
      }
      context.commit("removeCampaign", options.index)
    },
    removeDeal(context, id) {
      if (id != null){
        axios.delete(`/deals/${id}.json`)
        .then(response => {
          sleep(100).then(() => {
            window.location.replace("/deals");
          })
        })
      }
    }
  },

  mutations: {
    removeCampaign(state, index) {
      state.deal.campaigns_attributes.splice(index, 1);
    },
    setStatusId(state, data) {
      state.status_id = data
    },
    resetVariables(state) {
      state.channels = []
      state.deals = []
      state.companies = []
      state.weeks = []
      state.all_weeks = []
      state.period_year = null
      state.period = null
      state.source = null
      state.medium = null
      state.page = 1
      state.media = []
      state.selected_channels = []
      state.selected_week = null
      state.identifier = "init"
      state.order = null
      state.status = null
      state.statuses = []
      state.promotion_types = []
      state.import_errors = []
      state.filter = null
      state.sources = []
      state.periods = []
      state.users = []
      state.user = null
    },
    setChannelIds(state, data) {
      state.channel_ids = data
    },
    resetMediumFilters(state, data) {
      state.filter = null
    },
    resetUserFilters(state, data) {
      state.filter = null
      state.order = "id-desc"
    },
    setUpcomingWeeks(state,data) {
      state.upcoming_weeks = data
    },
    setSources(state,data) {
      state.sources = data
    },
    setUser(state, data) {
      state.user = data
    },
    setUsers(state, data) {
      state.users = data
    },
    setCampaignItemMedium(state, data) {
      state.deal.campaigns_attributes[data.campaign_index].campaign_items_attributes[data.campaign_item_index].medium_id = data.id
    },
    setCampaignItemGaCode(state, data) {
      state.deal.campaigns_attributes[data.campaign_index].campaign_items_attributes[data.campaign_item_index].ga_code = data.ga_code
    },
    setPeriods(state,data) {
      state.periods = data
    },
    resetDealFilters(state, data){
      state.status = null
      state.order = "date-desc"
      state.filter = null
    },
    resetMailFilters(state, data){
      state.filter = null
    },
    newCampaign(state) {
      state.deal.campaigns_attributes.push({campaign_items_attributes: [{medium_id: null}]})
    },
    newCampaignItem(state, index) {
      if(state.deal.campaigns_attributes[index].campaign_items_attributes == undefined) {
        state.deal.campaigns_attributes[index].campaign_items_attributes = []
      }

      state.deal.campaigns_attributes[index].campaign_items_attributes.push({medium_id: null})
    },
    resetChannelFilters(state, data){
      state.order = "date-desc"
      state.filter = null
    },
    resetCompanyFilters(state, data){
      state.order = "id-desc"
      state.filter = null
    },
    setMedia(state, data) {
      state.media = data
    },
    setWeeks(state, data) {
      state.weeks = data
    },
    setStatuses(state, data) {
      state.statuses = data
    },
    setPromotionTypes(state, data) {
      state.promotion_types = data
    },
    setImportErrors(state, data) {
      state.import_errors = data
    },
    setStatus(state, data){
      state.status = data
    },
    setOrder(state, data){
      state.order = data
    },
    setDeal(state, data) {
      state.deal = data
    },
    resetChannels(state){
      state.channels = []
    },
    setChannels(state, data){
      data.forEach(function (item, index) {
        state.channels.push(item)
      })
    },
    setChannelsList(state, data){
      state.channels = data
    },
    setMailTemplates(state, data) {
      state.mail_templates = data
    },
    setDeals(state, data) {
      state.deals = data
    },
    setEmails(state, data) {
      state.emails = data
    },
    setCompanies(state, data) {
      state.companies = data
    },
    setDagjewegItems(state, data) {
      state.dagjeweg_items = data
    },
    setFilter(state, data) {
      state.filter = data
    },
    setSelectedChannels(state, data) {
      state.channel_ids = data.channel_ids
      state.selected_channels = data.selected_channels
    },
    addSelectedChannel(state, data) {
      var array = window.store.getters.selected_channels;
      var index = array.indexOf(data);
      if (index == -1) {
        state.selected_channels.push(data)
      }
    },
    resetSelectedChannels(state, data) {
      state.selected_channels = []
    },
    removeSelectedChannel(state, data) {
      var array = window.store.getters.selected_channels;
      var index = array.indexOf(data);
      if (index > -1) {
        array.splice(index, 1);
      }
      state.selected_channels = array
    },
    setWeeks(state, data) {
      state.weeks = data
    },
    setSource(state, data) {
      state.source = data
    },
    setSelectedWeek(state, data) {
      state.selected_week = data
    },
    setMedium(state, data) {
      state.medium = data
    },
    setPeriod(state, data) {
      state.period = data
    },
    setPeriodYear(state, data) {
      state.period_year = data
    },
    setPage(state, data){
      console.log("setting page: " + data)
      state.page = data
    },
    setAllWeeks(state, data){
      state.all_weeks = data
    },
    setIdentifier(state, data) {
      state.identifier = data
    },
    setInitialState(state, data){
      state.initial_state = data
    }
  }

})
