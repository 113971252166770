<template>
	<div>
		<div v-if="shouldMakePreview ">

      <div class="row">
        <div class="col-3" v-for="(item, index) in selectorList">
          <div class="card mb-4 shadow-sm">
            <div class="card-body">
              <BannerSelector @delete="removeItem" :index="index" :selector="item"></BannerSelector>
            </div>  
          </div>
        </div>
      </div>
      <div class="row" v-if="selectorList.length == 0 || selectorList[selectorList.length-1].name != ''">
        <div class="col">
          <div class="btn-group">
            <button class="btn btn-sm btn-outline-success" @click="addItem">Selector toevoegen</button>
            <button class="btn btn-sm btn-success" @click="saveItems">Opslaan</button>
          </div>
        </div>
      </div>
        

    			<center>Voorbeeld</center>
					<div class="card shadow-sm">
            <div class="card-header text-center p-1">
              <div class="input-group mb-0 small">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="text-muted  far fa-arrow-left"></i></span>
                </div>
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="text-muted far fa-arrow-right"></i></span>
                </div>
                <input type="text" class="form-control small" :value="banner_size.screenshot_url" readonly>
                <div class="input-group-append">
                  <span class="input-group-text"><i class="far fa-star"></i></span>
                </div>
              </div>
            </div>
            <div class="card-body p-0" style="max-height: 800px; overflow-y: auto;" ref="browser">
            	<center class="py-5 my-5" v-if="loading">
            		<i class='fa fa-spinner fa-spin'></i><br>
            		<em>Voorbeeld wordt gemaakt...</em>
            	</center>
              <a v-if="!loading" :href="previewUrl" target="_blank"><img :src="previewUrl" style="width: 100%;"></a>
            </div>
          </div>
	  		</div>

		</div>
	</div>
</template>

<script>
import axios from 'axios'
import BannerSelector from './BannerSelector.vue'
export default {

  name: 'BannerSizeExample',
  props: ['banner_size', 'selectors'],
  components: {BannerSelector},
  data () {
    return {
    	loading: true,
    	previewUrl: '',
      selectorList: [],
    }
  },
  computed: {
  	shouldMakePreview(){
  		return (this.banner_size.width && this.banner_size.width > 0 && this.banner_size.height && this.banner_size.height > 0 && this.banner_size.screenshot_url)
  	}
  },
  methods: {
    removeItem(index){
      console.log(`remove item at index ${index}`)
      this.selectorList.splice(index, 1)
    },
    addItem() {
      this.selectorList.push({name: '', index: 0})
    },
    saveItems(){
      this.loading = true
      if (this.shouldMakePreview){

        return axios.put(`/banner_sizes/${this.banner_size.id}.json`, {banner_size: {selector: JSON.stringify(this.selectorList)}})
        .then(response => {
          // this.loading = false
          this.fetchScreenshot()
        })
      }      
    },
    fetchScreenshot(){
      if (this.shouldMakePreview){
        return axios.get(`/banner_sizes/${this.banner_size.id}/screenshot.json`)
        .then(response => {
          this.previewUrl = response.data.url
          console.log(response.data)
          this.loading = false
          // window.store.dispatch("setDagjewegItems", response.data.dagjewegitems)
        })
      }  
    }

  },
  mounted(){
    this.selectorList = this.selectors

  	this.fetchScreenshot()
  }
}
</script>

<style lang="css" scoped>
</style>