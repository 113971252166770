import Vue from 'vue/dist/vue.esm'
import VueApexCharts from 'vue-apexcharts'
import VTooltip from 'v-tooltip'
import axios from 'axios'

Vue.component('apexchart', VueApexCharts)
Vue.use(require('vue-shortkey'))

import VueClip from 'vue-clip'
Vue.use(VueClip)

import VueNoty from 'vuejs-noty'
Vue.use(VueNoty, {
  timeout: 3000,
  progressBar: true,
  layout: 'topRight',
  theme: 'bootstrap-v4'
})

// import VueTour from 'vue-tour'

// Vue.use(VueTour)


import Navbar from '../components/base/Navbar.vue'
Vue.component('navbar', Navbar)

import TaskPlanner from '../components/tasks/TaskPlanner.vue'
Vue.component('taskplanner', TaskPlanner)

import SelectWeeks from '../components/deals/SelectWeeks.vue'
Vue.component('selectweeks', SelectWeeks)


import Planner from '../components/positions/Planner.vue'
Vue.component('planner', Planner)
import Filters from '../components/positions/Filters.vue'
Vue.component('filters', Filters)

import Task from '../components/tasks/Task.vue'
Vue.component('task', Task)

import Promotions from '../components/promotions/Promotions.vue'
Vue.component('promotions', Promotions)
import Promotion from '../components/promotions/Promotion.vue'
Vue.component('promotion', Promotion)
import PromotionFilter from '../components/promotions/PromotionFilter.vue'
Vue.component('promotionfilter', PromotionFilter)

import ProductTypes from '../components/product_types/ProductTypes.vue'
Vue.component('producttypes', ProductTypes)
import ProductTypeFilter from '../components/product_types/ProductTypeFilter.vue'
Vue.component('producttypefilter', ProductTypeFilter)

import TaskTypes from '../components/task_types/TaskTypes.vue'
Vue.component('tasktypes', TaskTypes)
import TaskTypeFilter from '../components/task_types/TaskTypeFilter.vue'
Vue.component('tasktypefilter', TaskTypeFilter)

import ConnectToHubspot from '../components/users/ConnectToHubspot.vue'
Vue.component('connecttohubspot', ConnectToHubspot)

import StatChart from '../components/stats/StatChart.vue'
Vue.component('statchart', StatChart)
import StatTable from '../components/stats/StatTable.vue'
Vue.component('stattable', StatTable)

import PageViews from '../components/dagjeweg_items/PageViews.vue'
Vue.component('pageviews', PageViews)

import OutgoingClicks from '../components/dagjeweg_items/OutgoingClicks.vue'
Vue.component('outgoingclicks', OutgoingClicks)

import SearchResults from '../components/dagjeweg_items/SearchResults.vue'
Vue.component('searchresults', SearchResults)

import Channels from '../components/channels/Channels.vue'
Vue.component('channels', Channels)
import ChannelFilter from '../components/channels/ChannelFilter.vue'
Vue.component('channelfilter', ChannelFilter)

import Media from '../components/media/Media.vue'
Vue.component('media', Media)
import MediumFilter from '../components/media/MediumFilter.vue'
Vue.component('mediumfilter', MediumFilter)

import Statuses from '../components/statuses/Statuses.vue'
Vue.component('statuses', Statuses)
import StatusFilter from '../components/statuses/StatusFilter.vue'
Vue.component('statusfilter', StatusFilter)

import PromotionTypes from '../components/promotion_types/PromotionTypes.vue'
Vue.component('promotiontypes', PromotionTypes)
import PromotionTypeFilter from '../components/promotion_types/PromotionTypeFilter.vue'
Vue.component('promotiontypefilter', PromotionTypeFilter)

import ImportErrors from '../components/import_errors/ImportErrors.vue'
Vue.component('importerrors', ImportErrors)
import ImportErrorFilter from '../components/import_errors/ImportErrorFilter.vue'
Vue.component('importerrorfilter', ImportErrorFilter)

import CompaniesFilter from '../components/companies/CompaniesFilter.vue'
Vue.component('companiesfilter', CompaniesFilter)
import Companies from '../components/companies/Companies.vue'
Vue.component('companies', Companies)

import DagjewegItemFilter from '../components/dagjeweg_items/DagjewegItemFilter.vue'
Vue.component('dagjewegitemfilter', DagjewegItemFilter)
import DagjewegItems from '../components/dagjeweg_items/DagjewegItems.vue'
Vue.component('dagjewegitems', DagjewegItems)

import GenerateLink from '../components/dagjeweg_items/GenerateLink.vue'
Vue.component('generatelink', GenerateLink)

import BannerSizeExample from '../components/deals/BannerSizeExample.vue'
Vue.component('bannersizeexample', BannerSizeExample)

import UserFilter from '../components/users/UserFilter.vue'
Vue.component('usersfilter', UserFilter)
import Users from '../components/users/Users.vue'
Vue.component('users', Users)

import SourceFilter from '../components/sources/SourceFilter.vue'
Vue.component('sourcefilter', SourceFilter)
import Sources from '../components/sources/Sources.vue'
Vue.component('sources', Sources)

import PeriodFilter from '../components/periods/PeriodFilter.vue'
Vue.component('periodfilter', PeriodFilter)
import Periods from '../components/periods/Periods.vue'
Vue.component('periods', Periods)

import DealFilter from '../components/deals/DealFilter.vue'
Vue.component('dealfilter', DealFilter)
import Deals from '../components/deals/Deals.vue'
Vue.component('deals', Deals)
import Deal from '../components/deals/Deal.vue'
Vue.component('deal', Deal)
import CampaignList from '../components/deals/CampaignList.vue'
Vue.component('campaignlist', CampaignList)

import MailTemplates from '../components/mails/MailTemplates.vue'
Vue.component('mailtemplates', MailTemplates)
import ListMailTemplate from '../components/mails/ListMailTemplate.vue'
Vue.component('listmailtemplate', ListMailTemplate)
import LiveForm from '../components/mails/LiveForm.vue'
Vue.component('liveform', LiveForm)
import LinkModal from '../components/mails/LinkModal.vue'
Vue.component('linkmodal', LinkModal)
import VariableModal from '../components/mails/VariableModal.vue'
Vue.component('VariableModal', VariableModal)
import LiveMailExample from '../components/mails/LiveMailExample.vue'
Vue.component('livemailexample', LiveMailExample)
import Mails from '../components/mails/Mails.vue'
Vue.component('mails', Mails)
import Mail from '../components/mails/Mail.vue'
Vue.component('mail', Mail)
import MailFilter from '../components/mails/MailFilter.vue'
Vue.component('mailfilter', MailFilter)

import Pagination from '../components/base/Pagination.vue'
Vue.component('pagination', Pagination)

Vue.use(VTooltip)

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
