<template>
  <div class="container-fluid filters">
    <div id="deal-filter" class="align-middle">
      <div class="row">
        <h1>
          Alle verstuurde emails
        </h1>
        <div class="col-2 input-group">
          <select @change="setFilter($event)" ref="filter" class="form-control" id="filterSelect" aria-describedby="filter">
            <option value="0">Filteren op template</option>
            <option v-for="(template, index) in templates" :key="index" :value="template.id">{{template.name}}</option>
          </select>
        </div>
        <a @click="resetFilters()" href="javascript:void(0)"  class="btn btn-danger btn my-auto">Reset</a>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";

  export default {

    data() {
      return {
        templates: []
      }
    },

    created(){
      this.loadFilters();
    },
    
    methods: {
      resetFilters() {
        window.store.dispatch("resetMailFilters", null)
        this.$refs.filter.value = 0
      },
      loadFilters(){
        axios.get("/emails.json").then(response => {
          const dataSet = response.data
          const uniqueMailTemplates = Array.from(new Set(dataSet.map(item => item.mail_template.id)))
          .map(id => dataSet.find(item => item.mail_template.id === id).mail_template);

          this.templates = uniqueMailTemplates;
        })
      },
      selectOrder() {
        window.store.dispatch("setOrder", event.target.value)
        console.log(window.store.getters)

      },
      setFilter() {
        window.store.dispatch("setFilter", event.target.value)
      }
    }
  }
</script>