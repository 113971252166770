<template>
  <div class="card mt-3">
    <div class="card-body">
      <div class="row">
        <div class="col-3">
          <img :src="note.user.image" class="rounded-circle z-depth-2" v-if="note.user.image" height="38" data-holder-rendered="true">
        </div>
        <div class="col-9">
          <h5 class="font-weight-bold" style="font-size: 0.9rem;">{{note.user.name}}</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="font-size: 0.7rem;">{{note.created_at}}</h6>
        </div>
      </div>
      <p class="card-text">{{note.content}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Note',
    props: ["note"]
  };
</script>

<style scoped>

</style>
