<template>
  <div class="container-fluid bg-white fixed-bottom deal_footer">
      <p class="h5 mt-2 float-right mr-5">Maandelijks: €{{totalPrice.monthly}}</p>
      <p class="h5 mt-2 float-right mr-5">Jaarlijks: €{{totalPrice.single}}</p>
  </div>
</template>

<script>
  export default {
    name: 'PromotionFooter',
    props: ["totalPrice"]
  };
</script>

<style scoped>

</style>
