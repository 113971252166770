<template>
  <tr>
    <th scope="row">{{ user.id }}</th>
    <td>{{ user.name }}</td>
    <td>{{ user.email }}</td>
    <td>{{ user.role }}</td>
    <td><img :src="user.image" v-if="user.image" height="50"></td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/users/${user.id}`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListUser',
    props: ["user"]
  };
</script>

<style scoped>

</style>
