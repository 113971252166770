<template>
  <tr>
    <th scope="row">{{ company.id }}</th>
    <td><a class="no_link" :href="`/companies/${company.id}`">{{ company.name }}</a></td>
    <td v-if="company.has_ids"><i class="fas fa-check-circle" style="color: rgb(26, 211, 83);"></i></td>
    <td v-else><i class="fas fa-times-circle" style="color: rgb(237, 38, 35);"></i></td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/companies/${company.id}`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListCompany',
    props: ["company"],
  };
</script>

<style scoped>

</style>
