<template>
	<div>
		
		<div class="form-group">
	    <label>Selector</label>
	    <input type="text" v-model="selector.name" class="form-control" placeholder="Selector">
	  </div>
	  <div class="row">
	  	<div class="col-6">
			  <div class="form-group">
			    <input type="text" v-model="selector.index" class="form-control" placeholder="0">
			  </div>
	  	</div>
	  	<div class="col-6">
		    <div class="form-group form-check">
			    <input v-model="selector.text" type="checkbox" class="form-check-input">
			    <label class="form-check-label">Tekst</label>
			  </div>		  
	  	</div>
	  </div>

	  <button @click="remove" class="btn btn-outline-danger btn-sm btn-block">verwijderen</button>
	</div>
	  
</template>

<script>
export default {

  name: 'BannerSelector',
  props: ['selector', 'index'],
  data () {
    return {

    }
  },
  methods: {
  	remove(){
  		this.$emit('delete', this.index)
  	}
  }
}
</script>

<style lang="css" scoped>
</style>