<template>
	<div>
		<label for="title">Week</label>
      <multiselect v-model="week" :options="model" @search-change="setSearchItems" :custom-label="name" placeholder="Kies een week" label="name" track-by="id"></multiselect>
		<a v-if="week" :href="`/weeks/${week.id}/deals`" class="btn btn-primary text-white">Bekijk</a>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'

  export default {
    name: 'SelectWeeks',
    data () {
      return {
       	week: null,
       	weeks: [],
        all_weeks: [],
        model: []
      }
    },
    created() {
		  axios.get('/weeks.json').then(response => 
      (
        this.weeks = response.data.weeks_from_now,
        this.all_weeks = response.data.weeks,
        this.model = response.data.weeks_from_now
      ))
    },
    components: {
    	Multiselect
    },
    methods: {
      setSearchItems(searchQuery){
        if(searchQuery.length > 0){
          this.model = this.all_weeks
        }else{
          this.model = this.weeks
        }
      },  
      name ({ name }) {
        return `${name}`
      },
  	}
  };
</script>

<style scoped>

</style>

