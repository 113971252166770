<template>
  <div class="container-fluid filters">
    <div id="companies-filter" class="align-middle">
      <div class="row">
        <h1 class="col-1">Bedrijven</h1>


        <div class="col-3 input-group">
          <input @input="onInput" v-model="query" type="text" class=" form-control" placeholder="Filter op klant, naam of nummer" ref="filter" aria-describedby="filter">
          <div class="input-group-append">
            <span class="input-group-text input-group-append bg-primary text-white" id="basic-addon1"><i class="far fa-search"></i></span>
          </div>
        </div>


        <div class="col-2 input-group">
          <select @change="selectOrder($event)" ref="order" class="form-control " id="orderSelect" aria-describedby="order">
            <option value="id-desc">Meest recente eerst</option>
            <option value="id-asc">Minst recente eerst</option>
          </select>
        </div>
        <div class="col-1">
          <a @click="resetFilters()" href="javascript:void(0)"  class="btn btn-danger btn my-auto">Reset</a>
        </div>
        <div class="col">
          <a class="btn btn-primary float-right my-auto" href="/companies/new">Nieuw bedrijf</a>
        </div>
      </div> 
    </div> 
  </div>
</template>

<script>
  import SalesPlanner from '../../packs/salesplanner.js'

  export default {
    name: 'CompanyFilter',
    methods: {
      resetFilters() {
        window.store.dispatch("resetCompanyFilters", null)
        this.$refs.order.value = "id-desc"
        this.$refs.filter.value = null
      },
      onInput(event) {
        window.store.dispatch("setFilter", event.target.value.toLowerCase())
      },
      selectOrder() {
        window.store.dispatch("setOrder", event.target.value)
      }
    },
    props: {
      query: String
    },
    created() {
      window.store.dispatch("setFilter", this.query.toLowerCase())
    },
  };
</script>
<style scoped>

</style>
