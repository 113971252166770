<template>
	<div style="border-bottom: 1px dashed #dedede;" v-bind:class="{'bg-light' : isAlternative, 'rouned': isAlternative, 'p-2': isAlternative}">
		<div class="row mt-3 mb-5 pb-3">
	  	<div class="col-4 col-lg-6">

	  		<div v-if="file.uploaded" class="text-center">
          <div class="text-center mb-2" v-if="file.screenshot">
            <div class="btn-group-sm btn-group">
              <button @click="showPreview = false" class="btn btn-sm btn-outline-primary" v-bind:class="{'active':!showPreview}"><i class="fal fa-image"></i> afbeelding</button>
              <button @click="openBrowser" class="btn btn-sm btn-outline-primary" v-if="previewAvailable" v-bind:class="{'active':showPreview}"><i class="fal fa-browser"></i> preview</button>
              <span class="btn btn-sm btn-outline-primary disabled" v-if="!previewAvailable"><i class="fal fa-browser"></i> preview wordt gemaakt <i class='fa fa-spinner fa-spin'></i></span>
            </div>
          </div>
					<figure class="figure" v-if="!showPreview || file.screenshot == null">
					  <a :href="file.image" target="_blank"><img :src="file.image" class="figure-img img-fluid imb-thu rounded" style="max-height: 250px;"></a>
					  <figcaption class="figure-caption text-center">{{file.file_name}}</figcaption>
            <div v-if="(file && !file.validated) || user_signed_in">
              <button @click="deleteFile" class="mt-2 btn btn-sm btn-danger" v-bind:disabled="deleting">bestand verwijderen</button>
            </div>

					</figure>   
          <div v-if="file.alternatives && file.alternatives.length" class="w-75 mx-auto">
            <figure class="figure" v-if="!showPreview || file.screenshot == null" v-for="alternative in file.alternatives">
            <a :href="alternative.image" target="_blank"><img :src="alternative.image" class="figure-img img-fluid imb-thu rounded" style="max-height: 250px;"></a>
            <figcaption class="figure-caption text-center">Alternatief formaat: {{ alternative.width }}&times;{{ alternative.height }}</figcaption>
          </figure>   
          </div>

          <div class="card shadow-sm" v-if="showPreview && file.screenshot">
            <div class="card-header text-center p-1">
              <div class="input-group mb-0 small">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="text-muted  far fa-arrow-left"></i></span>
                </div>
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="text-muted far fa-arrow-right"></i></span>
                </div>
                <input type="text" class="form-control small" value="https://www.dagjeweg.nl/" readonly>
                <div class="input-group-append">
                  <span class="input-group-text"><i class="far fa-star"></i></span>
                </div>
              </div>
            </div>
            <div class="card-body p-0" style="max-height: 400px; overflow-y: auto;" ref="browser" :id="`browser-${file.id}`">
              <a :href="previewUrl" target="_blank"><img :src="previewUrl" style="width: 100%;"></a>
            </div>
          </div>
	  		</div>


				<div v-if="!file.uploaded">	  		
		  		<vue-clip :on-added-file="addedFile" :on-complete="completedUpload" :options="options">
				    <template slot="clip-uploader-action">
				      <div v-if="!currentFile">
				        <div class="dz-message">
				        	<i class="fal fa-upload fa-3x mb-2"></i>
				        	<h2 class="mb-2">Klik of sleep hier een bestand heen</h2>
				        </div>
				      </div>
				    </template>

				    <template slot="clip-uploader-body" slot-scope="props">
				      <div v-for="file in props.files">
				        <img v-bind:src="file.dataUrl" class="img-thumbnail mb-2" v-if="!file.status == 'error'"/><br>
				        <div v-if="file.status == 'added'"><i class="fas fa-spinner fa-spin fa-2x"></i><br></div>
				        <div v-if="file.status == 'success'"><i class="text-success fas fa-check fa-2x"></i><br></div>
                <div v-if="file.status == 'error'"><i class="fas fa-exclamation-triangle"></i> Bestand kan niet worden geüpload:<br></div>
				        {{ file.name }}
				      </div>
				    </template>
				  </vue-clip>

          <div v-if="user_signed_in && !file.uploaded" class="text-center my-2">
            <a class="btn btn-outline-primary" :href="`https://via.placeholder.com/${requirement.width}x${requirement.height}.jpg?text=${requirement.name}`" target="_blank"><i class="fal fa-image"></i> download nepbanner in dit formaat</a>
          </div>
        
				</div>

        <FileFieldWeekSelector @update="visibilityChanged" :campaign_item="campaign_item" :file="file" :requirement="requirement" v-if="campaign_item.channels.length > 1 || campaign_item.weeks.length > 1"></FileFieldWeekSelector>

	  	</div>


		  <div class="col-8 col-lg-6">
		  	<h4 class="d-flex justify-content-between">
          <span>{{requirement.name}} <span class="text-muted" v-if="index > 0">(alternatief {{index}})</span></span> 
          <span class="text-muted">{{requirement.width}}&times;{{requirement.height}}px</span>
        </h4>
        <button v-if="index > 0" @click="deleteAlternative" class="btn btn-danger btn-sm mb-1" v-bind:disabled="deleting"><i class="fal fa-trash"></i> alternatief verwijderen</button>
		  	<table class="table">
		  		<thead>
		  			<tr>
		  				<th></th>
		  				<th>Vereist SD</th>
		  				<th>Vereist HD</th>
		  				<th>Geüpload</th>
		  				<th></th>
		  			</tr>
		  		</thead>
		  		<tbody>
			  		<tr>
			  			<th>Breedte</th>
			  			<td>{{ requirement.width / 2 }}px</td>
			  			<td>{{ requirement.width }}px</td>
			  			<td v-if="file.uploaded">{{file.metadata.width}}px</td>
			  			<td v-if="!file.uploaded">-</td>
			  			<td>
                <i class="fal fa-check text-success" v-if="file.uploaded && !file.errors.width && !file.validated"></i>
                <i class="fal fa-check-double text-success" v-if="file.uploaded && file.passes_autocheck && file.validated"></i>
                <i class="fal fa-exclamation-triangle text-danger" v-if="file.uploaded && file.errors.width"></i>
              </td>
			  		</tr>
			  		<tr>
			  			<th>Hoogte</th>
			  			<td>{{ requirement.height / 2 }}px</td>
			  			<td>{{ requirement.height }}px</td>
			  			<td v-if="file.uploaded">{{file.metadata.height}}px</td>
			  			<td v-if="!file.uploaded">-</td>
			  			<td>
                <i class="fal fa-check text-success" v-if="file.uploaded && !file.errors.height && !file.validated"></i>
                <i class="fal fa-check-double text-success" v-if="file.uploaded && file.passes_autocheck && file.validated"></i>
                <i class="fal fa-exclamation-triangle text-danger" v-if="file.uploaded && file.errors.height"></i>
              </td>
			  		</tr>
			  		<tr>
			  			<th>Bestandsgrootte</th>
			  			<td>min. {{ requirement.min_file_size }} KB</td>
			  			<td>min. {{ requirement.min_file_size }} KB</td>
			  			<td v-if="file.uploaded">{{file.human_size}}</td>
			  			<td v-if="!file.uploaded">-</td>
			  			<td>
                <i class="fal fa-check text-success" v-if="file.uploaded && !file.errors.file_size && !file.validated"></i>
                <i class="fal fa-check-double text-success" v-if="file.uploaded && file.passes_autocheck && file.validated"></i>
                <i class="fal fa-exclamation-triangle text-danger" v-if="file.uploaded && file.errors.file_size"></i>
              </td>
			  		</tr>
            <tr>
              <th>Klik-URL</th>
              <td colspan="2" class="py-1">
                <input class="form-control py-0" @change="validatingUrl = true; saveFile();" v-model="url" placeholder="" v-bind:readonly="file.validated && file.valid_url">
                <small class="form-text text-muted">Naar welke URL wordt de bezoeker gestuurd na het klikken op deze advertentie?</small>
              </td>
              <td>
                <i class="fal fa-spinner fa-spin" v-if="validatingUrl"></i>
                <i class="fal fa-check text-success" v-if="!validatingUrl && file.valid_url && !file.validated"></i>
                <i class="fal fa-check-double text-success" v-if="!validatingUrl && file.valid_url && file.validated"></i>
                <i class="fal fa-exclamation-triangle text-danger" v-if="!validatingUrl && file.uploaded && !file.valid_url"></i>
              </td>
            </tr>
		  		</tbody>

		  	</table>    

        <div v-if="user_signed_in && file.passes_autocheck" class="text-center my-2">
          <input v-model="file.doesnt_have_cta" type="checkbox" name="doesnt_have_cta" id="doesnt_have_cta"/>
          <label for="doesnt_have_cta">Is een afbeelding zonder CTA</label>
        </div>    

        <div v-if="user_signed_in && file.passes_autocheck" class="text-center my-2">
          <div class="btn-group">
            <button class="btn btn-success btn-sm" v-bind:class="{'disabled' : isValidating, 'active' : file.validated == true}" @click="toggleValidation(true)"><i class="fal fa-thumbs-up"></i> goedkeuren</button>
            <button class="btn btn-danger btn-sm" v-bind:class="{'disabled' : isValidating, 'active' : file.validated == false}" @click="toggleValidation(false)"><i class="fal fa-thumbs-down"></i> afkeuren</button>
          </div>
        </div>
        

        <div v-if="file.uploaded && file.error_messages.length" class="alert alert-danger tada animated">
          <p>Het gekozen bestand voldoet niet aan de eisen. Verwijder deze en upload een nieuw bestand. Fouten:</p>
          <ul>
            <li v-for="error in file.error_messages">{{error}}</li>
          </ul>
        </div>

        <div class="alert w-100" v-bind:class="{'alert-success': (file.validated || file.passes_autocheck), 'alert-danger': file.rejected}" v-if="file.uploaded && file.passes_autocheck">
          <p v-if="!file.validated && !file.rejected">Het bestand lijkt in orde! Voor plaatsing wordt het bestand nog handmatig gecontroleerd door een medewerker van {{campaign_item.source.name}}.</p>
          <p v-if="file.validated && !file.rejected">Het bestand is in orde en handmatig gecontroleerd en goedgekeurd door een medewerker van {{campaign_item.source.name}}.</p>
          <p v-if="file.rejected">Het bestand is in principe in orde, maar door een een medewerker van {{campaign_item.source.name}} afgekeurd.</p>
          <button v-if="file.screenshot" v-bind:disabled="!previewAvailable" class='btn btn-success btn-sm' @click="openBrowser" href="javascript:void(0)">Bekijk een preview van de advertentie</button>
        </div>

        <div class="mb-3" v-if="file.rejected && (user_signed_in || comment.length)">
          <div class="input-group note">
            <textarea @change="saveFile" v-bind:readonly="!user_signed_in" v-model="comment" rows="5" style="resize: none; font-size: 18px; line-height: 30px;" class="form-control paper handwriting" placeholder="Schrijf hier eventueel een opmerking op over dit bestand..."></textarea>
          </div>
        </div>

      	<div v-if="campaign_item.allowed_types.length">
      		Toegestane bestandtypes: <strong class="badge-default badge" v-for="type in campaign_item.allowed_types">{{type}}</strong>
      	</div>

		  </div>
	  </div>
	</div>
</template>

<script>
import axios from 'axios'
import FileFieldWeekSelector from './FileFieldWeekSelector.vue'
export default {

  name: 'SingleFileField',
  props: ['requirement', 'campaign_item', 'campaign', 'file', 'index', 'total_files', 'user_signed_in', 'sources'],
  components: {FileFieldWeekSelector},
  data () {
    return {
    	currentFile: null,
    	deleting: false,
      previewAvailable: true,
      isValidating: false,
      validatingUrl: false,
      showPreview: false,
      timestamp: 0,
      comment: "",
      scrollHeight: 0
    }
  },
  created(){
    this.comment  = this.file.comment
    this.url      = this.file.url
  },
  methods: {
    toggleValidation(state){
      this.isValidating = true
      return axios.put(`/update-material/${this.campaign_item.id}/${this.file.id}.json`, {toggle_validation: true, state: state, doesnt_have_cta: this.file.doesnt_have_cta})
        .then(response => { 
          this.isValidating = false
          this.$emit('uploadComplete', response.data) 
        })
    },
    visibilityChanged(data){
      this.$emit('uploadComplete', data)
    },
    deleteAlternative(){
      this.deleting = true
      return axios.delete(`/delete-alternative/${this.campaign_item.id}/${this.file.id}.json`)
        .then(response => { 
          this.deleting = false
          this.$emit('uploadComplete', response.data) 
        })
    },
    saveFile(){
      return axios.put(`/update-material/${this.campaign_item.id}/${this.file.id}.json`, {campaign_material: {comment: this.comment, url: this.url}})
        .then(response => { 
          this.validatingUrl = false
          this.$emit('uploadComplete', response.data) 
        })
    },
    openBrowser(){
      this.showPreview = true;
      this.timestamp = Math.floor(Date.now() / 1000);
      setTimeout(() => this.scrollToLocation(), 1000);
    },
    scrollToLocation(){
      if (this.$refs.browser){
        if (this.file && this.file.position){
          let browserWidth = this.$refs.browser.clientWidth
          let screenshotWidth = this.requirement.original_width
          let ratio = (browserWidth*100) / screenshotWidth
          this.scrollHeight = (ratio / 100.0) * this.file.position.y
          console.log(browserWidth)
          console.log(screenshotWidth)
          console.log(ratio)
        }
        console.log(this.$refs.browser)
        this.$refs.browser.scrollTo(0, this.scrollHeight - 45)
      }
      // window.scrollBy(100, 0);
    },
  	deleteFile(){
  		this.deleting = true
      this.showPreview = false
			return axios.delete(`/remove-materials/${this.campaign_item.id}/${this.file.id}.json`)
		   	.then(response => {
		   		const json = response.data
		   		console.log("success")
		   		console.log(json)
		   		this.deleting = false
		   		this.$emit('uploadComplete', json)
		   	})

  	},
	  addedFile (file) {
      this.previewAvailable = false
	    this.currentFile = file
	  },
	  completedUpload(file, status, xhr){
        // Adding server id to be used for deleting
        // the file.
        console.log(status)
        console.log(file)
        console.log(xhr)
        if (status == 'success'){
					// file.addAttribute('id', xhr.response.id)
					console.log("received success")
					const json = JSON.parse(xhr.response)
					this.currentFile = null
          setTimeout(() => this.previewAvailable = true, 8000);
					// console.log(json)
					// console.log('sending down')
					this.$emit('uploadComplete', json)

        } else {
          this.currentFile = null
        	alert("Er is iets misgegaan bij het uploaden. Heb je een geldig bestand geüpload? Alleen afbeeldingsbestanden (type .jpg en .png bijvoorbeeld) kunnen worden verwerkt.")
        }
        
      }
  },
  computed: {
    isAlternative(){
      return false //this.index > 0
    },
    previewUrl(){
      return `${this.file.screenshot}?t=${this.timestamp}`
    },
  	options(){
  		return {
  			maxFiles: 1,
  			uploadMultiple: false,
        url: `/upload-material/${this.campaign_item.id}/${this.requirement.id}/${this.file.id}`,
        paramName: 'file',
        acceptedFiles: {
          extensions: ['image/*'],
          message: 'Alleen afbeeldingsbestanden (zoals .jpg en .png) zijn toegestaan'
        }

      }
  		// :campaign_item_id/:medium_requirement_id
  	}
  }
};
</script>

<style lang="css">

</style>