<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th style="width: 280px;">Naam</th>
              <th>Offertenummer</th>
              <th>Datum</th>
              <th style="width: 280px;">Klant</th>
              <th style="width: 280px;">Adverteerder</th>
              <th>Prijs excl. BTW</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListDeal :deal="deal" v-for="(deal, $index) in deals" :key="$index"></ListDeal>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListDeal from './ListDeal';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'Deals',
    components: {
      ListDeal,
    },
    created(){
      window.store.dispatch("resetVariables")
      window.store.dispatch("setOrder", "date-desc")
      SalesPlanner.getDeals();
    },
    methods: {
      reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
          ret.push(input[i]);
        }
        return ret;
      },
    },
    computed: {
      deals() {
        var deals = [];
        if(window.store.getters.status != null) {
          deals = window.store.getters.deals.filter(deal => deal.status.id == window.store.getters.status);
        } else {
          deals = window.store.getters.deals;
        }

        if(window.store.getters.order == "date-desc") {
          deals = deals.sort();
        } else if(window.store.getters.order == "date-asc") {
          deals = this.reverseArr(deals)
        }

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          deals = deals.filter(deal =>
            (deal.title.toLowerCase().includes(window.store.getters.filter)) ||
            (deal.estimate_number.toLowerCase().includes(window.store.getters.filter)) ||
            (deal.customer.name.toLowerCase().includes(window.store.getters.filter)) ||
            (deal.id.toString().includes(window.store.getters.filter)) ||
            (deal.date.toLowerCase().includes(window.store.getters.filter)) ||
            (deal.advertiser.name.toLowerCase().includes(window.store.getters.filter)) ||
            (deal.user.name.toLowerCase().includes(window.store.getters.filter)));
        }

        return deals;
      },
    }
  };
</script>
<style scoped>

</style>
