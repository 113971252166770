<template>
  <div class="container-fluid">
    <div class="row">
      <div class="m-auto col-12 col-lg-10">
        <table class="table">
          <thead>
            <tr>
              <th>Nummer</th>
              <th>Naam</th>
              <th>Standaard posities</th>
              <th>Url</th>
              <th>Type</th>
              <th>Volgorde</th>
              <th>Medium</th>
              <th>Bron</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ListChannel :channel="channel" v-for="(channel, $index) in channels" :key="$index"> </ListChannel>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ListChannel from './ListChannel';
  import SalesPlanner from '../../packs/salesplanner.js'
  export default {
    name: 'Channels',
    components: {
      ListChannel,
    },
    created(){
      window.store.dispatch("resetVariables")
      window.store.dispatch("setOrder", "date-desc")
      SalesPlanner.getChannels();
    },
    methods: {
      reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
          ret.push(input[i]);
        }
        return ret;
      },
    },
    computed: {
      channels() {
        var channels = [];
        channels = window.store.getters.channels;

        if(window.store.getters.order == "date-desc") {
          channels = this.reverseArr(channels.sort((a, b) => parseFloat(a.id) - parseFloat(b.id)))
        } else if(window.store.getters.order == "date-asc") {
          channels = channels.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        }

        if((window.store.getters.filter != null) && (window.store.getters.filter != "")) {
          channels = channels.filter(channel =>
            (channel.name.toLowerCase().includes(window.store.getters.filter)) ||
            (channel.type.toLowerCase().includes(window.store.getters.filter)) ||
            (channel.url.toLowerCase().includes(window.store.getters.filter)) ||
            (channel.source.toLowerCase().includes(window.store.getters.filter)) ||
            (channel.id.toString().includes(window.store.getters.filter)) ||
            (channel.medium.toLowerCase().includes(window.store.getters.filter)));
        }

        return channels;
      },
    }
  };
</script>
<style scoped>

</style>
