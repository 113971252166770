<template>
	<div>
		<a href="https://app.hubspot.com/oauth/authorize?client_id=2f9f16cb-fd24-44a2-81d5-cb4a196df833&redirect_uri=https://www.mkbsalesplanner.nl/hubspot-callback-admin&scope=oauth%20e-commerce%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read" class="btn btn-block btn-outline-primary"><i class="fab fa-hubspot mr-3"></i><span>Koppel met Hubspot (Admin)</span></a>
		<a href="https://app.hubspot.com/oauth/authorize?client_id=2583420b-c805-47d2-b261-593aeaba6072&redirect_uri=https://www.mkbsalesplanner.nl/hubspot-callback&scope=oauth%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read" class="btn btn-block btn-outline-primary"><i class="fab fa-hubspot mr-3"></i><span>Koppel met Hubspot</span></a>
	</div>
</template>

<script>
  export default {
    name: 'ConnectToHubspot',
  };
</script>

<style scoped>

</style>
