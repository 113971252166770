<template>
  <tr>
    <th scope="row">{{ channel.id }}</th>
    <td>{{ channel.name }}</td>
    <td>{{ channel.default_positions }}</td>
    <td>{{ channel.url }}</td>
    <td>{{ channel.type }}</td>
    <td>{{ channel.sorting_order }}</td>
    <td>{{ channel.medium }}</td>
    <td>{{ channel.source }}</td>
    <td><a class="btn btn-outline-primary btn-sm" :href="`/channels/${channel.id}`">Bekijk</a></td>
  </tr>
</template>

<script>
  export default {
    name: 'ListChannel',
    props: ["channel"]
  };
</script>

<style scoped>

</style>
